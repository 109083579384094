<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid"
                    v-if="editable"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    v-show="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="btn btn-primary ml-2"
                    v-if="berechtigungen.b_bildung_anwesenheit.read"
                    @click="AnwesenheitBearbeiten"
                  >
                    {{ $t("global.showpresence") }}
                  </button>
                  <button class="btn btn-primary ml-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_ressourcen.delete"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div v-if="$CONST('CONFIG').APPTYPE == 'Education'" class="row">
                  <div class="col-xl-6">
                    <div class="form-checkbox">
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="termin.infoscreen"
                        :disabled="!editable"
                      />
                      {{ $t("global.showoninfoscreen") }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <input
                          v-model.trim="termin.bezeichnung"
                          :readonly="!editable"
                          class="form-control"
                          v-bind:class="{ 'border-danger': !passed }"
                          placeholder=" "
                        />
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.designation")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="termin.ressourcenstatus"
                        label="bezeichnung"
                        :options="ressourcenstati"
                        :reduce="(s) => s.id"
                        :disabled="!editable"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <ValidationProvider rules="required" immediate
                        ><input type="hidden" v-model.trim="termin.von"
                      /></ValidationProvider>
                      <date-picker
                        :inputClass="
                          termin.von == '' || termin.von == null
                            ? 'border-danger'
                            : ''
                        "
                        date-format="dd.mm.yy"
                        :initial="termin.von"
                        placeholer=" "
                        :show-icon="true"
                        :disabled="!editable"
                        @update="(val) => (termin.von = val)"
                      />
                      <label>{{ $t("global.from") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="termin.bis"
                        placeholder=" "
                        :show-icon="true"
                        :disabled="!editable"
                        @update="(val) => (termin.bis = val)"
                      />
                      <label>{{ $t("global.till") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-2 pl-0" :key="uhrzeitVonKey">
                    <ValidationProvider rules="required" immediate
                      ><input type="hidden" v-model.trim="termin.von_uhrzeit"
                    /></ValidationProvider>
                    <time-field
                      placeholder=" "
                      :label="$t('global.starttime')"
                      :readonly="!editable"
                      :initial="termin.von_uhrzeit"
                      :inputClass="
                        termin.von_uhrzeit == '' || termin.von_uhrzeit == null
                          ? 'border-danger'
                          : ''
                      "
                      @update="updateUhrzeitVon"
                    />
                  </div>

                  <div class="col-xl-2 pl-0" :key="uhrzeitBisKey">
                    <ValidationProvider rules="required" immediate
                      ><input type="hidden" v-model.trim="termin.bis_uhrzeit"
                    /></ValidationProvider>
                    <time-field
                      placeholder=" "
                      :label="$t('global.endtime')"
                      :readonly="!editable"
                      :initial="termin.bis_uhrzeit"
                      :inputClass="
                        termin.bis_uhrzeit == '' || termin.bis_uhrzeit == null
                          ? 'border-danger'
                          : ''
                      "
                      @update="updateUhrzeitBis"
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    v-if="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <v-select
                        v-model="termin.konfliktstatus"
                        label="bezeichnung"
                        :options="konfliktstati"
                        :reduce="(s) => s.id"
                        :disabled="!editable"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.conflictstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE == 'Education'" class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model.number="termin.lektionen"
                        class="form-control"
                        :readonly="!editable || honorar"
                        placeholder=" "
                      />
                      <label>{{ $t("global.lessons") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="termin-verantwortlicher"
                      :person="termin.verantwortlich"
                      :label="$t('global.responsible')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :editable="editable"
                      @confirmed="setVerantwortlicher"
                    />
                  </div>
                </div>

                <div class="row">
                  <div
                    v-if="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <v-select
                        v-model="termin.ressourcenbuchung"
                        label="bezeichnung"
                        :options="ressourcenbuchungstypen"
                        :reduce="(r) => r.id"
                        :disabled="!editable"
                        :placeholder="$t('global.externalinternal')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.resourcebooking") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="termin-reserviert-durch"
                      :person="termin.reserviert_durch"
                      :label="$t('global.reservedby')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :editable="editable"
                      :required="true"
                      @confirmed="setReserviertDurch"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="termin.bemerkung"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                        rows="4"
                      ></textarea>
                      <label>{{ $t("global.comment") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Reservationen -->
          <div>
            <container-headline
              :headline="$t('global.reservations')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div
                    v-show="$CONST('CONFIG').APPTYPE == 'Education'"
                    class="row"
                  >
                    <div class="col-xl-12">
                      <single-select-veranstaltung
                        id="termin-veranstaltung"
                        :veranstaltung="termin.veranstaltung"
                        :label="$t('global.event')"
                        :allowOpen="berechtigungen.m_bildung.read"
                        :editable="editable"
                        @confirmed="setVeranstaltung"
                      />
                    </div>
                  </div>
                  <div
                    v-show="$CONST('CONFIG').APPTYPE === 'DESKA'"
                    class="row"
                  >
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="selectedTyp"
                          label="typ"
                          :options="buchungstyp"
                          :reduce="(r) => r.typ"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.bookingtype") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="termin.einrichtung_von"
                          placeholer=" "
                          :show-icon="true"
                          :disabled="!editable"
                          @update="(val) => (termin.einrichtung_von = val)"
                        />
                        <label>{{ $t("global.facilityfrom") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="termin.einrichtung_bis"
                          placeholder=" "
                          :show-icon="true"
                          :disabled="!editable"
                          @update="(val) => (termin.einrichtung_bis = val)"
                        />
                        <label>{{ $t("global.facilityuntil") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <time-field
                        placeholder=" "
                        :initial="termin.einrichtung_von_uhrzeit"
                        :readonly="!editable"
                        :label="$t('global.starttime')"
                        @update="
                          (val) => (termin.einrichtung_von_uhrzeit = val)
                        "
                      />
                    </div>

                    <div class="col-xl-3">
                      <div class="form-group">
                        <input
                          v-model.trim="termin.einrichtung_bis_uhrzeit"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                          @update="
                            (val) => (termin.einrichtung_bis_uhrzeit = val)
                          "
                        />
                        <label>{{ $t("global.endtime") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <!-- v-select für Education -->
                        <v-select
                          v-model="selectedGebaeude"
                          label="bezeichnung"
                          :options="gebaeude"
                          v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                          :reduce="(r) => r.id"
                          :placeholder="$t('global.building')"
                          :disabled="!editable"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.building") }}</label>
                      </div>
                    </div>

                    <div class="col-xl-6">
                      <div class="form-group">
                        <!-- v-select für Education -->
                        <v-select
                          v-model="selectedRaum"
                          label="bezeichnung"
                          :options="filteredRaeume"
                          v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                          :reduce="(r) => r.id"
                          :placeholder="$t('global.room')"
                          :disabled="!editable"
                          multiple
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <!-- v-select für DESKA -->
                        <v-select
                          v-model="termin.raum"
                          label="bezeichnung"
                          :options="raeume"
                          v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                          :reduce="(r) => r.id"
                          :placeholder="$t('global.room')"
                          :disabled="!editable"
                          @input="ladeArbeitsplaetze(termin.raum)"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.room") }}</label>
                      </div>
                    </div>
                    <div
                      v-if="
                        selectedTyp == 'Arbeitsplatz' &&
                        termin.raum != null &&
                        $CONST('CONFIG').APPTYPE === 'DESKA'
                      "
                      class="col-xl-6"
                    >
                      <div class="form-group">
                        <v-select
                          v-model="termin.arbeitsplatz"
                          label="bezeichnung"
                          :options="arbeitsplatzListe"
                          :reduce="(r) => r.ID"
                          :placeholder="$t('global.workplace')"
                          :disabled="!editable"
                          multiple
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.workplace") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Reservationen -->
          <!-- START Finanzen -->
          <div v-show="$CONST('CONFIG').APPTYPE === 'Education'" v-if="id">
            <container-headline
              :headline="$t('global.finance')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.trim="termin.rechnungsdatum"
                          class="form-control"
                          placeholder=" "
                          readonly="true"
                        />
                        <label>{{ $t("global.invoicedate") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Finanzen -->
        </div>
        <!-- ENDE Rechte Seite -->
        <div v-if="id" class="col-xl-12">
          <div v-if="$CONST('CONFIG').APPTYPE === 'Education'" class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item" v-if="berechtigungen.m_dozierende.read">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'termin',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.lecturerdeployment") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <!-- Start Dozenteneinsatz -->
                  <dozenteneinsatzliste
                    :termin="id"
                    :editable="editable"
                    :shown="anzeige == 0 ? true : false"
                  />
                  <!-- Ende Dozenteneinsatz -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <message-box
      id="termin-konflikt-modal"
      :headerText="$t('global.dateconflict')"
      :text="$t('global.aconflictwithanappointmentoccurredwhensaving')"
      :ok="true"
      :cancel="true"
      :okText="$t('global.acceptdateconflict')"
      @ok="acceptConflict"
      @cancel="setConflictStatus"
      :cancelText="$t('global.dontacceptdateconflict')"
    >
      <p v-if="currentconflicts.length !== 0">
        {{ $t("global.conflictswith") }}:
      </p>
      <div class="terminkonflikte">
        <div v-for="(conflict, index) of currentconflicts" :key="index">
          <div class="row pl-2">
            <div class="col-12">
              <div
                class="terminkonfliktelement"
                v-if="conflict.raum"
                @click="openTermin(conflict.termin.id)"
                v-tooltip
                :title="$t('global.showappointment')"
              >
                <div>
                  {{ conflict.termin.von }} {{ conflict.termin.von_uhrzeit }} -
                  {{ conflict.termin.bis }} {{ conflict.termin.bis_uhrzeit }}
                </div>
                <div>
                  {{ $t("global.reason") }}: {{ $t("global.room") }}
                  {{ conflict.raum.bezeichnung }}
                </div>
              </div>
              <div
                class="terminkonfliktelement"
                v-if="conflict.Dozenteneinsatz"
                @click="openTermin(conflict.termin.id)"
                v-tooltip
                :title="$t('global.showappointment')"
              >
                <div>
                  {{ conflict.termin.von }} {{ conflict.termin.von_uhrzeit }} -
                  {{ conflict.termin.bis }} {{ conflict.termin.bis_uhrzeit }}
                </div>
                <div>
                  {{ $t("global.reason") }}: {{ $t("global.lecturer") }}
                  {{ conflict.Dozenteneinsatz }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </message-box>

    <loeschen-modal id="termin-loeschen-modal" @confirmed="terminloeschen" />
  </div>
</template>

<script>
import Dozenteneinsatzliste from "@/components/Reiter/Dozenteneinsatzliste";
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import App from "@/AppApi";
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal.vue";
import MessageBox from "@/components/Modals/Messagebox.vue";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "Termin",
  components: {
    Dozenteneinsatzliste,
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
    LoeschenModal,
    SingleSelectVeranstaltung,
    MessageBox,
    TimeField,
    UnsavedChangesModal,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedGebaeude: null,
      selectedRaum: [],
      uhrzeitBisKey: 1000,
      uhrzeitVonKey: 1,
      termin: {
        veranstaltung: { bezeichnung: this.$t("global.notlinked") },
        verantwortlich: null,
        reserviert_durch: null,
        raumliste: [],
        raum: null,
        arbeitsplatz: [],
        von: null,
        bis: null,
        von_uhrzeit: null,
        bis_uhrzeit: null,
      },
      currentconflicts: [],
      arbeitsplatzListe: [],
      selectedTyp: "",
      buchungstyp: [
        {
          typ: "Raum",
        },
        {
          typ: "Arbeitsplatz",
        },
      ],
      dozenteneinsatz: {
        id: null,
      },
      honorar: null,
    };
  },
  computed: {
    navbarTitel: function () {
      return this.$t("global.appointment");
    },
    ressourcenstati: {
      get() {
        return this.$store.state.ressourcen.ressourcenstati;
      },
    },
    ressourcenbuchungstypen: {
      get() {
        return this.$store.state.ressourcen.ressourcenbuchungstypen;
      },
    },
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
    konfliktstati: {
      get() {
        return this.$store.state.ressourcen.konfliktstati;
      },
    },
    // Define the computed property to extract the unique "gebaeude" array from "raeume"
    gebaeude: {
      get() {
        return this.$store.state.ressourcen.gebaeude;
      },
    },
    filteredRaeume: function () {
      // Filter raeume based on the selectedGebaeude
      if (this.selectedGebaeude) {
        return this.raeume.filter(
          (raum) => raum.gebaeude?.id === this.selectedGebaeude
        );
      }
      return this.raeume; // If no gebaeude is selected, show all raeume
    },
  },

  watch: {
    selectedGebaeude: function () {
      // Reset selectedRaum when a new gebaeude is selected
      if (this.selectedRaum) {
        let filteredRaumByGebaeudeID = this.raeume.filter(
          (raum) => raum.id === this.selectedRaum[0]
        )[0];
        if (
          filteredRaumByGebaeudeID?.gebaeude?.id != this.selectedGebaeude ||
          this.selectedGebaeude == null
        ) {
          this.selectedRaum = null;
        }
      }
    },
    "termin.raumliste": function () {
      // Fill selectedRaum with the ids from this.termin.raumliste
      this.selectedRaum = this.termin.raumliste.map((raum) => raum.id);
    },
    selectedRaum: function () {
      // Set gebaeude based on the selectedRaum
      if (!this.selectedGebaeude && this.selectedRaum && this.raeume.length) {
        let filteredGebaeude = this.raeume.filter(
          (raum) => raum.id === this.selectedRaum[0]
        );
        this.selectedGebaeude = filteredGebaeude[0]?.gebaeude?.id;
      }
    },
  },

  created() {
    if (this.id != null) {
      Api.get("termin/", { params: { id: this.id } }).then((response) => {
        this.initializeTermin(response.data);
      });
      Api.get("dozenteneinsatz/", { params: { termin: this.id } }).then(
        (response) => {
          this.initializeDozentenseinsatz(response.data);
          this.getHonorar();
        }
      );
    } else this.initializeTermin(this.termin);
  },
  mounted: function () {
    if (this.ressourcenstati.length == 0) {
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
    }
  },
  methods: {
    updateUhrzeitVon(value) {
      this.termin.von_uhrzeit = value;
      this.uhrzeitVonKey++;
    },
    updateUhrzeitBis(value) {
      this.termin.bis_uhrzeit = value;
      this.uhrzeitBisKey++;
    },
    getHonorar() {
      Api.get("finanzen/honorar/", {
        params: { dozenteneinsatz: this.dozenteneinsatz[0].id },
      }).then((response) => {
        this.initializeHonorar(response.data);
      });
    },

    abbrechen() {
      this.$router.push({ name: "Terminliste" });
    },

    initializeDozentenseinsatz(dozenteneinsatz) {
      this.dozenteneinsatz = dozenteneinsatz;

      this.editable = this.berechtigungen.m_ressourcen.update;

      this.initializeChangewatcher(["termin"]);
    },

    initializeHonorar(honorar) {
      if (honorar?.length > 0) {
        this.honorar = honorar;
      }

      this.editable = this.berechtigungen.m_ressourcen.update;

      this.initializeChangewatcher(["termin"]);
    },

    initializeTermin(termin) {
      this.termin = termin;

      if (this.termin.reserviert_durch == null)
        this.termin.reserviert_durch = this.angemeldeterMitarbeiter.person;

      this.selectedGebaeude = this.termin.gebaeude?.id;
      //this.selectedRaum = this.termin.raumliste.map((raum) => raum.id);

      /*f (this.termin.verantwortlich == null) {
        this.termin.verantwortlich = this.angemeldeterMitarbeiter.person;
      }*/

      this.editable = this.berechtigungen.m_ressourcen.update;

      this.initializeChangewatcher(["termin"]);
      this.ladeArbeitsplaetze();
    },

    ladeArbeitsplaetze(id) {
      this.loading = true;

      //var params = null;

      Api.get("/arbeitsplatz/", {
        params: { raumID: id },
      })
        .then((response) => {
          this.arbeitsplatzListe = [];
          if (response.data) {
            response.data.forEach((arbeitsplatz) => {
              this.arbeitsplatzListe.push({
                ID: arbeitsplatz.id,
                bezeichnung: arbeitsplatz.bezeichnung,
                style: "font-size: 0.8em; cursor: pointer",
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setVerantwortlicher(person) {
      this.termin.verantwortlich = person;
    },

    setReserviertDurch(person) {
      this.termin.reserviert_durch = person;
    },

    setVeranstaltung(veranstaltung) {
      this.termin.veranstaltung = veranstaltung;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      if (this.termin.bis == null || this.termin.bis == "")
        this.termin.bis = this.termin.von;

      this.termin.raumliste = this.selectedRaum;
      this.termin.gebaeude = this.selectedGebaeude;
      var json = Object.assign({}, this.termin);

      /*json.veranstaltung = this.termin.veranstaltung.id;
      json.verantwortlich = this.termin.verantwortlich.id;
      json.reserviert_durch = this.termin.reserviert_durch.id;*/

      /*if (
        this.termin.ressourcenbuchung != null &&
        this.termin.ressourcenbuchung.id
      )
        json.ressourcenbuchung = this.termin.ressourcenbuchung.id;
      else json.ressourcenbuchung = this.termin.ressourcenbuchung;

      if (
        this.termin.ressourcenstatus != null &&
        this.termin.ressourcenstatus.id
      )
        json.ressourcenstatus = this.termin.ressourcenstatus.id;
      else json.ressourcenstatus = this.termin.ressourcenstatus;

      if (this.termin.konfliktstatus != null && this.termin.konfliktstatus.id)
        json.konfliktstatus = this.termin.konfliktstatus.id;
      else json.konfliktstatus = this.termin.konfliktstatus;

      json.raumliste = this.termin.raumliste.map((raum) =>
        raum.id ? raum.id : raum
      );
*/
      if (!this.id) {
        Api.post("termin/", json)
          .then((response) => {
            this.postSave(response.data);
            this.$router.replace({
              name: "termin",
              params: { id: response.data.termin.id, anzeige: 0 },
            });
            return response.data;
          })
          .then((responseData) => {
            this.createOrUpdateInQuickpool("post", responseData);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("termin/", json, { params: { id: this.termin.id } })
          .then((response) => {
            this.postSave(response.data);
            return response.data;
          })
          .then((responseData) => {
            // TODO PUT Anfrage an Quickpool mit TerminIDs
            this.createOrUpdateInQuickpool("put", responseData);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    createOrUpdateInQuickpool(mode, response) {
      if (this.$CONST("CONFIG").QUICKPOOLENABLED !== "true") return;

      const terminIds = this.collectIds(response);

      // TODO POST Anfrage an Quickpool mit TerminIDs
      if (mode === "post") {
        App.post("quickpool/termin/", terminIds)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.appointmentsuccessfullysavedinquickpool"),
            });
          })
          .catch(() => {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhilesavinginquickpool"),
            });
          });
      } else if (mode === "put") {
        App.put("quickpool/termin/", terminIds)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.appointmentsuccessfullysavedinquickpool"),
            });
          })
          .catch(() => {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhilesavinginquickpool"),
            });
          });
      }
    },

    collectIds(response) {
      // get ID from single Terminresponse
      const collectIds = [];

      if (response?.termin?.id) collectIds.push(response?.termin?.id);

      return collectIds;
    },

    postSave(data) {
      this.initializeTermin(data.termin);

      if (data.konflikte.length !== 0) {
        this.currentconflicts = data.konflikte;
        this.$bus.$emit("open-modal", "termin-konflikt-modal");
      }

      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.appointmentsuccessfullysaved"),
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "termin-loeschen-modal");
    },

    terminloeschen() {
      Api.delete("termin/", {
        params: { id: this.id },
      }).then(() => {
        this.$router.push({ name: "Terminliste" });
        this.deleteInQuickpool(this.id);
      });
    },

    deleteInQuickpool(id) {
      if (this.$CONST("CONFIG").QUICKPOOLENABLED !== "true") return;

      App.delete("quickpool/termin/", { params: { ids: id } })
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.appointmentsuccessfullydeletedinquickpool"),
          });
        })
        .catch(() => {
          this.$notify({
            type: "warn",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.errorwhilesavinginquickpool"),
          });
        });
    },

    AnwesenheitBearbeiten() {
      this.$router.push({
        path:
          "/bildung/anwesenheit/" +
          this.termin.veranstaltung.id +
          "/" +
          this.id,
      });
    },
    acceptConflict() {
      this.termin.konfliktstatus = this.konfliktstati.find((status) => {
        return status.id === "174E85F8CCE"; // akzeptiert
      });
      this.speichern();
    },
    setConflictStatus() {
      this.termin.konfliktstatus = this.konfliktstati.find((status) => {
        return status.id === "174E85F8CCC"; // vorhanden
      });
      this.speichern();
    },

    openTermin(terminId) {
      // open termin in new tab
      const url = this.$router.resolve({
        name: "termin",
        params: { id: terminId, anzeige: 0 },
      }).href;
      window.open(url, "_blank");
    },
  },
};
</script>

<style></style>
