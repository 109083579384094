<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.positions')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="neuePosition"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-regular fa-plus" />
              {{ $t("global.new") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-danger"
              v-if="
                (selectedPositionIds.length > 0 &&
                  auftrag.buchungsstatus.id != '1751D1D2995') ||
                editable
              "
              @click="oeffneLoeschenModal"
            >
              <font-awesome-icon icon="fa-duotone fa-trash" class="mr-1" />
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12" :key="positionenKey">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="positionen"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-clicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <auftrag-position-modal
      ref="positionModal"
      id="position-modal"
      :auftrag="auftrag"
      :positionId="editPosition"
      :editable="editable && auftrag.buchungsstatus.id != '1751D1D2995'"
      @position-changed="positionChanged"
      @position-created="positionCreated"
      @closed="positionModalClosed"
    ></auftrag-position-modal>
    <rechnungsplit-modal
      ref="RechnungsplitModal"
      id="rechnungsplit-modal"
      :auftrag="auftrag"
      :positionen="positionen"
    ></rechnungsplit-modal>
    <loeschen-modal
      id="position-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import AuftragPositionModal from "@/components/Auftrag/AuftragPositionModal";
import RechnungsplitModal from "@/components/Auftrag/RechnungsplitModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import numeral from "numeral";
import { v4 as uuid } from "uuid";

export default {
  name: "Auftragspositionen",
  components: {
    ContainerHeadline,
    LoeschenModal,
    AuftragPositionModal,
    RechnungsplitModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    auftrag: {
      type: Object,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 60,
      sortBy: "",
      sortDesc: false,
      positionen: [],
      position: null,
      editPosition: null,
      positionenKey: uuid(),
      selected: [],
      selectedPositionIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "artikel",
          sortable: true,
          label: this.$t("global.item"),
          formatter: (value) => {
            if (value)
              return `${value.artikelnummer} / ${value.kurzbezeichnung} / ${value.bezeichnung}`;
            else return "-";
          },
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "anzahl",
          sortable: true,
          label: this.$t("global.qty"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "artikeleinheit",
          sortable: true,
          label: this.$t("global.unit"),
          formatter: (value) => {
            if (value) {
              return value.bezeichnung;
            } else {
              return "-";
            }
          },
        },
        {
          key: "preis",
          sortable: true,
          label: this.$t("global.price"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "totalnetto",
          sortable: true,
          label: this.$t("global.totalnet"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "totalmwst",
          sortable: true,
          label: this.$t("global.totalvat"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "totalbrutto",
          sortable: true,
          label: this.$t("global.totalgross"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "ertragskonto",
          sortable: true,
          label: this.$t("global.revenueaccount"),
          formatter: (value) => {
            if (value) return `${value.kontonummer} / ${value.bezeichnung}`;
            else {
              return "-";
            }
          },
        },
        {
          key: "ertragskostenstelle",
          sortable: true,
          label: this.$t("global.incomecostcenter"),
          formatter: (value) => {
            if (value)
              return `${value.kostenstellennummer} / ${value.bezeichnung}`;
            else {
              return "-";
            }
          },
        },
      ];
    },
    queryParams() {
      return {
        auftrag: this.id,
        page: this.page,
        count: this.anzahlProPage,
      };
    },
  },
  watch: {
    shown(val) {
      if (val && this.positionen.length == 0) this.getPositionen();
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.id != "") {
      this.getPositionen();
    }
    this.getPositionen();
  },
  methods: {
    getPositionen() {
      Api.get("auftrag/position/", {
        params: this.queryParams,
      }).then((response) => {
        this.positionen = response.data;
        this.$emit("positionen-loaded", this.positionen);
      });
    },
    details(position) {
      this.editPosition = position.id;
      this.position = position;
      if (position.manuellerPreis) {
        this.editPosition.manuellerPreis = this.position.preis;
      }
      this.$bus.$emit("open-modal", "position-modal");
    },
    positionModalClosed() {
      this.editPosition = null;
    },
    infiniteHandler($state) {
      Api.get("auftrag/position/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.positionen.push(...response.data);
          $state.loaded();
        } else {
          this.positionen.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedPositionIds.includes(gp.id)) {
            this.selectedPositionIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedPositionIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedPositionIds.push(row.item.id);
      } else {
        this.selectedPositionIds = this.selectedPositionIds.filter(
          (id) => id != row.item.id
        );
      }
    },
    neuePosition() {
      this.editPosition = null;
      this.position = {
        artikel: { artikeltyp: {} },
        preis: {},
        ertragskonto: {},
        ertragskostenstelle: {},
        artikeleinheit: {},
      };
      this.$bus.$emit("open-modal", "position-modal");
    },

    oeffneRechnungsplitModal() {
      this.$bus.$emit("open-modal", "rechnungsplit-modal");
    },
    positionChanged(position) {
      let index = this.positionen.indexOf(
        this.positionen.find((p) => p.id === position.id)
      );
      if (index !== -1) this.positionen[index] = position;

      this.positionenKey = uuid();
      this.editPosition = null;
      this.$emit("positionen-changed");
    },
    positionCreated(position) {
      this.positionen.push(position);
      this.$emit("positionen-changed");
    },
    async loeschen() {
      let ids = Array.from(new Set(this.selectedPositionIds));

      await Api.delete("auftrag/position/", { data: ids });
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.invoicepositiondeletedsuccessfully"),
      });
      this.schliesseLoeschenModal();
      this.positionen = this.positionen.filter((p) => !ids.includes(p.id));
      this.$emit("positionen-changed");

      this.selectedPositionIds = [];
    },
    schliesseLoeschenModal() {
      $("#position-loeschen-modal").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#position-loeschen-modal").modal("show");
    },
  },
};
</script>
