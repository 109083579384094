<template>
  <div>
    <container-headline
      :headline="$t('global.masterdata')"
      :col="2"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <input
                  :readonly="!editable"
                  v-model.trim="kurzveranstaltung.kuerzel"
                  class="form-control"
                  tabindex="1"
                  v-bind:class="{ 'border-danger': !passed }"
                  placeholder=" "
                />

                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.shortdesignation")
                }}</label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <input
                  :readonly="!editable"
                  v-model.trim="kurzveranstaltung.bezeichnung"
                  class="form-control"
                  v-bind:class="{ 'border-danger': !passed }"
                  tabindex="2"
                  placeholder=" "
                />
                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.designation")
                }}</label>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 form-group" :key="statuselement">
              <v-select
                :disabled="!editable"
                v-model="kurzveranstaltung.veranstaltungsstatus"
                :getOptionLabel="
                  (veranstaltungsstatus) =>
                    veranstaltungsstatus.bezeichnung
                      ? veranstaltungsstatus.bezeichnung
                      : veranstaltungsstatus.Bezeichnung
                "
                :options="veranstaltungsstati"
                eager="true"
                :tabindex="11"
                :placeholder="$t('global.status')"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.status") }}</label>
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="kurzveranstaltungleiter"
                :person="this.kurzveranstaltung.leitung"
                :label="$t('global.eventmanager')"
                :allowOpen="true"
                :editable="editable"
                :required="false"
                tabindex="6"
                @confirmed="setKurzveranstaltungsLeiter"
              />
            </div>

            <div class="col-xl-3">
              <single-select-person
                id="kontakt"
                :person="this.kurzveranstaltung.kontakt"
                :label="$t('global.contact')"
                :allowOpen="true"
                :editable="editable"
                tabindex="6"
                @confirmed="setKontakt"
              />
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="verantwortlich"
                :person="this.kurzveranstaltung.verantwortlich"
                :label="$t('global.responsible')"
                :allowOpen="false"
                :editable="editable"
                @confirmed="setVerantwortlich"
                tabindex="7"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <v-select
                  :disabled="!editable"
                  v-model="kurzveranstaltung.kurzveranstaltungstyp"
                  label="bezeichnung"
                  :options="kurzveranstaltungstypen"
                  eager="true"
                  :tabindex="12"
                  :placeholder="$t('global.shorteventtype')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label v-bind:class="{ 'text-danger': !passed }"
                  >{{ $t("global.shorteventtype") }}
                </label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="kurzveranstaltung.thema_auswahl"
                label="bezeichnung"
                :options="themen"
                :disabled="!editable"
                :placeholder="$t('global.dashboard_topic')"
                multiple
                :tabindex="13"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.dashboard_topic") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="kurzveranstaltung.zielgruppen"
                :getOptionLabel="
                  (zielgruppe) =>
                    zielgruppe.bezeichnung
                      ? zielgruppe.bezeichnung
                      : zielgruppe.Bezeichnung
                "
                :options="zielgruppen"
                :disabled="!editable"
                :placeholder="$t('global.targetgroups')"
                multiple
                :tabindex="13"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.targetgroups") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <v-select
                  v-model="kurzveranstaltung.sprachen"
                  v-bind:class="{ 'border-danger': !passed }"
                  :getOptionLabel="
                    (sprachen) =>
                      sprachen.bezeichnung
                        ? sprachen.bezeichnung
                        : sprachen.Bezeichnung
                  "
                  :options="sprachen"
                  :disabled="!editable"
                  multiple
                  :placeholder="$t('global.language')"
                  :tabindex="13"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.language")
                }}</label>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-2 form-group" v-if="!isTemplate">
              <date-picker
                :disabled="!editable"
                date-format="dd.mm.yy"
                :initial="kurzveranstaltung.startdatum"
                placeholer=" "
                :show-icon="true"
                :tabindex="14"
                @update="(val) => (kurzveranstaltung.startdatum = val)"
              />
              <label>{{ $t("global.start") }}</label>
            </div>
            <div class="col-xl-2 form-group" v-if="!isTemplate">
              <date-picker
                :disabled="!editable"
                date-format="dd.mm.yy"
                :initial="kurzveranstaltung.enddatum"
                placeholder=" "
                :show-icon="true"
                :tabindex="15"
                @update="(val) => (kurzveranstaltung.enddatum = val)"
              />
              <label>{{ $t("global.end") }}</label>
            </div>

            <div class="col-xl-1 form-group">
              <input
                :readonly="!editable"
                v-model.number="kurzveranstaltung.teilnehmeranzahl_maximal"
                class="form-control"
                tabindex="18"
                placeholder=" "
              />
              <label
                >{{ $t("global.participants") }}
                {{ $t("global.maximum") }}</label
              >
            </div>
            <div class="col-xl-1">
              <div class="form-check">
                <input
                  :disabled="!editable"
                  v-model="kurzveranstaltung.warteliste_zulassen"
                  class="form-check-input"
                  type="checkbox"
                  tabindex="19"
                  :label="$t('global.allowwaitinglist')"
                />
                <label class="form-check-label">{{
                  $t("global.allowwaitinglist")
                }}</label>
              </div>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="kurzveranstaltung.abschluss"
                :getOptionLabel="
                  (abschluss) =>
                    abschluss.bezeichnung ? abschluss.bezeichnung : abschluss.id
                "
                :options="abschluesse"
                :disabled="!editable"
                :placeholder="$t('global.proof')"
                :tabindex="20"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.proof") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 form-group">
              <v-select
                v-model="kurzveranstaltung.veranstaltungsgruppe"
                :getOptionLabel="
                  (gruppe) =>
                    gruppe.bezeichnung ? gruppe.bezeichnung : gruppe.id
                "
                :options="filteredVeranstaltungsgruppen"
                :disabled="!editable"
                :placeholder="$t('global.eventgroup')"
                :tabindex="22"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.eventgroup") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="kurzveranstaltung.veranstaltungskategorie"
                :getOptionLabel="
                  (kategorie) =>
                    kategorie.bezeichnung ? kategorie.bezeichnung : kategorie.id
                "
                :options="
                  kurzveranstaltung.veranstaltungsgruppe
                    ? kurzveranstaltung.veranstaltungsgruppe
                        .veranstaltungskategorien
                    : veranstaltungskategorien
                "
                :disabled="!editable"
                :placeholder="$t('global.eventcategory')"
                :tabindex="23"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.eventcategory") }}</label>
            </div>

            <div class="col-xl-3 form-group">
              <v-select
                :disabled="!editable"
                v-model="kurzveranstaltung.institution"
                :getOptionLabel="(i) => i.bezeichnung"
                :options="institutionen"
                :placeholder="$t('global.institution')"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.institution") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 form-group">
              <textarea
                :readonly="!editable"
                v-model.trim="kurzveranstaltung.beschreibung"
                class="form-control"
                tabindex="21"
                placeholder=" "
              ></textarea>
              <label>{{ $t("global.internalremarks") }}</label>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model="kurzveranstaltung.vorbereitung"
                  :disabled="!editable"
                ></ckeditor>
                <label>{{ $t("global.preparation") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import ClassicEditor from "/custom/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "KurzveranstaltungStammdaten",
  components: {
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
  },
  props: {
    kurzveranstaltung: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    isTemplate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      statuselement: +new Date(),
      editor: ClassicEditor,
    };
  },
  computed: {
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    kurzveranstaltungstypen: {
      get() {
        return this.$store.state.veranstaltung.kurzveranstaltungstypen;
      },
    },
    themen: {
      get() {
        return this.$store.state.veranstaltung.themen;
      },
    },
    zielgruppen: {
      get() {
        return this.$store.state.veranstaltung.zielgruppen;
      },
    },
    sprachen: {
      get() {
        return this.$store.state.veranstaltung.sprachen;
      },
    },
    abschluesse: {
      get() {
        return this.$store.state.veranstaltung.nachweise;
      },
    },
    veranstaltungsgruppen: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsgruppen;
      },
    },
    veranstaltungskategorien: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungskategorien;
      },
    },
    institutionen: {
      get() {
        return this.$store.state.veranstaltung.institutionen;
      },
    },
    filteredVeranstaltungsgruppen() {
      if (this.kurzveranstaltung.veranstaltungskategorie) {
        return this.veranstaltungsgruppen.filter((gruppe) => {
          return gruppe.veranstaltungskategorien.some(
            (kategorie) =>
              kategorie.id === this.kurzveranstaltung.veranstaltungskategorie.id
          );
        });
      } else return this.veranstaltungsgruppen;
    },
  },
  watch: {
    "kurzveranstaltung.veranstaltungskategorie": function (newVal) {
      if (newVal && this.filteredVeranstaltungsgruppen.length === 1) {
        this.kurzveranstaltung.veranstaltungsgruppe =
          this.filteredVeranstaltungsgruppen[0];
      }
    },
  },
  mounted: function () {
    if (this.veranstaltungsstati.length == 0) {
      this.$store
        .dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`)
        .then(() => {
          this.initializeData();
        });
    } else {
      this.initializeData();
    }
  },
  methods: {
    initializeData() {
      if (!this.kurzveranstaltung.veranstaltungsstatus) {
        const kurzveranstaltungStatusPlanung = this.$CONST(
          "VERANSTALTUNGSSTATI"
        ).PLANNED;

        this.kurzveranstaltung.veranstaltungsstatus =
          this.veranstaltungsstati.find(
            ({ id }) => id == kurzveranstaltungStatusPlanung
          );
        this.statuselement += 1;
      }
    },

    setKurzveranstaltungsLeiter(person) {
      this.kurzveranstaltung.leitung = person;
    },
    setKontakt(person) {
      this.kurzveranstaltung.kontakt = person;
    },
    setVerantwortlich(verantwortlich) {
      this.kurzveranstaltung.verantwortlich = verantwortlich;
    },
  },
};
</script>

<style lang="scss"></style>
