<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.consentconfiguration") }}</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12 pr-4">
            <!-- START Stammdaten -->
            <container-headline
              :headline="$t('global.masterdata')"
              :col="6"
            ></container-headline>

            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row" style="align-items: flex-end">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="consenttext_template"
                          :getOptionLabel="(el) => el.bezeichnung"
                          :options="consenttextTemplates"
                          :placeholder="$t('global.consenttemplates')"
                          style="z-index: 70"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.consenttemplates") }}</label>
                      </div>
                    </div>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="deleteTemplate"
                      style="height: 31.5px; margin-bottom: 8px"
                      :disabled="!consenttext_template"
                      :title="
                        !consenttext_template
                          ? $t('global.notemplateselected')
                          : ''
                      "
                    >
                      {{ $t("global.deletethistemplate") }}
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <ckeditor
                          :editor="editor"
                          v-model="consenttext"
                        ></ckeditor>
                        <label v-bind:class="{ 'text-danger': !hasInput }">{{
                          $t("global.consenttext")
                        }}</label>
                      </div>
                    </div>
                    <div class="d-flex col-xl-12">
                      <div class="mr-3">
                        <div class="form-group">
                          <button
                            class="btn btn-primary btn-sm"
                            @click="toggleLinkContentSelection()"
                          >
                            <font-awesome-icon
                              icon="fa-duotone fa-link"
                              class="mr-1"
                            />
                            {{ $t("global.linkcontents") }}
                          </button>
                        </div>
                      </div>
                      <div class="mb-1">
                        <div class="form-group">
                          <button
                            class="btn btn-sm"
                            :class="
                              showTemplateInput ? 'btn-success' : 'btn-primary'
                            "
                            @click="handleSaveAsTemplate"
                            :disabled="
                              !showTemplateInput
                                ? !consenttext
                                : !hasConsenttextAndBezeichnung
                            "
                            :title="
                              !consenttext
                                ? $t('global.noconsenttext')
                                : showTemplateInput && !template_bezeichnung
                                ? $t('global.notemplatedesignation')
                                : ''
                            "
                          >
                            {{ $t("global.saveastemplate") }}
                          </button>
                        </div>
                      </div>

                      <div v-if="showTemplateInput" class="form-group col-xl-2">
                        <label for="template_bezeichnung">{{
                          $t("global.templatedesignation")
                        }}</label>
                        <input
                          type="text"
                          id="template_bezeichnung"
                          v-model="template_bezeichnung"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ENDE Stammdaten -->

            <!-- START Linkcontents -->
            <div v-show="showLinkContentSelection">
              <container-headline
                :headline="$t('global.linkcontents')"
                :col="6"
              ></container-headline>

              <div class="row mb-3">
                <div class="col-xl-12 block br-t-l-0">
                  <div class="eingabe-panel-kopf p-3">
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="linkcontent"
                            :getOptionLabel="(el) => el.bezeichnung"
                            :options="linkcontents"
                            :placeholder="$t('global.linkcontent')"
                          >
                            <span slot="no-options">{{
                              $t("global.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.linkcontent") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group d-flex">
                          <button
                            class="btn btn-primary btn-sm"
                            @click="copyLinkToClipboard()"
                            :disabled="!linkcontent?.id"
                            :title="$t('global.pleaseselectlinkedcontent')"
                          >
                            <font-awesome-icon
                              icon="fa-duotone fa-copy"
                              class="mr-1"
                            />
                            {{ $t("global.copylink") }}
                          </button>
                          <span v-if="copied" class="ml-2">{{
                            $t("global.linkcopied")
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="linkcontent" class="col-xl-12 p-0">
                      <div class="col-xl-12 p-0 d-flex">
                        <div
                          v-for="(lang, index) in langs"
                          :key="'headline-' + index"
                          class="col-2 p-0"
                        >
                          <div
                            class="col clickable p-0"
                            @click="setActiveLang(index)"
                          >
                            <div
                              class="block block-kopf bg-medium-blue"
                              :class="
                                activeLang === index
                                  ? ''
                                  : 'bg-grayscale-5 hover'
                              "
                            >
                              <span class="col primary-headline-text p-0">
                                {{ lang.kuerzel }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 p-0">
                        <div
                          v-for="(lang, index) in langs"
                          :key="index"
                          class="col-xl-12 p-0"
                        >
                          <div v-if="activeLang === index">
                            <div class="row">
                              <div class="col-xl-12">
                                <div
                                  class="form-group"
                                  style="
                                    border: 1px solid #c9d7de;
                                    padding: 16px 16px 0;
                                  "
                                >
                                  <div
                                    v-html="
                                      linkcontent[
                                        'content_' + lang.kuerzel.toLowerCase()
                                      ]
                                    "
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Linkcontents -->
          </div>
        </div>
        <div class="modal-footer consent-config-modal-footer">
          <span v-if="showTemplateInput">
            {{ $t("global.notesaveconsent") }}
          </span>

          <div>
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="!hasInput"
              class="btn btn-primary"
              @click="speichern"
              v-tooltip
              :title="
                !hasInput ? $t('global.pleasefillinallrequiredfields') : ''
              "
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>

<script>
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";
import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "@/store/einstellungendashboard/actions.type";

import store from "@/store";
import AppApi from "@/AppApi";

import page from "@/mixins/Page";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import LoadingOverlay from "@/components/global/LoadingOverlay";
import ClassicEditor from "/custom/custom_ckeditor/build/ckeditor";
import { LADE_AUSWAHLWERTE_ANMLEDEFORMULAR } from "../../store/veranstaltung/actions.type";

export default {
  name: "ConsentConfigModal",
  components: {
    ContainerHeadline,
    ModalCloseButton,
    LoadingOverlay,
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      loading: false,
      shown: false,
      consenttextTemplates: [],
      consenttext_template: null,
      showLinkContentSelection: false,
      linkcontent: null,
      consenttext: "",
      editor: ClassicEditor,
      hasInput: false,
      showTemplateInput: false,
      template_bezeichnung: "",
      feld: {},
      copied: false, // Flag for visual confirmation of copied link
      activeLang: 0,
    };
  },
  watch: {
    consenttext_template(val) {
      if (val) {
        this.consenttext = val.consenttext;
      }
    },
    consenttext(val) {
      if (val) {
        this.hasInput = true;
      } else {
        this.hasInput = false;
      }
    },
  },
  computed: {
    langs: {
      get() {
        return this.$store.state.einstellungendashboard.sprachen;
      },
    },
    /*     linkContents: {
        get() {
        return this.$store.state.veranstaltung.staticpages;
      },
    }, */
    linkcontents: {
      get() {
        return this.$store.state.veranstaltung.staticpages;
      },

      /*       return [
        {
          id: "asdsaddsasdsa",
          bezeichnung: "Testcontent",
          content_de: "<p>de content</p>",
          content_en: "<p>en content</p>",
          content_fr: "<p>fr content</p>",
          content_it: "<p>it content</p>",
        },
        {
          id: "123123124124142",
          bezeichnung: "Testcontent2",
          content_de: "<p>de content</p>",
          content_en: "<p>en content</p>",
          content_fr: "<p>fr content</p>",
          content_it: "<p>it content</p>",
        },
      ]; */
    },

    /*     consenttextTemplates() {
      return [
        {
          bezeichnung: "Template Bez",
          consenttext: "<p><b>this is bold tetx from template</b></p>",
        },
      ]; // Todo
    }, */

    hasConsenttextAndBezeichnung() {
      return this.template_bezeichnung && this.consenttext;
    },
  },
  mounted() {
    this.$bus.$on("openConsentConfigModal", (id, feld) => {
      this.feld = feld;
      this.consenttext = "";
      this.showLinkContentSelection = false;
      this.template_bezeichnung = "";
      this.showTemplateInput = false;
      if (!this.shown) this.openModal(id);
    });

    this.$bus.$on("editConsentConfigModal", (id, feld) => {
      if (!this.shown) this.openModal(id);
      this.feld = feld;
      this.showLinkContentSelection = false;
      this.template_bezeichnung = "";
      this.showTemplateInput = false;
      if (feld.consent) {
        this.consenttext = feld.consent;
      }
    });

    if (this.langs.length == 0) {
      this.$store.dispatch(
        `einstellungendashboard/${LADE_AUSWAHLWERTE_EINSTELLUNGEN}`
      );
    }

    if (this.linkcontents.length == 0) {
      this.$store.dispatch(
        `veranstaltung/${LADE_AUSWAHLWERTE_ANMLEDEFORMULAR}`
      );
    }

    if (this.consenttextTemplates.length === 0) {
      AppApi.get("einverstaendnistemplate")
        .then((response) => {
          this.consenttextTemplates = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    }
  },
  methods: {
    openModal(id) {
      this.shown = true;
      const thisId = id ? id : this.id;
      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    toggleLinkContentSelection() {
      this.showLinkContentSelection = !this.showLinkContentSelection;
    },

    copyLinkToClipboard() {
      if (!this.linkcontent?.id) return;
      navigator.clipboard
        .writeText(
          this.$CONST("CONFIG").BASEURL +
            "/portal/page/?id=" +
            this.linkcontent.id
        )
        .then(() => {
          this.copied = true;
          setTimeout(() => (this.copied = false), 2000); // Hide confirmation after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    },

    speichern() {
      this.$emit("confirmed", this.feld, this.consenttext);
      this.closeModal();
    },

    handleSaveAsTemplate() {
      this.showTemplateInput = true;

      if (!this.template_bezeichnung) return;

      this.saveAsTemplate();
    },

    saveAsTemplate() {
      console.log("saving template consenttext");
      // TODO new enpoint save bezeichnung + consettext as html in new table bxc_einverstaendnisfrage
      // trigger auswahlwerte call für templates

      AppApi.post("einverstaendnistemplate", {
        id: null,
        bezeichnung: this.template_bezeichnung,
        consenttext: this.consenttext,
        activ: "j",
      })
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.consenttemplatesavedsuccessfully"),
          });
          this.consenttextTemplates.push(response.data);
          this.showTemplateInput = false;
          this.template_bezeichnung = "";
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {});
    },

    setActiveLang(index) {
      this.activeLang = index;
    },

    deleteTemplate() {
      let idToDelete = this.consenttext_template.id;
      AppApi.delete("einverstaendnistemplate/", {
        params: { id: idToDelete },
      })
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.consenttemplatedeletedsuccessfully"),
          });
          // remove the template with deleted Id
          this.consenttextTemplates = this.consenttextTemplates.filter(
            (el) => el.id != idToDelete
          );
          this.consenttext_template = null;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped></style>
