<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.portalinformation')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              :disabled="pruefeDaten"
              @click="speicherePortalinformationen"
              v-if="editable"
              v-tooltip
              :title="pruefeDaten ? $t('global.portalinfonotvalid') : ''"
            >
              {{ $t("global.save") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-primary"
              @click="
                portalinformationen.uhrzeit_anzeigen =
                  !portalinformationen.uhrzeit_anzeigen
              "
              v-if="editable"
            >
              {{
                portalinformationen.uhrzeit_anzeigen
                  ? $t("global.hideclocktime")
                  : $t("global.showclocktime")
              }}
              <span
                ><font-awesome-icon
                  v-if="portalinformationen.uhrzeit_anzeigen === false"
                  icon="fa-solid fa-check"
                  class="pl-2" /><font-awesome-icon
                  v-if="portalinformationen.uhrzeit_anzeigen === true"
                  icon="fa-regular fa-xmark"
                  class="pl-2"
              /></span>
            </button>
          </div>
          <div v-if="portalinformationen.publiziert != null">
            <toggle-button
              :toggleValue="portalinformationen.publiziert"
              :option1="$t('global.notpublished')"
              :option2="$t('global.published')"
              @update="updateToggleValue"
            ></toggle-button>
          </div>
        </div>
      </container-headline>

      <div class="row col-xl-12 mb-3">
        <div class="col-xl-12 block br-t-l-0">
          <div
            class="eingabe-panel-kopf p-3 d-flex justify-content-center"
            v-if="isBusy && !ladeFehler"
          >
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <div
            class="eingabe-panel-kopf p-3 d-flex justify-content-center text-danger"
            v-if="ladeFehler"
          >
            <strong>{{ $t("global.errorwhileloading") }}</strong>
          </div>
          <div class="eingabe-panel-kopf p-3" v-if="!isBusy && !ladeFehler">
            <div class="row">
              <!-- TODO: TABINDEXES und PLACEHOLDER -->
              <div class="col-xl-3 form-group">
                <date-picker
                  :disabled="!editable"
                  date-format="dd.mm.yy"
                  :initial="portalinformationen.publikation_von"
                  placeholer=" "
                  :show-icon="true"
                  @update="
                    (val) =>
                      setDateAndDefaultTime(
                        val,
                        'publikation_von',
                        'publikation_startzeit',
                        '00:00'
                      )
                  "
                  :class="{
                    'border border-2 border-danger border-radius-6':
                      !portalinformationen.publikation_von &&
                      portalinformationen.publikation_startzeit,
                  }"
                />
                <label>{{ $t("global.publicationstartdate") }}</label>
              </div>

              <div class="col-xl-3 form-group">
                <div class="input-group">
                  <input
                    type="time"
                    :disabled="!editable"
                    class="form-control"
                    v-model="portalinformationen.publikation_startzeit"
                    :readonly="!portalinformationen.uhrzeit_anzeigen"
                  />
                  <div
                    class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-clock"
                      class="m-auto"
                    />
                  </div>
                </div>
                <label>{{ $t("global.publicationstarttime") }}</label>
              </div>

              <div class="col-xl-3 form-group">
                <date-picker
                  :disabled="!editable"
                  date-format="dd.mm.yy"
                  :initial="portalinformationen.publikation_bis"
                  placeholer=" "
                  :show-icon="true"
                  @update="
                    (val) =>
                      setDateAndDefaultTime(
                        val,
                        'publikation_bis',
                        'publikation_endzeit',
                        '23:59'
                      )
                  "
                  :class="{
                    'border border-2 border-danger border-radius-6':
                      !portalinformationen.publikation_bis &&
                      portalinformationen.publikation_endzeit,
                  }"
                />
                <label>{{ $t("global.publicationenddate") }}</label>
              </div>

              <div class="col-xl-3 form-group">
                <div class="input-group">
                  <input
                    type="time"
                    :disabled="!editable"
                    class="form-control"
                    v-model="portalinformationen.publikation_endzeit"
                    :readonly="!portalinformationen.uhrzeit_anzeigen"
                  />
                  <div
                    class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-clock"
                      class="m-auto"
                    />
                  </div>
                </div>
                <label>{{ $t("global.publicationendtime") }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 form-group">
                <date-picker
                  :disabled="!editable"
                  date-format="dd.mm.yy"
                  :initial="portalinformationen.anmeldung_von"
                  placeholer=" "
                  :show-icon="true"
                  @update="
                    (val) =>
                      setDateAndDefaultTime(
                        val,
                        'anmeldung_von',
                        'anmeldung_startzeit',
                        '00:00'
                      )
                  "
                  :class="{
                    'border border-2 border-danger border-radius-6':
                      !portalinformationen.anmeldung_von &&
                      portalinformationen.anmeldung_startzeit,
                  }"
                />
                <label>{{ $t("global.registrationstartdate") }}</label>
              </div>

              <div class="col-xl-3 form-group">
                <div class="input-group">
                  <input
                    type="time"
                    :disabled="!editable"
                    class="form-control"
                    v-model="portalinformationen.anmeldung_startzeit"
                    :readonly="!portalinformationen.uhrzeit_anzeigen"
                  />
                  <div
                    class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-clock"
                      class="m-auto"
                    />
                  </div>
                </div>
                <label>{{ $t("global.registrationstarttime") }}</label>
              </div>

              <div class="col-xl-3 form-group">
                <date-picker
                  :disabled="!editable"
                  date-format="dd.mm.yy"
                  :initial="portalinformationen.anmeldung_bis"
                  placeholer=" "
                  :show-icon="true"
                  @update="
                    (val) =>
                      setDateAndDefaultTime(
                        val,
                        'anmeldung_bis',
                        'anmeldung_endzeit',
                        '23:59'
                      )
                  "
                  :class="{
                    'border border-2 border-danger border-radius-6':
                      !portalinformationen.anmeldung_bis &&
                      portalinformationen.anmeldung_endzeit,
                  }"
                />
                <label>{{ $t("global.registrationenddate") }}</label>
              </div>

              <div class="col-xl-3 form-group">
                <div class="input-group">
                  <input
                    type="time"
                    :disabled="!editable"
                    class="form-control"
                    v-model="portalinformationen.anmeldung_endzeit"
                    :readonly="!portalinformationen.uhrzeit_anzeigen"
                  />
                  <div
                    class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-clock"
                      class="m-auto"
                    />
                  </div>
                </div>
                <label>{{ $t("global.registrationendtime") }}</label>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-3">
                <div class="form-group">
                  <input
                    :readonly="!editable"
                    v-model.trim="portalinformationen.dauer"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.duration") }}</label>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <!--
                  <input
                    :readonly="!editable"
                    v-model.trim="portalinformationen.kursort"
                    class="form-control"
                    placeholder=" "
                  />-->
                  <v-select
                    :disabled="!editable"
                    v-model="portalinformationen.Gebaeude"
                    :getOptionLabel="
                      (gebaeude) =>
                        gebaeude.bezeichnung
                          ? gebaeude.bezeichnung
                          : gebaeude.Bezeichnung
                    "
                    :options="gebaeude"
                    :placeholder="$t('global.building')"
                  >
                    <span slot="no-options">{{
                      $t("notification.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.building") }}</label>
                </div>
              </div>
              <!--  <div class="col-xl-3">
                <div class="form-group">
                  <input
                    :readonly="!editable"
                    v-model.trim="portalinformationen.kurszeiten"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.classtimes") }}</label>
                </div>
              </div> -->
              <div class="col-xl-3">
                <div class="form-group">
                  <input
                    :readonly="!editable"
                    v-model.trim="portalinformationen.link"
                    class="form-control"
                    placeholder=" "
                    @blur="checkHTTPS"
                  />
                  <label>{{ $t("global.linktothirdparty") }}</label>
                </div>
              </div>
              <div
                class="col-xl-3 form-group"
                v-tooltip.hover
                :title="$t('global.showsdatesinwebshop')"
              >
                <input
                  :disabled="!editable"
                  type="checkbox"
                  class="mr-2"
                  v-model="portalinformationen.zeige_kursdaten"
                />
                {{ $t("global.showcoursedates") }}
              </div>
            </div>

            <div class="row">
              <div class="col-xl-3">
                <div class="form-group">
                  <vue-tel-input
                    class="phone-input"
                    id="phone-contact"
                    v-model="portalinformationen.telefon_kontakt"
                    :disabled="!editable"
                    :inputOptions="inputOptions"
                    mode="international"
                  ></vue-tel-input>
                  <label>{{ $t("global.contacttel") }}</label>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <input
                    :readonly="!editable"
                    v-model.trim="portalinformationen.email_kontakt"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.contactemail") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6 form-group">
                <v-select
                  :disabled="!editable"
                  v-model="portalinformationen.Abschluesse"
                  :getOptionLabel="
                    (abschluss) =>
                      abschluss.bezeichnung
                        ? abschluss.bezeichnung
                        : abschluss.Bezeichnung
                  "
                  :options="abschluesse"
                  multiple
                  eager="true"
                  :placeholder="$t('global.degrees')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.degrees") }}</label>
              </div>
              <div class="col-xl-6 form-group">
                <v-select
                  :disabled="!editable"
                  v-model="portalinformationen.Methodik"
                  :getOptionLabel="
                    (methode) =>
                      methode.bezeichnung
                        ? methode.bezeichnung
                        : methode.Bezeichnung
                  "
                  :options="methoden"
                  multiple
                  eager="true"
                  :placeholder="$t('global.teachingmethod')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.teachingmethod") }}</label>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.beschreibung"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.description") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.kurszeiten"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.informationonclasstimes") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.ziele"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.goals") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.inhalte"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.content") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.zulassungsbedingungen"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.admissionconditions") }}</label>
                </div>
              </div>
              <!-- <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.vorbereitung"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.preparation") }}</label>
                </div>
              </div> -->
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.voraussetzung"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.requirement") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.kosten"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.costs") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.annulationsbedingungen"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.cancellationconditions") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.verpflegung"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.catering") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.zielgruppe_beschreibung"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.targetgroupdescription") }}</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <ckeditor
                    :editor="editor"
                    v-model="portalinformationen.zusatzinformationen"
                    :disabled="!editable"
                  ></ckeditor>
                  <label>{{ $t("global.additionalinfo") }}</label>
                </div>
              </div>
            </div>

            <div
              class="row"
              v-if="portalinformationen.id"
              id="portalinfoDateien"
            >
              <div class="col-xl-6 input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input clickable"
                    id="portalPictureInput"
                    ref="portalPictureInput"
                    accept="image/jpeg, image/png"
                    @change="handlePictureUpload"
                  />
                  <label
                    class="custom-file-label clickable"
                    for="portalPictureInput"
                    @click="durchsucheBilder"
                    >{{
                      selectedPictureName
                        ? selectedPictureName
                        : bildAuswahlText
                    }}</label
                  >
                </div>
                <button
                  class="btn btn-medium-blue ml-2"
                  @click="durchsucheBilder"
                >
                  {{ $t("global.selectimage") }}
                </button>

                <button
                  class="btn btn-primary ml-2"
                  @click="uploadPicture"
                  :disabled="!portalinfoPicture"
                >
                  {{ $t("global.uploadimage") }}
                </button>

                <button
                  v-if="portalinformationen.Bild"
                  class="btn btn-success ml-2"
                  @click="openPicture"
                >
                  {{ $t("global.openimage") }}
                </button>
                <button
                  v-if="portalinformationen.Bild"
                  class="btn btn-danger ml-2"
                  @click="oeffneBildLoeschenMsgBox"
                >
                  <font-awesome-icon icon="fa-duotone fa-trash" />
                </button>
              </div>

              <div class="col-xl-6 input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input clickable"
                    id="portalFileInput"
                    ref="portalFileInput"
                    @change="handleFilepload"
                  />
                  <label
                    class="custom-file-label clickable"
                    for="portalFileInput"
                    @click="durchsucheFiles"
                    >{{
                      selectedFileName ? selectedFileName : dokumentAuswahlText
                    }}</label
                  >
                </div>
                <button
                  class="btn btn-medium-blue ml-2"
                  @click="durchsucheFiles"
                >
                  {{ $t("global.selectdocument") }}
                </button>
                <button
                  class="btn btn-primary ml-2"
                  @click="uploadFile"
                  :disabled="!portalinfoFile"
                >
                  {{ $t("global.uploaddocument") }}
                </button>
                <button
                  v-if="portalinformationen.Lageplan"
                  class="btn btn-success ml-2"
                  @click="openFile"
                >
                  {{ $t("global.opendocument") }}
                </button>
                <button
                  v-if="portalinformationen.Lageplan"
                  class="btn btn-danger ml-2"
                  @click="oeffneDokumentLoeschenMsgBox"
                >
                  <font-awesome-icon icon="fa-duotone fa-trash" />
                </button>
              </div>
            </div>
            <div class="row mt-2" v-if="portalinformationen.id">
              <div class="col-xl-6">
                <img
                  class="w-100-p"
                  v-if="portalinformationen.Bild"
                  :src="bildURL"
                  alt="Image Preview"
                />
              </div>
              <!-- <div class="col-xl-6">
                <iframe
                  v-if="portalinformationen.Lageplan"
                  :src="fileURL"
                  width="100%"
                  height="500px"
                  frameborder="0"
                ></iframe>
              </div> -->
              <div class="col-xl-6">
                <iframe
                  v-if="fileURL ? isDisplayableFile(fileURL) : false"
                  :src="fileURL"
                  width="100%"
                  height="500px"
                  frameborder="0"
                ></iframe>
                <p v-else>{{ getFileNameFromURL(fileURL) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <messagebox-enhanced
      :headerText="$t('global.delete')"
      id="bild-loeschen-msgbox"
      :ok="true"
      :cancel="true"
      :okText="$t('global.confirm')"
      :cancelText="$t('global.cancel')"
      @ok="deletePicture()"
    />
    <messagebox-enhanced
      :headerText="$t('global.delete')"
      id="dokument-loeschen-msgbox"
      :ok="true"
      :cancel="true"
      :okText="$t('global.confirm')"
      :cancelText="$t('global.cancel')"
      @ok="deleteFile()"
    />
  </div>
</template>

<script>
import PortalApi from "@/PortalApi";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import DatePicker from "@/components/Datepicker";
import ClassicEditor from "/custom/custom_ckeditor/build/ckeditor";
import reiter from "@/mixins/Reiter";
import ToggleButton from "@/components/global/functionButtons/toggleButton";

import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import store from "@/store";
import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { LADE_AUSWAHLWERTE_ANMELDUNG } from "@/store/anmeldung/actions.type";

export default {
  name: "Portalinformationen",
  components: {
    ContainerHeadline,
    DatePicker,
    BSpinner,
    MessageboxEnhanced,
    ToggleButton,
  },
  mixins: [reiter, ChangeWatcher],
  props: {
    lehrgang: {
      type: Object,
      required: true,
    },
    portalinfoId: {
      type: String,
      default: null,
    },
    isKurzveranstaltung: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sortBy: "periodennummer",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      ckEditorloaded: false,
      selectedPictureName: null,
      selectedFileName: null,
      portalinfoPicture: null,
      portalinfoFile: null,
      portalinformationen: {
        id: null,
        publiziert: null,
        publikation_von: null,
        publikation_startzeit: null,
        publikation_bis: null,
        publikation_endzeit: null,
        anmeldung_von: null,
        anmeldung_startzeit: null,
        anmeldung_bis: null,
        anmeldung_endzeit: null,
        beschreibung: "",
        dauer: "",
        ziele: "",
        inhalte: "",
        zulassungsbedingungen: "",
        // vorbereitung: "",
        Abschluesse: [],
        zeige_kursdaten: null,
        kursort: null,
        kurszeiten: "",
        link: "",
        kosten: "",
        annulationsbedingungen: "",
        verpflegung: "",
        zielgruppe_beschreibung: "",
        voraussetzung: "",
        zusatzinformationen: "",
        Lageplan: null,
        Bild: null,
        Methodik: [],
        telefon_kontakt: null,
        email_kontakt: null,
        uhrzeit_anzeigen: false,
      },
      editor: ClassicEditor,
    };
  },
  watch: {
    portalinfoId(val) {
      if (val) {
        this.getPortalinformationen();
      }
    },
    hasChanged(newValue, oldValue) {
      if (newValue == true) {
        this.$emit("portalinfoChanged");
      }
    },
    pruefeDaten(newVal) {
      this.$emit("portalinfoValidChanged", !newVal);
    },
  },
  mounted() {
    this.$bus.$on("savePortalinfo", () => {
      this.speicherePortalinformationen();
    });
    if (this.methoden.length === 0) {
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
    }
    if (this.abschluesse.length === 0) {
      this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);
    }
  },
  beforeDestroy() {
    this.$bus.$off("savePortalinfo");
  },
  computed: {
    abschluesse: {
      get() {
        return this.$store.state.anmeldung.abschluesse;
      },
    },
    methoden: {
      get() {
        return this.$store.state.veranstaltung.lehrmethoden;
      },
    },
    gebaeude: {
      get() {
        return this.$store.state.veranstaltung.gebaeude;
      },
    },
    bildURL() {
      if (this.portalinformationen.Bild) {
        return PortalApi.defaults.defaultURL + this.portalinformationen.Bild;
      } else {
        return null;
      }
    },
    fileURL() {
      if (this.portalinformationen.Lageplan) {
        return (
          PortalApi.defaults.defaultURL + this.portalinformationen.Lageplan
        );
      } else {
        return null;
      }
    },
    bildAuswahlText() {
      return this.$t("global.selectimage");
    },
    dokumentAuswahlText() {
      return this.$t("global.selectdocument");
    },
    inputOptions() {
      return { placeholder: this.$t("global.enteraphonenumber") };
    },
    pruefeDaten() {
      //TODO: Abfangen, wenn ein falsches datum eingegeben wird (z.B. 31.05.20231029321)
      //Wenn eine Uhrzeit eingegeben ist, muss zwingend das dazugehörige Datum eingegeben werden
      if (
        (!this.portalinformationen.publikation_von ||
          !this.portalinformationen.publikation_von === "") &&
        this.portalinformationen.publikation_startzeit
      ) {
        return true;
      }
      if (
        (!this.portalinformationen.publikation_bis ||
          !this.portalinformationen.publikation_bis === "") &&
        this.portalinformationen.publikation_endzeit
      ) {
        return true;
      }
      if (
        (!this.portalinformationen.anmeldung_von ||
          !this.portalinformationen.anmeldung_von === "") &&
        this.portalinformationen.anmeldung_startzeit
      ) {
        return true;
      }
      if (
        (!this.portalinformationen.anmeldung_bis ||
          !this.portalinformationen.anmeldung_bis === "") &&
        this.portalinformationen.anmeldung_endzeit
      ) {
        return true;
      }
      return false;
    },
  },
  created() {},
  methods: {
    getPortalinformationen() {
      this.isBusy = true;
      PortalApi.get("portalinfo/", {
        params: {
          id: this.portalinfoId,
        },
      })
        .then((response) => {
          this.initializePortalinfo(response);
          this.$emit("portalinfoFirstInitialized");
          this.ladeFehler = false;
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    initializePortalinfo(response) {
      this.portalinformationen = response.data;
      this.initChangeWatcher(this.portalinformationen);
      this.$emit("portalinfoInitialized");
    },
    speicherePortalinformationen() {
      let json = Object.assign({}, this.portalinformationen);

      this.isBusy = true;

      if (this.portalinfoId || this.portalinformationen?.id) {
        let parameter = this.isTemplate
          ? { tid: this.lehrgang.id }
          : { id: this.lehrgang.id };
        PortalApi.put("portalinfo/", json, {
          params: parameter,
        })
          .then((response) => {
            this.initializePortalinfo(response);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.portalinformationsavedsuccessfully"),
            });

            this.ladeFehler = false;
          })
          .catch((e) => {
            console.log(e);
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      } else {
        let parameter = {};
        if (this.isTemplate) {
          parameter.tid = this.lehrgang.id;
        } else {
          parameter.id = this.lehrgang.id;
        }
        parameter.kurzveranstaltung = this.isKurzveranstaltung;

        PortalApi.post("portalinfo/", json, {
          params: parameter,
        })
          .then((response) => {
            this.initializePortalinfo(response);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.portalinformationsavedsuccessfully"),
            });
          })
          .catch((e) => {
            console.log(e);
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },
    handlePictureUpload() {
      this.portalinfoPicture = event.target.files[0];

      if (
        this.portalinfoPicture &&
        this.portalinfoPicture.type.startsWith("image/")
      ) {
        this.selectedPictureName = this.portalinfoPicture.name;
      } else {
        this.selectedPictureName = null;
      }
    },
    uploadPicture() {
      //const file = this.$refs.portalPictureInput.files[0];
      const file = this.portalinfoPicture;
      const formData = new FormData();
      formData.append("file", file);

      PortalApi.post("portalinfo/upload/picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          pi: this.portalinformationen.id,
        },
      })
        .then((response) => {
          if (response.data.success === true) {
            this.portalinformationen.Bild = response.data.url;

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.imageuploadedsuccessfully"),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhileuploadingimage"),
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    openPicture() {
      window.open(this.bildURL, "_blank");
    },
    durchsucheBilder() {
      // Trigger des Klick-Events des Eingabefelds
      this.$refs.portalPictureInput.click();
    },
    handleFilepload() {
      this.portalinfoFile = event.target.files[0];

      if (this.portalinfoFile) {
        this.selectedFileName = this.portalinfoFile.name;
      } else {
        this.selectedFileName = null;
      }
    },
    uploadFile() {
      // const file = this.$refs.portalFileInput.files[0];
      const file = this.portalinfoFile;
      const formData = new FormData();
      formData.append("file", file);

      PortalApi.post("portalinfo/upload/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          pi: this.portalinformationen.id,
        },
      })
        .then((response) => {
          if (response.data.success === true) {
            this.portalinformationen.Lageplan = response.data.url;

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.documentuploadedsuccessfully"),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhileuploadingdocument"),
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    durchsucheFiles() {
      this.$refs.portalFileInput.click();
    },
    openFile() {
      window.open(this.fileURL, "_blank");
    },
    isDisplayableFile(url) {
      const fileExtension = this.getFileExtension(url);
      return ["pdf", "png", "jpg", "jpeg", "gif"].includes(fileExtension);
    },
    getFileExtension(url) {
      const parts = url?.split(".");
      return parts[parts.length - 1].toLowerCase();
    },
    getFileNameFromURL(url) {
      if (!url) return;
      const parts = url.split("/");
      const name = parts[parts.length - 1];
      return decodeURIComponent(name.replace(/%(..)/g, "%$1")); // ersetz URL-encoded special characters mit den originalen Werten
    },
    checkHTTPS() {
      if (
        this.portalinformationen.link &&
        !this.portalinformationen.link.includes("https://")
      ) {
        this.portalinformationen.link =
          "https://" + this.portalinformationen.link;
      }
    },
    oeffneBildLoeschenMsgBox() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "bild-loeschen-msgbox",
        text: this.$t("global.doyoureallywanttodeletefile"),
      });
    },
    deletePicture() {
      PortalApi.delete("portalinfo/upload/picture", {
        params: {
          pi: this.portalinformationen.id,
        },
      })
        .then((response) => {
          if (response.data.success === true) {
            this.portalinformationen.Bild = response.data.url;
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.imagedeletedsuccessfully"),
            });

            this.$refs.portalPictureInput.value = null;
            this.portalinfoPicture = null;
            this.selectedPictureName = null;
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhiledeletingimage"),
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    oeffneDokumentLoeschenMsgBox() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "dokument-loeschen-msgbox",
        text: this.$t("global.doyoureallywanttodeletefile"),
      });
    },
    deleteFile() {
      PortalApi.delete("portalinfo/upload/file", {
        params: {
          pi: this.portalinformationen.id,
        },
      })
        .then((response) => {
          if (response.data.success === true) {
            this.portalinformationen.Lageplan = response.data.url;
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.documentdeletedsuccessfully"),
            });

            this.$refs.portalFileInput.value = null;
            this.portalinfoFile = null;
            this.selectedFileName = null;
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.errorwhiledeletingdocument"),
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    setDialCode(country) {
      console.log(country);
    },
    setDateAndDefaultTime(newDate, dateKey, timeKey, defaultTime) {
      //Setze Datum
      this.$set(this.portalinformationen, dateKey, newDate);

      //Setze oder entferne eine Default Zeit, wenn ein Datum gsetzt oder gelöscht wird
      if (newDate && !this.portalinformationen[timeKey]) {
        //Wenn Wert für Datum gesetzt, setze Default Uhrzeit
        this.$set(this.portalinformationen, timeKey, defaultTime);
      } else if (
        !newDate &&
        this.portalinformationen[timeKey] === defaultTime
      ) {
        //Wenn Wert für Datum entfernt, setze default Uhrzeit zurück
        this.$set(this.portalinformationen, timeKey, null);
      }
    },
    updateToggleValue(value) {
      this.portalinformationen.publiziert = value;
    },
  },
};
</script>

<style scoped>
#portalinfoDateien .custom-file-label::after {
  display: none;
}
</style>
