<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ $t("global.createinvoice") }}</h4>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body" v-if="vorlage">
          <div class="row">
            <div class="col-12">
              <h4>{{ $t("global.template") }}</h4>
            </div>
            <div clasS="col-12">
              <v-select
                v-model="vorlage"
                :options="vorlagen"
                label="bezeichnung"
                :clearable="false"
              >
              </v-select>
            </div>
            <div class="col-12 mt-3">
              <h4>{{ $t("global.invoicemasterdata") }}</h4>
            </div>

            <div class="col-6">
              <div class="form-group">
                <date-picker
                  :initial="rechnungsdatum"
                  @update="(val) => (rechnungsdatum = val)"
                  show-icon
                  date-format="dd.mm.yy"
                ></date-picker>
                <label>{{ $t("global.invoicedate") }}</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="vorlage.zahlungskondition"
                  :getOptionLabel="(option) => option.bezeichnung"
                  :options="zahlungskonditionen"
                  :placeholder="$t('global.termsofpayment')"
                  :clearable="false"
                >
                </v-select>
                <label>{{ $t("global.termsofpayment") }}</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="vorlage.institution"
                  :getOptionLabel="(option) => option.bezeichnung"
                  :options="institutionen"
                  :placeholder="$t('global.institution')"
                  :clearable="true"
                  :reduce="(el) => el.id"
                >
                </v-select>
                <label>{{ $t("global.institution") }}</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="vorlage.zahlungsverbindung"
                  :getOptionLabel="(option) => option.bezeichnung"
                  :options="zahlungsverbindungen"
                  :placeholder="$t('global.paymentdetails')"
                  :clearable="false"
                  :disabled="vorlage.institution"
                  v-tooltip.hover="
                    vorlage.institution
                      ? $t('global.paymentconnectionsetbyinstitution')
                      : ''
                  "
                >
                </v-select>
                <label>{{ $t("global.paymentdetails") }}</label>
              </div>
            </div>
            <div class="col-12">
              <single-select-person
                id="kontaktperson"
                :person="kontaktperson"
                :label="$t('global.contactperson')"
                :rollen="filterMitarbeitende"
                :allowOpen="false"
                :editable="true"
                @confirmed="setKontaktperson"
              />
            </div>
            <div class="col-12">
              <div class="form-group">
                <input class="form-control" v-model.trim="unsereReferenz" />
                <label>{{ $t("global.ourreference") }}</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <textarea
                  rows="5"
                  class="form-control"
                  v-model.trim="einleitungstext"
                />
                <label>{{ $t("global.introductiontext") }}</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <textarea
                  rows="5"
                  class="form-control"
                  v-model.trim="schlusstext"
                />
                <label>{{ $t("global.closingtext") }}</label>
              </div>
            </div>
          </div>
          <verrechnungspositionen
            v-if="veranstaltung"
            :veranstaltung="veranstaltung"
            :shown="true"
            @vpGesetzt="setVerrechnungpositionIds"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="closeModal"
          >
            {{ $t("global.cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-danger mr-2"
            @click="entfernen"
            v-if="remove"
          >
            {{ $t("global.remove") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="rechnungErstellen"
            :disabled="VerrechnungspositionIds.length == 0"
          >
            {{ $t("global.createinvoice") }} ({{ anmeldungen.length }})
          </button>
        </div>
      </div>
    </div>
    <messagebox-error
      :headerTitel="$t('global.createinvoice')"
      :errorText="$t('global.notification_failed')"
      id="error-rechnung-erstellen-alert-msgbox"
      @ok="closeModal"
    />

    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import AppApi from "@/AppApi";
import DatePicker from "@/components/Datepicker";
import Verrechnungspositionen from "@/components/Rechnungslegung/Verrechnungspositionen.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import { LADE_AUSWAHLWERTE_AUFTRAG } from "@/store/auftrag/actions.type";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import MessageboxError from "@/components/Modals/MessageboxError.vue";

import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  components: {
    DatePicker,
    Verrechnungspositionen,
    SingleSelectPerson,
    MessageboxError,
    ModalCloseButton,
    LoadingOverlay,
  },
  name: "RechnungErstellenModal",
  mixins: [modal],
  props: {
    anmeldungen: {
      type: Array,
      required: true,
      default: () => [],
    },
    veranstaltung: {
      type: String,
      required: false,
      default: null,
    },
    angemeldeterMitarbeiter: {
      type: Object,
      required: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vorlage: null,
      vorlagen: [],
      rechnungsdatum: new Date().toGermanDateString(),
      kontaktperson: {},
      //kontaktpersonen: [],
      unsereReferenz: null,
      einleitungstext: null,
      zahlungskondition: {
        id: "17520A6C023",
        kuerzel: "30",
        bezeichnung: "",
        sortierung: 20,
        tage: 30,
        standard: true,
      },
      schlusstext: null,
      VerrechnungspositionIds: [],
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
      loading: false,
    };
  },
  watch: {
    vorlage(val) {
      this.einleitungstext = val.einleitungstext;
      this.schlusstext = val.schlusstext;
    },
    "vorlage.institution": function (val) {
      if (val) {
        this.vorlage.zahlungsverbindung = null;
      }
    },
    shown(val) {
      if (val && !this.kontaktperson?.id)
        this.kontaktperson = this.angemeldeterMitarbeiter.person;
      if (val && this.vorlagen.length == 0) {
        Api.get("rechnungslegung/vorlage/").then((response) => {
          this.vorlagen = response.data;
          this.vorlage = response.data.find((e) => e.standard) || null;
        });
      }
      if (val && this.zahlungskonditionen.length == 0) {
        this.$store.dispatch(`auftrag/${LADE_AUSWAHLWERTE_AUFTRAG}`);
      }
    },
  },
  created() {},
  mounted() {},
  computed: {
    zahlungskonditionen: {
      get() {
        return this.$store.state.auftrag.zahlungskonditionen;
      },
    },
    zahlungsverbindungen: {
      get() {
        return this.$store.state.auftrag.zahlungsverbindungen;
      },
    },
    zahlungsplaene: {
      get() {
        return this.$store.state.auftrag.zahlungsplaene;
      },
    },
    institutionen: {
      get() {
        return this.$store.state.veranstaltung.institutionen;
      },
    },
  },
  methods: {
    setVerrechnungpositionIds(val) {
      this.VerrechnungspositionIds = val;
    },

    setKontaktperson(person) {
      //console.log("APIUSOD");
      //console.log("PERSON: " + person);
      this.kontaktperson = person;
    },

    /*
    searchKontaktperson(sucheString, loading) {
      if (sucheString) {
        loading(true);
        Api.get("geschaeftspartner/", {
          params: {
            suche: sucheString,
          },
        })
          .then((response) => {
            this.kontaktpersonen = response.data;
          })
          .finally(() => {
            loading(false);
          });
      }
    },
    kontaktpersonName(option) {
      if (typeof option === "string") {
        let person = this.kontaktpersonen.find((p) => p.id === option);
        if (person) return person.label;
        else "";
      } else {
        return option.personName;
      }
    },*/

    rechnungErstellen() {
      if (this.loading) return;
      this.loading = true;
      AppApi.post("veranstaltung/rechnung/", {
        anmeldungen: Array.from(new Set(this.anmeldungen)),
        vorlage: this.vorlage ? this.vorlage.id : null,
        zahlungskondition: this.vorlage.zahlungskondition?.id,
        zahlungsverbindung: this.vorlage.zahlungsverbindung?.id,
        institution: this.vorlage.institution,
        rechnungsdatum: this.rechnungsdatum,
        kontaktperson: this.kontaktperson?.id,
        veranstaltung: this.veranstaltung,
        unsereReferenz: this.unsereReferenz,
        einleitungstext: this.einleitungstext,
        schlusstext: this.schlusstext,
        VerrechnungspositionIds: Array.from(
          new Set(this.VerrechnungspositionIds)
        ),
      })
        .then((response) => {
          if (response.data.error.length != 0) {
            this.$bus.$emit("openMessageboxError", {
              data: response.data.error,
              id: "error-rechnung-erstellen-alert-msgbox",
            });
          } else {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.allinvoicescreatedsuccessfully"),
            });
            this.closeModal();
            this.$emit("changed");
          }
          /*
        this.$router.push({
          path: "/finanzen/auftraege",
        });
        */
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },
    entfernen() {},
  },
};
</script>

<style></style>
