<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.scoreentrytemplate')"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="
                      berechtigungen.b_bildung_noteneingabe.delete && editable
                    "
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-8">
                    <div class="form-group">
                      <input
                        v-model="noteneingabe.bezeichnung"
                        :readonly="!editable || isDozent"
                        class="form-control"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="noteneingabe.noteneingabe_typ"
                          label="bezeichnung"
                          :options="noteneingabetypen"
                          :disabled="!editable || isDozent"
                          :placeholder="$t('global.scoreinputtype')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.scoreinputtype")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <v-select
                        v-model="noteneingabe.noteneingabe_status"
                        label="bezeichnung"
                        :options="noteneingabestati"
                        :disabled="!editable"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <v-select
                        v-model="noteneingabe.sichtbar_mit_details"
                        label="bezeichnung"
                        :options="sichtbarkeiten"
                        :disabled="!editable || isDozent"
                        :placeholder="$t('global.visibility')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.visibility") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <div class="form-group">
                      <v-select
                        v-model="noteneingabe.notentyp"
                        label="bezeichnung"
                        :options="notentypen"
                        :disabled="!editable || isDozent"
                        :placeholder="$t('global.scoretype')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.scoretype") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="noteneingabe.sichtbar_von"
                        :disabled="isDozent || !editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (noteneingabe.sichtbar_von = val)"
                      />
                      <label>{{ $t("global.Visiblefrom") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <time-field
                      placeholder=" "
                      :initial="noteneingabe.sichtbar_von_uhrzeit"
                      :readonly="isDozent || !editable"
                      :label="$t('global.Time')"
                      @update="
                        (val) => (noteneingabe.sichtbar_von_uhrzeit = val)
                      "
                    />
                  </div>
                </div>

                <!-- ROW 3 -->

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="noteneingabe.sichtbar_bis"
                        placeholder=" "
                        :show-icon="true"
                        :disabled="isDozent || !editable"
                        @update="(val) => (noteneingabe.sichtbar_bis = val)"
                      />
                      <label>{{ $t("global.visibleuntil") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <time-field
                      placeholder=" "
                      :initial="noteneingabe.sichtbar_bis_uhrzeit"
                      :readonly="isDozent || !editable"
                      :label="$t('global.Time')"
                      @update="
                        (val) => (noteneingabe.sichtbar_bis_uhrzeit = val)
                      "
                    />
                  </div>
                </div>

                <!-- ROW 4 -->
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="noteneingabe-loeschen-modal"
      @confirmed="noteneingabeloeschen"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import {
  LADE_AUSWAHLWERTE_NOTEN,
  LADE_FAECHER,
} from "@/store/noten/actions.type";

import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import TimeField from "@/components/TimeField.vue";

export default {
  name: "TemplateNoteneingabe",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    LoeschenModal,
    TimeField,
    UnsavedChangesModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return this.$t("global.scoreentrytemplate");
      },
    };
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    kategorieProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      editable: true,
      pruefungKey: 0,
      nfKey: 0,
      isDozent: false,
      mspRollen: this.$CONST("ROLLEN").DOZENTEN,
      msvTypen: [this.$CONST("VERANSTALTUNGSTYPEN").MODULE],

      noteneingabe: {
        fach: {
          bezeichnung: this.$t("global.notlinked"),
          noteneingabetyp: { bezeichnung: this.$t("global.notlinked") },
        },
        noteneingabe_status: { bezeichnung: this.$t("global.notlinked") },
        noteneingabe_typ: { bezeichnung: this.$t("global.notlinked") },
        notenberechnung: { bezeichnung: this.$t("global.notlinked") },
        veranstaltungen: [],
        verantwortliche: [],
      },

      veranstaltungen: [],
      verantwortliche: [],

      showPruefung: true,

      sortBy: "name",
      sortDesc: false,
    };
  },
  computed: {
    fields() {
      return [
        { key: "nummer", sortable: true, label: this.$t("global.num") },
        { key: "name", sortable: true, label: this.$t("global.surname") },
        { key: "vorname", sortable: true, label: this.$t("global.firstname") },
        { key: "typ", sortable: true, label: this.$t("global.type") },
        { key: "pruefung1", sortable: true, label: this.$t("global.examone") },
        { key: "durchschnitt", sortable: true, label: "Ø" },
        {
          key: "korrektur",
          sortable: true,
          label: this.$t("global.correction"),
        },
        {
          key: "dispensiert",
          sortable: true,
          label: this.$t("global.dispensed"),
        },
        {
          key: "durchschnittDefinitiv",
          sortable: true,
          label: "Ø " + this.$t("global.definitive"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    eingabeTyp: function () {
      let eingabeTyp = this.noteneingabe.noteneingabe_typ;

      if (!eingabeTyp) {
        // Übergang vor Migration von Typ aus Fach direkt zu Noteneingabe
        if (!this.noteneingabe.fach || !this.noteneingabe.fach.noteneingabetyp)
          return "noten";

        eingabeTyp = this.noteneingabe.fach.noteneingabetyp;
      }

      if (eingabeTyp.id == this.$CONST("NOTENEINGABETYPEN").CHOICE) {
        return "textwahl";
      }
      if (eingabeTyp.id == this.$CONST("NOTENEINGABETYPEN").TEXT) {
        return "freitext";
      } else {
        return "noten";
      }
      /*
      return eingabeTyp.id == this.$CONST("NOTENEINGABETYPEN").CHOICE
        ? "textwahl"
        : "noten";
        */
    },
    noteneingabestati: {
      get() {
        return this.$store.state.noten.noteneingabestati;
      },
    },
    sichtbarkeiten: {
      get() {
        return this.$store.state.noten.sichtbarkeiten;
      },
    },
    noteneingabetypen: {
      get() {
        return this.$store.state.noten.noteneingabetypen;
      },
    },
    faecher: {
      get() {
        return this.$store.state.noten.faecher;
      },
    },
    notentypen: {
      get() {
        return this.$store.state.noten.notentypen;
      },
    },
  },
  watch: {
    noteneingabetypen: function (typen) {
      if (typen && !this.noteneingabe.noteneingabe_typ) {
        let netNoten = this.$CONST("NOTENEINGABETYPEN").GRADES;

        this.noteneingabe.noteneingabe_typ = typen.find(
          (typ) => typ.id == netNoten
        );
      }
    },
    eingabeTyp: function (value) {
      let anzeige = value == "textwahl" || value == "freitext" ? 2 : 0;
      if (this.anzeige == anzeige) return;

      this.$router.push({
        name: "noteneingabe",
        params: {
          id: this.noteneingabe.id,
          anzeige: anzeige,
        },
      });
    },
  },
  created() {
    this.loading = true;

    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    )
      this.isDozent = true;

    this.loadNoteneingabe();
  },
  mounted: function () {
    if (this.noteneingabestati.length == 0)
      this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);

    if (this.faecher.length == 0) this.$store.dispatch(`noten/${LADE_FAECHER}`);
  },
  methods: {
    pruefungUpdated() {
      this.loadNoteneingabe();
      this.pruefungKey++;
      console.log("pruefungUpdated");
    },

    pruefungDeleted() {
      location.reload();
    },

    loadNoteneingabe() {
      if (this.id) {
        Api.get("noteneingabe/", { params: { id: this.id } }).then(
          (response) => {
            this.initializeNoteneingaben(response.data);

            this.loading = false;
          }
        );
      } else {
        this.editable = true;
        this.loading = false;
      }
    },

    oeffneVerantwortlicher() {
      if (this.noteneingabe.verantwortlicher) {
        this.$router.push({
          path:
            "/stammdaten/geschaeftspartner/" +
            this.noteneingabe.verantwortlicher.id,
        });
      }
    },

    oeffneVeranstaltung() {
      if (this.noteneingabe.veranstaltung) {
        this.$router.push({
          path: "/bildung/Klasse/" + this.noteneingabe.veranstaltung.id,
        });
      }
    },

    selectVeranstaltung() {},
    oeffneNotenBerechnung() {},
    selectNotenBerechnung() {},
    abbrechen() {
      this.$router.push({ name: "noteneingabe-liste" });
    },

    initializeNoteneingaben(noteneingabe) {
      if (noteneingabe) {
        this.noteneingabe = noteneingabe;

        this.noteneingabe.fach = noteneingabe.fach ? noteneingabe.fach : [];

        // Für Übergangsphase bis Migration fertig hier noch Übernahme von Noteneingabetyp direkt auf die Noteneingabe

        if (
          !this.noteneingabe.noteneingabe_typ &&
          this.noteneingabe.fach.noteneingabetyp
        ) {
          this.noteneingabe.noteneingabe_typ =
            this.noteneingabe.fach.noteneingabetyp;
        }

        if (this.noteneingabe.noteneingabe_typ) {
          this.showPruefung =
            this.noteneingabe.noteneingabe_typ.id ==
            this.$CONST("NOTENEINGABETYPEN").GRADES
              ? true
              : false;
        }

        this.noteneingabe.notenberechnung = noteneingabe.notenberechnung
          ? noteneingabe.notenberechnung
          : [];

        this.veranstaltungen = noteneingabe.veranstaltungen
          ? noteneingabe.veranstaltungen
          : [{ bezeichnung: this.$t("global.notlinked") }];

        if (this.veranstaltungen.length == 0)
          this.veranstaltungen = [{ bezeichnung: this.$t("global.notlinked") }];

        this.verantwortliche = noteneingabe.verantwortliche
          ? noteneingabe.verantwortliche
          : [{ personName: this.$t("global.notlinked") }];

        if (this.verantwortliche.length == 0)
          this.verantwortliche = [{ personName: this.$t("global.notlinked") }];

        this.editable =
          this.$CONST("NOTENEINGABESTATI").COMPLETED ||
          noteneingabe.noteneingabe_status.id ==
            this.$CONST("NOTENEINGABESTATI").ARCHIVED
            ? true
            : false;

        if (this.isDozent) {
          /* Dozent darf nicht mehr ändern wenn 'Sichtbar bis' überschritten ist */
          if (this.noteneingabe.sichtbar_bis) {
            var parts = this.noteneingabe.sichtbar_bis.split(".");
            var checkDate = new Date(parts[2], parts[1] - 1, parts[0]);
            if (new Date() > checkDate) this.editable = false;
          }
        }

        if (this.berechtigungen.b_bildung_noteneingabe.update == false)
          this.editable = false;

        this.initializeChangewatcher(["noteneingabe"]);
      } else {
        console.error("noteneingabe ist null");
      }
    },

    speichern() {
      if (this.loading) return;
      this.loading = true;
      var json = this.noteneingabe;
      json.veranstaltungen = this.veranstaltungen;
      json.verantwortliche = this.verantwortliche;

      if (!this.id) {
        Api.post("noteneingabe/", json)
          .then((response) => {
            this.initializeNoteneingaben(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.scoreinputsavedsuccessfully"),
            });

            this.$router.replace({
              name: "noteneingabe",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("noteneingabe/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeNoteneingaben(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.scoreinputsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "noteneingabe-loeschen-modal");
    },

    noteneingabeloeschen() {
      Api.delete("noteneingabe/", {
        params: { ids: this.id },
      }).then(() => {
        this.$router.push({ name: "noteneingabe-liste" });
      });
    },
    updatePruefungen() {
      this.pruefungKey++;
      //this.nfKey++;
    },
  },
};
</script>

<style></style>
