var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":_vm.id}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('modal-close-button',{on:{"confirmed":_vm.closeModal}}),_c('h4',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.neuePosition ? _vm.$t("global.newclearingitem") : _vm.$t("global.editclearingitem"))+" ")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row ml-0 mr-0"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('container-headline',{attrs:{"headline":_vm.$t('global.masterdata'),"col":6}}),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
                                  _vm.verrechnungsposition.artikel.artikelnummer
                                ),expression:"\n                                  verrechnungsposition.artikel.artikelnummer\n                                ",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"placeholder":" ","readonly":"true"},domProps:{"value":(
                                  _vm.verrechnungsposition.artikel.artikelnummer
                                )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.verrechnungsposition.artikel, "artikelnummer", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.itemnumber")))])])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.artikelListe,"get-option-label":(e) =>
                                    `${e.artikelnummer} / ${e.kurzbezeichnung} / ${e.bezeichnung}`,"placeholder":_vm.$t('global.item'),"clearable":false},model:{value:(_vm.verrechnungsposition.artikel),callback:function ($$v) {_vm.$set(_vm.verrechnungsposition, "artikel", $$v)},expression:"verrechnungsposition.artikel"}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.item")))])],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(
                                  _vm.verrechnungsposition.artikel.artikeltyp
                                    .bezeichnung
                                ),callback:function ($$v) {_vm.$set(_vm.verrechnungsposition.artikel.artikeltyp
                                    , "bezeichnung", $$v)},expression:"\n                                  verrechnungsposition.artikel.artikeltyp\n                                    .bezeichnung\n                                "}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.itemtype")))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.verrechnungsposition.anzahl),expression:"verrechnungsposition.anzahl",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"placeholder":" "},domProps:{"value":(_vm.verrechnungsposition.anzahl)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.verrechnungsposition, "anzahl", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.Number")))])])]),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(
                                  _vm.verrechnungsposition.artikel.einheit
                                    .bezeichnung
                                ),callback:function ($$v) {_vm.$set(_vm.verrechnungsposition.artikel.einheit
                                    , "bezeichnung", $$v)},expression:"\n                                  verrechnungsposition.artikel.einheit\n                                    .bezeichnung\n                                "}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.unit")))])],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ passed }){return [_c('v-select',{key:_vm.verrechnungsposition.artikel.id,attrs:{"placeholder":_vm.$t('global.price'),"options":_vm.preisOptions},on:{"input":_vm.handlePriceChange},model:{value:(_vm.selectedPrice),callback:function ($$v) {_vm.selectedPrice=$$v},expression:"selectedPrice"}}),(_vm.customPriceEnabled)?_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
                                    _vm.verrechnungsposition.manueller_preis
                                  ),expression:"\n                                    verrechnungsposition.manueller_preis\n                                  ",modifiers:{"number":true}}],staticClass:"form-control mt-2",class:{
                                    'border-danger':
                                      !_vm.verrechnungsposition.manueller_preis,
                                  },attrs:{"type":"number","placeholder":_vm.$t('global.individualprice')},domProps:{"value":(
                                    _vm.verrechnungsposition.manueller_preis
                                  )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.verrechnungsposition, "manueller_preis", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e(),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.price")))])]}}],null,true)})],1)])])])])])],1)])])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("global.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":invalid || !_vm.verrechnungsposition.manueller_preis},on:{"click":_vm.speichern}},[_vm._v(" "+_vm._s(_vm.$t("global.save"))+" ")])])]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }