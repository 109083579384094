<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                  <button
                    class="btn btn-primary mr-2"
                    @click="oeffneProjektErstelungsModal"
                  >
                    Projekt / Aufgabe erstellen
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_timereporting.delete && editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("timereporting.resettimesheets") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-person
                      id="mitarbeiter"
                      :person="timesheet.mitarbeiter"
                      :label="$t('global.employee')"
                      :allowOpen="true"
                      :editable="editable"
                      :required="true"
                      @confirmed="setMitarbeiter"
                      :tabindex="1"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="timesheet.datum"
                        placeholer=" "
                        :show-icon="true"
                        @update="(val) => (timesheet.datum = val)"
                        :disabled="!editable"
                      />
                      <label>{{ $t("global.date") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="timesheet.status"
                        :options="timesheetstati"
                        label="bezeichnung"
                        :disabled="!editable"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 form-group">
                    <b-card-group deck>
                      <b-card
                        class="text-center"
                        bg-variant="info"
                        text-variant="white"
                        no-body
                      >
                        <b-card-body style="padding: 0.25rem">
                          <b-card-text style="margin-bottom: 0.25rem">
                            <b>{{ $t("global.hours") }}</b> </b-card-text
                          ><b-card-text>
                            <h2>
                              <b-badge variant="light">{{
                                arbeitszeit
                              }}</b-badge>
                            </h2>
                          </b-card-text>
                        </b-card-body>
                      </b-card>

                      <b-card
                        class="text-center"
                        bg-variant="info"
                        text-variant="white"
                        no-body
                      >
                        <b-card-body style="padding: 0.25rem">
                          <b-card-text style="margin-bottom: 0.25rem">
                            <b>{{
                              $t("global.remaininghours")
                            }}</b> </b-card-text
                          ><b-card-text>
                            <h2>
                              <b-badge variant="light">{{
                                nichtRapportierteStunden
                                  ? nichtRapportierteStunden
                                  : 0
                              }}</b-badge>
                            </h2>
                          </b-card-text>
                        </b-card-body>
                      </b-card>
                    </b-card-group>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- START Rechte Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.timeblocks')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <zeitblock-liste
              :timesheet="timesheet"
              :editable="editable"
              :zeitbloecke="timesheet.zeitbloecke"
            />
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- HEADER DER REITER -->

        <div v-if="id" class="col-xl-6 p-0">
          <projekt-erfassungs-liste
            :timesheet="id"
            :shown="true"
            :editable="editable"
            :arbeitszeit="arbeitszeit"
            :rapportierteStunden="rapportierteStunden"
            @updateRapportierteStunden="updateProjektStunden"
          />
        </div>
        <div v-if="id" class="col-xl-6 p-0">
          <interne-erfassungs-liste
            :timesheet="id"
            :shown="true"
            :editable="editable"
            :arbeitszeit="arbeitszeit"
            :rapportierteStunden="rapportierteStunden"
            :nichtrapportierteStunden="nichtRapportierteStunden"
            @updateRapportierteStunden="updateInterneStunden"
          />
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <neues-projekt-erfassen-modal
      :kunden="kunden"
      :projekte="projekte"
      :projektstati="projektstati"
      @erfasseNeuesProjekt="erfasseNeuesProjekt"
    />

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal id="timesheet-loeschen-modal" @confirmed="timesheetReset" />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import ProjektErfassungsListe from "@/components/Reiter/Projekterfassungsliste";
import InterneErfassungsListe from "@/components/Reiter/Interneerfassungsliste";
import NeuesProjektErfassenModal from "@/components/Modals/NeuesProjektErfassenModal";

import { round } from "@/utils/bxchTools.js";

import { LADE_AUSWAHLWERTE_ZEITERFASSUNG } from "@/store/zeiterfassung/actions.type";
import ZeitblockListe from "./ZeitblockListe.vue";

export default {
  name: "Timesheet",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    LoeschenModal,
    SingleSelectPerson,
    ProjektErfassungsListe,
    InterneErfassungsListe,
    ZeitblockListe,
    NeuesProjektErfassenModal,
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    datum: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fromRoute: null,
      editable: true,
      timesheet: {
        mitarbeiter: {},
        zeitbloecke: [],
      },
      zeitkonto: {},
      rapportierteStunden: 0,
      projektStunden: 0,
      interneStunden: 0,

      timeblockPanelStyle: {
        height: "420px",
        "overflow-y": "scroll",
      },
    };
  },
  watch: {},
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    navbarTitel: function () {
      if (this.id) {
        return `Zeitblatt`;
      } else {
        return this.$t("global.timereporting");
      }
    },
    timesheetstati: {
      get() {
        return this.$store.state.zeiterfassung.timesheetstati;
      },
    },

    // Summe aller Zeitbloecke bzw. totale Arbeitszeit, NaN berücksichtigt
    arbeitszeit: {
      get() {
        let sum = 0;
        this.timesheet.zeitbloecke.forEach((zeitblock) => {
          if (!isNaN(zeitblock.stunden)) {
            sum += zeitblock.stunden;
          }
        });
        return round(sum, 2);
      },
    },
    nichtRapportierteStunden: function () {
      return round(this.arbeitszeit - this.timesheet.rapportiert, 2);
    },

    kunden: {
      get() {
        return this.$store.state.zeiterfassung.kunden;
      },
    },

    projekte: {
      get() {
        return this.$store.state.zeiterfassung.projekte;
      },
    },

    projektstati: {
      get() {
        return this.$store.state.zeiterfassung.projektstati;
      },
    },
  },
  created() {
    this.loading = true;
    this.fromRoute = this.$route.params.fromRoute;

    this.loadTimesheet();
  },
  mounted: function () {
    if (this.timesheetstati.length == 0) {
      this.$store.dispatch(`zeiterfassung/${LADE_AUSWAHLWERTE_ZEITERFASSUNG}`);
    }
  },
  methods: {
    loadTimesheet() {
      if (this.id) {
        Api.get("zeiterfassung/timesheet/", { params: { id: this.id } }).then(
          (response) => {
            this.initializeTimesheet(response.data);
            this.getZeitKonto();
            this.loading = false;
          }
        );
      } else {
        this.timesheet.mitarbeiter = this.angemeldeterMitarbeiter.person;
        this.timesheet.status = this.timesheetstati.find(
          (tss) => tss.appkey == "draft"
        );

        this.timesheet.datum = this.datum
          ? this.datum
          : new Intl.DateTimeFormat("ch").format(new Date());

        this.editable = true;
        this.loading = false;
      }
    },

    abbrechen() {
      this.$router.push({
        name: this.fromRoute || "overview",
        params: { currentMitarbeiter: this.timesheet.mitarbeiter },
      });
    },

    initializeTimesheet(timesheet) {
      if (timesheet) {
        this.timesheet = timesheet;

        if (timesheet.rapportiert === null) {
          this.rapportierteStunden = 0;
        } else {
          this.rapportierteStunden = timesheet.rapportiert;
        }

        this.editable = true;

        if (this.berechtigungen.m_timereporting.update == false)
          this.editable = false;

        this.initializeChangewatcher(["timesheet"]);
      }
    },

    hasTimeConflict(zeitBlock) {
      let conflict = false;

      this.timesheet.zeitbloecke.forEach((zb) => {
        if (zb.id != zeitBlock.id) {
          // Wenn Zeitblöcke nicht dieselben sind:
          if (
            zeitBlock.startzeit >= zb.startzeit && // Zeitblock startet zur selben Zeit, wo bereits ein Zeitblock existiert
            zeitBlock.startzeit <= zb.endzeit
          )
            conflict = true;

          if (
            zeitBlock.endzeit >= zb.startzeit && // Zeitblock endet zur selben Zeit, wo bereits ein Zeitblock existiert
            zeitBlock.endzeit <= zb.endzeit
          )
            conflict = true;
        }
      });

      return conflict;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      let conflict = false;

      this.timesheet.zeitbloecke.forEach((zeitblock) => {
        // Wenn Zeitzonen überlappen --> conflict = true
        if (!conflict && this.hasTimeConflict(zeitblock)) {
          /* this.$notify("Die Zeitangaben überlappen einander!"); */
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("Die Zeitangaben überlappen einander!"),
          });
          conflict = true;
        }
      }, this);

      // Wenn Zeitüberlappungen vorhanden, nicht laden und put nicht ausführen
      if (conflict) {
        this.loading = false;
        return;
      }

      var json = this.timesheet;

      if (!this.id) {
        Api.post("zeiterfassung/timesheet/", json)
          .then((response) => {
            if (response.data.errormsg) {
              this.$notify({
                type: "error",
                title: this.$t("global.actionunsuccessful"),
                text: response.data.errormsg,
              });
            } else {
              this.initializeTimesheet(response.data);
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: "Zeitblatt wurde erfolgreich gespeichert.",
              });

              this.$router.replace({
                name: "timesheet",
                params: { id: response.data.id },
              });
            }
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.updateTimesheet();
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "timesheet-loeschen-modal");
    },

    timesheetReset() {
      Api.delete("zeiterfassung/timesheet/", { data: [this.id] }).then(() => {
        this.$router.push({
          name: "overview",
        });
      });
    },

    setMitarbeiter(person) {
      this.timesheet.mitarbeiter = person;
      this.$bus.$emit("mitarbeiter-changed");
    },

    updateTimesheet() {
      // Timesheet-Update
      var json = this.timesheet;

      Api.put("zeiterfassung/timesheet/", json, { params: { id: this.id } })
        .then((response) => {
          if (response.data.errormsg) {
            this.$notify({
              type: "error",
              title: this.$t("global.actionunsuccessful"),
              text: response.data.errormsg,
            });
          } else {
            this.initializeTimesheet(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Zeitblatt wurde erfolgreich gespeichert.",
            });
          }
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
          this.loadTimesheet();
        });
    },

    // zählt die rapportierten Stunden aus Projekten oder Intern zusammen, wenn sich diese geändert haben
    updateProjektStunden(value) {
      this.projektStunden = value;
      this.timesheet.rapportiert = this.projektStunden + this.interneStunden;

      // damit vor dem initialisieren des Timesheets die isvalid Funktion geht (für mehrere Anpassungen nacheinander)
      this.rapportierteStunden = this.projektStunden + this.interneStunden;
    },
    updateInterneStunden(value) {
      this.interneStunden = value;
      this.timesheet.rapportiert = this.projektStunden + this.interneStunden;
      this.rapportierteStunden = this.projektStunden + this.interneStunden;
    },

    getZeitKonto() {
      let now = new Date();
      now =
        now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear();
      Api.get("zeiterfassung/account/", {
        params: {
          date: now,
          ma: this.timesheet.mitarbeiter.id,
        },
      }).then((response) => {
        this.zeitkonto = response.data[0];
      });
    },
    oeffneProjektErstelungsModal() {
      // emit event, das im Modalcomponent das Öffnen bewirkt
      this.$bus.$emit("neues-projekt-erfassen");
    },
    erfasseNeuesProjekt(json) {
      // POST, welcher einen neuen Eintrag mit den Daten aus dem Modal erstellt.
      if (json) {
        Api.post("zeiterfassung/projektuebersicht/", json)
          .then(() => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Projekt wurde erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      }
    },
  },
};
</script>

<style></style>
