<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="isAdmin || editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="showDozenteneinsatzAdministrieren"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-user-clock"
                      class="mr-2"
                    />
                    {{ $t("global.administerlecturerdeployment") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>

                  <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    @click="kursKopierenModal"
                  >
                    <font-awesome-icon icon="fa-duotone fa-gem" class="mr-2" />
                    Kurs von Vorlage
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_bildung.delete"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <generisch-stammdaten
            :v-if="kursid"
            :veranstaltung="kurs"
            :editable="editable"
            :isTemplate="false"
            :veranstaltungstypen="kurstypen"
          />

          <generisch-lektionen
            :v-if="kursid"
            :veranstaltung="kurs"
            :editable="editable"
            :isTemplate="false"
          />

          <generisch-teilnehmer
            :v-if="kursid"
            :veranstaltung="kurs"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <kurs-details :kurs="kurs" :editable="editable" :isTemplate="false" />
        </div>

        <!-- ENDE Rechte Seite -->
        <div v-if="kursid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'kurs',
                      params: { kursid: kursid, anzeige: 0 },
                    }"
                    >{{ $t("global.registrations") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'kurs',
                      params: { kursid: kursid, anzeige: 1 },
                    }"
                    >{{ $t("global.lecturerbooking") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'kurs',
                      params: { kursid: kursid, anzeige: 2 },
                    }"
                    >{{ $t("global.appointments") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'kurs',
                      params: { kursid: kursid, anzeige: 3 },
                    }"
                    >{{ $t("global.clearingitems") }}</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 4 }"
                    :to="{
                      name: 'kurs',
                      params: {
                        kursid: kursid,
                        anzeige: 4,
                      },
                    }"
                    >{{ $t("global.dashboard_presence") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <anmeldungsliste
                    :veranstaltung="kursid"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    :showUebertragen="true"
                    :key="anmeldungslisteKey"
                  />
                </div>

                <div v-show="anzeige === 1">
                  <dozentenbuchungsliste
                    :veranstaltung="kursid"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                  />
                </div>

                <div v-show="anzeige === 2">
                  <terminliste
                    :veranstaltung="kurs"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                    :anzahl_anmeldungen="kurs.anmeldungen"
                  />
                </div>

                <div v-show="anzeige === 3">
                  <verrechnungspositionen
                    :veranstaltung="kursid"
                    :editable="editable"
                    :shown="anzeige == 3 ? true : false"
                    @positionChanged="anmeldungslisteUpdate"
                    :isVeranstaltung="true"
                  />
                </div>
                <div v-show="anzeige === 4">
                  <anwesenheit
                    :shown="anzeige == 4 ? true : false"
                    :editable="editable"
                    :kurzveranstaltungid="kursid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <dozenteneinsatz-administrieren-modal
      v-if="kursid"
      :id="DozenteneinsatzAdministrierenModalId"
      :veranstaltung="kursid"
      :bildungsbezeichnung="kurs.bezeichnung"
      @dozentChanged="terminlisteKey++"
    ></dozenteneinsatz-administrieren-modal>

    <messagebox-enhanced
      :headerText="$t('global.startpromotion')"
      :ok="true"
      @ok="promotionStart"
    />

    <kurs-vorlage-modal @confirmed="kursVonVorlage" />

    <loeschen-modal id="kurs-loeschen-modal" @confirmed="kursloeschen" />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import { modal } from "@/mixins/Modal";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import GenerischStammdaten from "@/components/Bildung/Generisch/Stammdaten";
import GenerischLektionen from "@/components/Bildung/Generisch/Lektionen";
import GenerischTeilnehmer from "@/components/Bildung/Generisch/Teilnehmer";
import KursDetails from "@/components/Bildung/Kurs/KursDetails.vue";

import Anmeldungsliste from "@/components/Reiter/Anmeldungsliste";
import Dozentenbuchungsliste from "@/components/Reiter/Dozentenbuchungsliste";
import Verrechnungspositionen from "@/components/Reiter/Verrechnungspositionen.vue";
import Terminliste from "@/components/Reiter/Terminliste";
import Anwesenheit from "@/components/Reiter/Anwesenheit";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import DozenteneinsatzAdministrierenModal from "@/components/Modals/DozenteneinsatzAdministrierenModal";
import KursVorlageModal from "@/components/Modals/KursVorlageModal";

import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";

export default {
  name: "kurs",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    GenerischStammdaten,
    GenerischLektionen,
    GenerischTeilnehmer,
    Anmeldungsliste,
    Dozentenbuchungsliste,
    Terminliste,
    Verrechnungspositionen,
    KursDetails,
    MessageboxEnhanced,
    DozenteneinsatzAdministrierenModal,
    KursVorlageModal,
    UnsavedChangesModal,
    Anwesenheit
  },
  mixins: [page, ChangeWatcherNew, modal],

  props: {
    kursid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      anmeldungslisteKey: 0,
      terminlisteKey: 0,
      isAdmin: false,
      kurs: {
        lehrgang: {
          bezeichnung: this.$t("global.notlinked"),
          lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
        },
        verantwortlicher: { personName: this.$t("global.notlinked") },
        veranstaltungstyp: {
          bezeichnung: "Kurs",
          id: "174D91487E8",
        },
        verantwortlich_kurs: null,
        dozent: null,
        leitung: null,
      },
      DozenteneinsatzAdministrierenModalId:
        "dozenteneinsatz-administrieren-modal",
    };
  },
  computed: {
    navbarTitel() {
      return (
        this.$t("global.course") +
        (this.kurs.bezeichnung ? ": " + this.kurs.bezeichnung : "")
      );
    },
    kurstypen: function () {
      let kurstypen = [];
      kurstypen.push({ bezeichnung: "Kurs", id: "174D91487E8" }); // kurstypen.push(this.$CONST("VERANSTALTUNGSTYPEN").PRECOURSE);
      kurstypen.push({ bezeichnung: "Vorkurs", id: "174D91487E0" }); // kurstypen.push(this.$CONST("VERANSTALTUNGSTYPEN").COURSE);
      kurstypen.push({ bezeichnung: "Pruefung", id: "174D91487D6" }); // k
      kurstypen.push({ bezeichnung: "Weiterbildungskurs", id: "174D91487E4" }); // k
      return kurstypen;
    },
  },
  watch: {
    kursid: {
      handler: function (val) {
        if (val) {
          this.ladeVeranstaltung(val);
        } else {
          this.editable = this.berechtigungen.m_bildung.update;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.$bus.$on("setKurstyp", (kurstyp) => {
      this.kurs.veranstaltungstyp = kurstyp;
    });

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    ladeVeranstaltung(kursid) {
      this.loading = true;
      Api.get("veranstaltungen/", { params: { id: kursid } })
        .then((response) => {
          this.initializeKurs(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    kursKopierenModal() {
      this.$bus.$emit("open-modal", "kurs-vorlage-modal");
    },
    vorlageSetzen(vorlage) {
      this.initializeKurs(vorlage);
    },
    initializeKurs(kurs) {
      if (kurs) {
        this.kurs = kurs;

        if (!this.kurs.verantwortlicher)
          this.kurs.verantwortlicher = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.kurs.lehrgang)
          this.kurs.lehrgang = {
            bezeichnung: this.$t("global.notlinked"),
            lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
          };

        if (!this.kurs.lehrgang.lehrgangstatus)
          // Datenfail in der Datenbank Lehrgang ohne Status
          this.kurs.lehrgang.lehrgangstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };

        this.initializeChangewatcher(["kurs"]);

        this.editable = this.$CONST("EVENTEDITABLESTATI").includes(
          this.kurs.veranstaltungsstatus.id
        )
          ? true
          : false;
      } else {
        console.error("kurs ist null");
      }

      this.editable = !this.berechtigungen.m_bildung.update
        ? false
        : this.editable;
    },

    kursVonVorlage(kurs) {
      this.kurs.kuerzel = kurs.bezeichnungKuerzel;
      this.kurs.bezeichnung = kurs.bezeichnung;
      this.kurs.veranstaltungsstatus = kurs.veranstaltungsstatus;
      this.kurs.lehrmethode = kurs.lehrmethode;

      this.initializeKurs(kurs);
    },

    promotion() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        text: this.$t("global.shouldthepromotionbestarted"),
      });
    },

    promotionStart() {
      Api.put(
        "noten/promotion",
        {},
        {
          params: { kurs: this.kurs.id },
        }
      ).then(() => {});
    },

    abbrechen() {
      this.$router.push({ name: "kurs-liste" });
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.kurs);
      if (this.kurs.qualifikationen)
        json.qualifikationen = this.kurs.qualifikationen.map((qa) => {
          return qa.id;
        });

      if (this.kurs.themen)
        json.themen = this.kurs.themen.map((t) => {
          return t.id;
        });

      json.lehrgang = this.kurs.lehrgang?.id;
      json.veranstaltungsstatus = this.kurs.veranstaltungsstatus?.id;
      json.verantwortlicher = this.kurs.verantwortlicher?.id;
      json.periode = this.kurs.periode?.id;

      if (!this.kursid) {
        Api.post("veranstaltungen/", json)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.coursesuccessfullysaved"),
            });
            this.$router.replace({
              name: "kurs",
              params: { kursid: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("veranstaltungen/", json, { params: { id: this.kurs.id } })
          .then((response) => {
            this.initializeKurs(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.coursesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "kurs-loeschen-modal");
    },
    anmeldungslisteUpdate() {
      this.anmeldungslisteKey++;
    },
    kursloeschen() {
      let selectedId = [this.kursid];
      Api.delete("veranstaltungen/", {
        params: { ids: selectedId.join(",") },
      }).then(() => {
        this.$router.replace({ name: "kurs-liste" });
      });
    },
    showDozenteneinsatzAdministrieren() {
      $("#" + this.DozenteneinsatzAdministrierenModalId).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.$bus.$emit("openDozenteneinsatzAdministrierenModal", null);
    },
  },
};
</script>

<style></style>
