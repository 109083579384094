<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter :col="12" class="d-xl-flex" :headline="$t('global.filter')"></head-menu-filter>

            <div class="row mb-2">
              <div class="block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10">
                <div class="row pt-4 pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <v-select v-model.trim="filterEingabeTyp" :options="noteneingabetypen" :reduce="(net) => net.id"
                                  label="bezeichnung" :placeholder="$t('global.scoreinputtype')">
                          <span slot="no-options">{{
                              $t("global.nostatusfound")
                            }}</span>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input v-model="filterBezeichnung" class="form-control input" type="text" placeholder=" "/>
                        <label>{{ $t("global.designation") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu :col="12" class="d-xl-flex" headline="Einstellung: Fächer"></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <button class="btn btn-success" @click="oeffneBearbeitenModal">
                      <font-awesome-icon icon="fa-regular fa-plus"/>
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon icon="fa-duotone fa-trash" class="mr-2"/>
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table ref="selectableTable"
                           small
                           tbody-tr-class="item"
                           :items="faecher"
                           :fields="fields"
                           :sort-by.sync="sortBy"
                           :sort-desc.sync="sortDesc"
                           sort-icon-left fixed selectable
                           select-mode="range"
                           @row-dblclicked="oeffneBearbeitenModal">
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox v-model="row.selected" @input="selectAllRows(row)"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox v-model="row.item.selected" @input="onRowSelected(row)"></b-form-checkbox>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
      <div slot="spinner">Lade weitere Fächer...</div>
      <div slot="no-more">Keine weiteren Fächer</div>
      <div slot="no-results">Keine weiteren ArtikFächerel gefunden</div>
    </infinite-loading>

    <div id="modal-bearbeiten" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeBearbeitenModal"/>
            <h4>Fach bearbeiten</h4>
          </div>
          <div class="modal-body">
            <div class="row p-0">
              <div class="col-xl-12">
                <div class="form-group">
                  <input v-model="editFach.kuerzel" class="form-control" placeholder=" "/>
                  <label>{{ $t("global.shortdesignation") }}</label>
                </div>
              </div>
              <div class="col-xl-12 mt-2">
                <div class="form-group">
                  <input v-model="editFach.bezeichnung" class="form-control" placeholder=" "/>
                  <label>{{ $t("global.designation") }}</label>
                </div>
              </div>
              <div class="col-xl-12 mt-2">
                <div class="form-group">
                  <v-select v-model="editFach.noteneingabetyp" label="bezeichnung" :options="noteneingabetypen"
                            placeholder="Noteneingabe Typ">
                    <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="closeBearbeitenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="fachSpeichern">
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal @confirmed="fachloeschen" :multiple="true"/>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";

import Navbar from "@/components/Navbar";
import {debounce} from "@/utils/Debouncer";
import InfiniteLoading from "vue-infinite-loading";

import store from "@/store";
import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import {apiErrorToAlert} from "@/utils/Errorhandler";

import {LADE_AUSWAHLWERTE_NOTEN} from "@/store/noten/actions.type";

export default {
  name: "Bildungsliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    ModalCloseButton,
    InfiniteLoading,
  },
  metaInfo() {
  },
  mixins: [page],
  props: {},
  store,
  data() {
    return {
      page: 0,
      anzahlProPage: 50,

      filterEingabeTyp: null,
      filterBezeichnung: null,

      editFach: {},
      faecher: [],

      sortBy: "bezeichnung",
      sortDesc: true,
      selected: [],
      selectedIds: [],

      infiniteId: +new Date(),
    };
  },
  computed: {
    fields() {
      return [
        {key: "selected", label: ""},
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "noteneingabetyp.bezeichnung",
          sortable: true,
          label: "Noteneingabe Typ",
        },
        {
          key: "textwahl.bezeichnung",
          sortable: true,
          label: "Wert",
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
        eingabetyp: this.filterEingabeTyp,
        bezeichnung: this.filterBezeichnung,
      };

      return params;
    },
    noteneingabetypen: {
      get() {
        return this.$store.state.noten.noteneingabetypen;
      },
    },
  },
  watch: {
    filterEingabeTyp: function () {
      this.resetLoading(this);
    },
    filterBezeichnung: function () {
      this.resetLoading(this);
    },
    noteneingabetypen: function () {
      if (this.noteneingabetypen.length > 0 && this.filterEingabeTyp == null) {
        this.filterEingabeTyp = this.noteneingabetypen[0].id;
      }
    },
  },
  mounted: function () {
    if (this.noteneingabetypen.length == 0)
      this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);
  },
  methods: {
    fachSpeichern() {
      var json = this.fachPeriode;
      json.noteneingabetyp = this.editFach.noteneingabe_typ.id;

      if (this.fachPeriode.id) {
        Api.put("noten/fach/", json, {
          params: {id: this.editFach.id},
        })
          .then(() => {
            this.resetLoading();

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Fach erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      } else {
        Api.post("noten/fach/", json)
          .then(() => {
            this.resetLoading();

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Fach erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      }
      this.closeBearbeitenModal();
    },

    oeffneBearbeitenModal(periode) {
      if (periode) {
        this.editFach = Object.assign({}, periode);

        $("#modal-bearbeiten").modal({
          backdrop: "static",
          keyboard: false,
        });
      }
    },

    leereBearbeitenModal() {
      this.editFach = {};
    },

    closeBearbeitenModal() {
      this.leereBearbeitenModal();
      $("#modal-bearbeiten").modal("hide");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) this.$bus.$emit("openLoeschenModal");
    },

    fachloeschen() {
      Api.delete("noten/fach/", {
        params: {
          ids: this.selectedIds.join(","),
        },
      }).then(() => {
        this.resetLoading();
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    infiniteHandler($state) {
      Api.get("noten/faecher/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.faecher.push(...response.data);
          $state.loaded();
        } else {
          this.faecher.push(...response.data);
          $state.complete();
        }
      });
    },

    resetLoading: debounce((self) => {
      self.page = 0;
      self.faecher = [];
      self.infiniteId += 1;
    }, 500),
  },
};
</script>

<style></style>
