export const SET_BILDUNGS_KATEGORIEN = "setBildungsKategorien";
export const SET_LEHRGANG_STATI = "setLehrgangStati";
export const SET_BILDUNGSGANG_TYPEN = "setBildungsgangTypen";
export const SET_PERIODEN = "setPerioden";
export const SET_VERANSTALTUNGS_STATI = "setVeranstaltungsStati";
export const SET_VERANSTALTUNGS_TYPEN = "setVeranstaltungsTypen";
export const SET_THEMEN = "setThemen";
export const SET_QUALIFIKATIONEN = "setQualifikationen";
export const SET_LEHRMETHODEN = "setLehrMethoden";
export const SET_NIVEAUS = "setNiveaus";
export const SET_FAECHER = "setFaecher";
export const SET_SEMESTER_TYPEN = "setSemesterTypen";
export const SET_NOTENTYPEN = "setNotenTypen";
export const SET_MODULEINSAETZE = "setModulEinsaetze";
export const SET_MODULGRUPPEN = "setModulGruppen";
export const SET_MODULTYPEN = "setModulTypen";
export const SET_KURSVORLAGEN = "setKursvorlagen";
export const SET_ZIELGRUPPEN = "setZielgruppen";
export const SET_SPRACHEN = "setSprachen";
export const SET_GEBAEUDE = "setGebaeude";
export const SET_MAILVORLAGEN = "setMailvorlagen";
export const SET_NACHWEISE = "setNachweise";
export const SET_PRUEFUNGSMODI = "setPruefungsModi";
export const SET_LEHRGANGSGRUPPEN = "setLehrgangsGruppen";
export const SET_LEHRGANGSKATEGORIEN = "setLehrgangsKategorien";
export const SET_INSTITUTIONEN = "setInstitutionen";
export const SET_REGELSAETZE = "setRegelsaetze";
export const SET_KURZVERANSTALTUNGSTYPEN = "setKurzveranstaltungstypen";
export const SET_VERANSTALTUNGSGRUPPEN = "setVeranstaltungsgruppen";
export const SET_VERANSTALTUNGSKATEGORIEN = "setVeranstaltungskategorien";
export const SET_STATICPAGES = "setStaticPages";
