<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>

                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid || !portalinfoValid"
                    @click="speichern(invalid)"
                    @shortkey="speichern(invalid)"
                    v-tooltip
                    :title="
                      invalid
                        ? $t('global.coursedatanotvalid')
                        : !portalinfoValid
                        ? $t('global.portalinfonotvalid')
                        : ''
                    "
                  >
                    {{ $t("global.save") }}
                  </button>

                  <b-dropdown v-if="editable" variant="primary mr-2">
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-duotone fa-copy"
                        class="mr-2"
                      />
                      {{ $t("global.Copy") }}
                    </template>
                    <b-dropdown-item
                      @click="semesterKopierenModal"
                      :disabled="invalid"
                    >
                      {{ $t("global.semesterfromtemplate") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="bgKopierenModal"
                      :disabled="invalid"
                    >
                      {{ $t("global.thiseducationcourse") }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    @click="semesterKopierenModal"
                    :disabled="invalid"
                  >
                    <font-awesome-icon icon="fa-duotone fa-gem" class="mr-2" />
                    {{ $t("global.semesterfromtemplate") }}
                  </button> -->

                  <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    @click="endnotenBerechnen"
                    :disabled="invalid"
                  >
                    {{ $t("global.calculatefinalgrades") }}
                  </button>

                  <span v-if="baseURL === 'https://tsp.bx-education.ch'">
                    <button
                      class="btn btn-primary mr-2"
                      @click="showNotenausweisPdfModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      <em>{{ $t("global.printNotenblatt") }}</em>
                    </button>
                  </span>
                  <span v-else>
                    <button
                      v-if="editable && lehrgang.startperiode == 4"
                      class="btn btn-primary mr-2"
                      @click="showNotenausweisPdfModal"
                      :disabled="invalid"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      <em>{{ $t("global.printperformancerecords") }}</em>
                    </button>

                    <button
                      v-if="editable && lehrgang.startperiode != 4"
                      class="btn btn-primary mr-2"
                      @click="showNotenausweisPdfModal"
                      :disabled="invalid"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      <em>{{ $t("global.printprediplomacertificates") }}</em>
                    </button>
                  </span>

                  <b-dropdown variant="primary mr-2">
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      {{ $t("global.printcourseparticipantslist") }}
                    </template>
                    <b-dropdown-item
                      @click="
                        openSpracheWaehlenModal(
                          'teilnehmerliste-ohne-foto-modal'
                        )
                      "
                    >
                      {{ $t("global.courseparticipantslistnopic") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        openSpracheWaehlenModal(
                          'teilnehmerliste-mit-foto-modal'
                        )
                      "
                    >
                      {{ $t("global.courseparticipantslistwithpic") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        openSpracheWaehlenModal(
                          'teilnehmerliste-mit-foto-ohne-kontakt-modal'
                        )
                      "
                    >
                      {{
                        $t("global.courseparticipantslistwithpicwithoutcontact")
                      }}
                    </b-dropdown-item>
                  </b-dropdown>

                  <!-- <button
					  class="btn btn-primary mr-2"
					  @click="Endnotenuebersicht"
					>
					  {{ $t("global.finalgradeoverview") }}
					</button> -->

                  <b-dropdown variant="primary mr-2">
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-duotone fa-file-certificate"
                        class="mr-2"
                      />
                      {{ $t("global.pdfreports") }}
                    </template>
                    <b-dropdown-item @click="openConfigGradesOverview">
                      {{ $t("global.cofiguregradesoverview") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="Endnotenuebersicht">
                      {{ $t("global.finalgradeoverview") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="lehrgangsuebergreifendeUebersicht">
                      {{ $t("global.crosscurriculargradeoverview") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="anwesenheitsUebersicht">
                      {{ $t("global.attendanceoverview") }}
                    </b-dropdown-item>
                  </b-dropdown>

                  <!-- Auskommentiert für Februar-Release
					  <button
					  class="btn btn-primary mr-2"
					  v-if="baseURL === 'https://scala.bx-education.ch'"
					  @click="schuelerausweisAusgeben"
					>
					  Schuelerausweis
					</button> -->

                  <button
                    class="btn btn-primary mr-2"
                    @click="kompetenzRaster"
                    v-if="lehrgang && lehrgang.kompetenzraster"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-ballot-check"
                      class="mr-2"
                    />{{ $t("global.competencegrid") }}
                  </button>

                  <!-- Notenuebersicht
					<router-link
					  class="btn btn-primary mr-2"
					  :to="{
						name: 'Notenuebersicht',
						params: {
						  id: id,
						  anzeige: 0,
						},
					  }"
					>
					  <font-awesome-icon icon="fa-regular fa-plus" />
					  Neue Notenübersicht
					</router-link>
					 -->
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.b_bildung_bildungsgang.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <bildungsgang-stammdaten
            :lehrgang="lehrgang"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <div class="col-xl-12 p-0" :class="{ 'col-xl-12': !showNavbar }">
          <vorlage-promotionsstruktur
            :isBusy="isBusy"
            :ladeFehler="ladeFehler"
            :klassen="klassen"
            :lehrgang="lehrgang"
            :notentypen="notentypen"
            @psChanged="setPromotionsstruktur"
            @enChanged="setEndnotenstruktur"
          />
        </div>

        <div v-if="lehrgangid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 0 },
                    }"
                    >{{ $t("global.classes") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 1 },
                    }"
                    >{{ $t("global.portalinformation") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 2 },
                    }"
                    >{{ $t("global.clearingitems") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 3 },
                    }"
                    >{{ $t("global.registrationform") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 4 }"
                    :to="{
                      name: 'bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 4 },
                    }"
                    >{{ $t("global.survey") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 5 }"
                    :to="{
                      name: 'bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 5 },
                    }"
                    >{{ $t("global.documentoverview") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <!-- Start Zuordnung -->
                <div v-show="anzeige === 0">
                  <klassenliste
                    :lehrgang="lehrgang"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    @ladeKlassen="ladeKlassen"
                    @klasseLoaded="setKlassen"
                  />
                </div>
                <!-- Ende Zuordnung -->

                <!-- Start Portalinfos -->
                <div v-show="anzeige === 1 && lehrgang.portalanzeige">
                  <portalinformationen
                    :lehrgang="lehrgang"
                    :portalinfoId="portalinfoId"
                    :isKurzveranstaltung="false"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                    @portalinfoChanged="portalinfoChanged"
                    @portalinfoValidChanged="portalinfoValidChanged"
                    @portalinfoInitialized="handlePortalinfoInitialized"
                  />
                </div>
                <!-- Ende Portalinfos -->

                <!-- Start Verrechnungspositionen -->
                <div v-show="anzeige === 2 && lehrgang.portalanzeige">
                  <bildungsgang-verrechnungspositionen
                    :lehrgang="lehrgang"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                  />
                </div>
                <!-- Ende Verrechnungspositionen -->

                <!-- Start Anmeldeformular -->
                <div v-show="anzeige === 3 && lehrgang.portalanzeige">
                  <anmeldeformular
                    :lehrgang="lehrgang"
                    :shown="anzeige == 3 ? true : false"
                    :editable="editable"
                    :mailvorlagen="mailvorlagen"
                  />
                </div>
                <!-- Ende Anmeldeformular -->

                <!-- Start Umfrage -->
                <div
                  v-if="anzeige === 4 && lehrgang.portalanzeige && lehrgang.id"
                >
                  <umfrage-reiter
                    :lehrgang="lehrgang"
                    :shown="anzeige == 4 ? true : false"
                    :editable="editable"
                  />
                </div>
                <!-- Ende Umfrage -->
                <!-- Start Dokumentenuebersicht -->
                <div
                  v-if="anzeige === 5 && lehrgang.portalanzeige && lehrgang.id"
                >
                  <dokumentenuebersicht
                    id="dokumentenübersicht-lehrgang"
                    :lehrgangId="lehrgang.id"
                    :shown="anzeige == 5 ? true : false"
                    :editable="editable"
                  />
                </div>
                <!-- Ende Dokumentenuebersicht -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="bildungsgang-loeschen-modal"
      @confirmed="bildungsgangloeschen"
    />

    <Kompetenzraster-modal />

    <message-box
      id="semester-kopieren-modal"
      :headerText="$t('global.copysemester')"
      :text="$t('global.doyoucopyallorjustnextsemester')"
      :ok="true"
      :cancel="true"
      :repeat="true"
      :okText="$t('global.thenext')"
      :repeatText="$t('global.allavailable')"
      :cancelText="$t('global.cancel')"
      @ok="createKlasseNext"
      @repeat="createKlasseAll"
    />

    <message-box
      id="bildungsgang-kopieren-modal"
      :headerText="$t('global.copyeducationcourse')"
      :text="$t('global.doyouwanttocopyalloronlythefirstsemester')"
      :ok="true"
      :cancel="true"
      :repeat="true"
      :okText="$t('global.semesterone')"
      :repeatText="$t('global.allsemesters')"
      :cancelText="$t('global.cancel')"
      @ok="createBildungsgang(false)"
      @repeat="createBildungsgang(true)"
    />

    <message-box
      id="bildungsgangtyp-ändern-modal"
      :headerText="$t('global.changecoursetype')"
      :text="$t('global.changecoursetypewarningtext')"
      :ok="true"
      :cancel="true"
      :okText="$t('global.apply')"
      :cancelText="$t('global.cancel')"
      @ok="handleOk"
      @cancel="lehrgang.bildungsgangtyp = bildungsgangtypAlt"
    />

    <span v-if="baseURL === 'https://tsp.bx-education.ch'">
      <notenausweis-pdf-modal
        id="notenausweis-pdf-modal"
        :notenblattAusgeben="notenblattAusgeben"
        :showNotenblatt="true"
        :date="date"
        :lehrgangid="lehrgangid"
        @dateChanged="updateDate"
      >
      </notenausweis-pdf-modal>
    </span>
    <span v-else>
      <notenausweis-pdf-modal
        id="notenausweis-pdf-modal"
        :leistungsausweisAusgeben="leistungsausweisAusgeben"
        :vordiplomausweisAusgeben="vordiplomausweisAusgeben"
        :date="date"
        :lehrgangid="lehrgangid"
        :showLeistungsausweis="lehrgang.startperiode == 4"
        :showVordiplomausweis="lehrgang.startperiode != 4"
        @dateChanged="updateDate"
      >
      </notenausweis-pdf-modal>
    </span>
    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>
    <sprache-waehlen-modal
      :id="'teilnehmerliste-ohne-foto-modal'"
      :headerText="$t('global.configureparticipantlist')"
      :okText="$t('global.viewpdf')"
      :selectDate="false"
      :languages="['DE', 'FR']"
      @ok="openParticipantsListNoPic"
    />
    <sprache-waehlen-modal
      :id="'teilnehmerliste-mit-foto-modal'"
      :headerText="$t('global.configureparticipantlist')"
      :okText="$t('global.viewpdf')"
      :selectDate="false"
      :languages="['DE', 'FR']"
      @ok="openParticipantsListWithPic"
    />
    <sprache-waehlen-modal
      :id="'teilnehmerliste-mit-foto-ohne-kontakt-modal'"
      :headerText="$t('global.configureparticipantlist')"
      :okText="$t('global.viewpdf')"
      :selectDate="false"
      :languages="['DE', 'FR']"
      @ok="openParticipantsListWithPicWithoutContact"
    />
    <konfig-notenuebersicht-modal
      :id="'konfig-notenuebersicht-modal'"
      :lehrgangId="lehrgangid"
      :editable="true"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Api from "@/Api";
import App from "@/AppApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import server from "@/server";

import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

import BildungsgangStammdaten from "@/components/Bildung/Bildungsgang/Stammdaten";
import VorlagePromotionsstruktur from "@/components/Bildung/Bildungsgang/VorlagePromotionsstruktur";
import Klassenliste from "@/components/Reiter/Klassenliste";
import Portalinformationen from "@/components/Reiter/Portalinformationen";
import UmfrageReiter from "@/components/Reiter/Questionaireliste";
import BildungsgangVerrechnungspositionen from "@/components/Reiter/BildungsgangVerrechnungspositionen";
import Anmeldeformular from "@/components/Reiter/Anmeldeformular";
import Dokumentenuebersicht from "@/components/Reiter/Dokumentenuebersicht";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageBox from "@/components/Modals/Messagebox";
import NotenausweisPdfModal from "@/components/Modals/NotenausweisPDFModal";
import KompetenzrasterModal from "@/components/Bildung/Kompetenzraster/KompetenzrasterModal.vue";
import SpracheWaehlenModal from "@/components/Modals/SpracheWaehlenModal.vue";
import { BIconThreeDotsVertical } from "bootstrap-vue";
import KonfigNotenuebersichtModal from "@/components/Modals/KonfigNotenuebersichtModal.vue";

export default {
  name: "Bildungsgang",
  components: {
    Klassenliste,
    Portalinformationen,
    BildungsgangVerrechnungspositionen,
    Anmeldeformular,
    HeadMenu,
    Navbar,
    BildungsgangStammdaten,
    LoeschenModal,
    MessageBox,
    VorlagePromotionsstruktur,
    NotenausweisPdfModal,
    KompetenzrasterModal,
    SpracheWaehlenModal,
    UmfrageReiter,
    UnsavedChangesModal,
    Dokumentenuebersicht,
    KonfigNotenuebersichtModal,
  },

  mixins: [page, ChangeWatcherNew],
  props: {
    lehrgangid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    /*
	  notenuebersichtid: {
		type: String,
		default: "7",
	  },*/
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      fromRoute: null,
      lehrgang: {
        lehrgangstatus: null,
        lehrgangleiter: { personName: this.$t("global.notlinked") },
        kontakt: { personName: this.$t("global.notlinked") },
        verantwortliche: [{ personName: this.$t("global.notlinked") }],
        verantwortliche2: [{ personName: this.$t("global.notlinked") }],
        templatelehrgang: { bezeichnung: this.$t("global.notlinked") },
        portalinfo: { id: null },
        portalanzeige: true,

        anmeldeformularMailvorlage: null,
        //sprache: { id: null, bezeichnung: null, tblName: null },
        sprache: [],
        vorbereitung: "",
      },
      portalinfoValid: true,
      BildungsgangStartenModalId: "modal-bildungsgang-starten",
      klassen: [],
      date: new Intl.DateTimeFormat("ch-DE").format(new Date()),
      leistungsausweis: [],
      vordiplomausweis: [],
      pdfPath: "",
      baseURL: this.$CONST("CONFIG").BASEURL,
      bildungsgangtypAlt: null,
    };
  },
  computed: {
    navbarTitel() {
      if (this.lehrgang.id)
        return (
          this.$t("global.educationcourse") +
          " #" +
          this.lehrgang.lehrgangnummer +
          (this.lehrgang.bezeichnung ? " - " + this.lehrgang.bezeichnung : "")
        );
      else return "Neuer Bildungsgang";
    },
    notentypen: {
      get() {
        return this.$store.state.veranstaltung.notentypen;
      },
    },
    mailvorlagen: {
      get() {
        return this.$store.state.veranstaltung.mailvorlagen;
      },
    },
    sprachen: {
      get() {
        return this.$store.state.veranstaltung.sprachen;
      },
    },
    portalinfoId() {
      if (this.lehrgang.portalinfo?.id) return this.lehrgang.portalinfo?.id;
      return null;
    },
  },
  watch: {
    ["lehrgang.bildungsgangtyp"]: {
      deep: true,
      handler(neu, alt) {
        if (
          !this.lehrgang.anmeldeformular ||
          !alt ||
          this.bildungsgangtypAlt?.id === neu.id ||
          alt.id === neu.id
        )
          return;

        this.$bus.$emit("open-modal", "bildungsgangtyp-ändern-modal");
        this.bildungsgangtypAlt = alt;
      },
    },
    sprachen: {
      deep: true,
      handler(val) {
        if (val) {
          if (this.lehrgang.sprache.length === 0) this.setLanguage();
        }
      },
    },
  },
  created() {
    this.fromRoute = this.$route.params.fromRoute;

    if (this.lehrgangid) {
      this.loading = true;

      Api.get("lehrgaenge/", { params: { id: this.lehrgangid } })
        .then((response) => {
          this.initializeBildungsgang(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.editable = true;
      this.setLanguage();
    }
  },
  mounted: function () {},
  methods: {
    showNotenausweisPdfModal() {
      this.$bus.$emit("open-modal", "notenausweis-pdf-modal");
    },

    portalinfoChanged() {
      // this.hasChanged = true;
      this.$set(this.hasChanges, "portalinformationen", true);
    },

    handlePortalinfoInitialized() {
      this.$set(this.hasChanges, "portalinformationen", false);
    },

    setLanguage() {
      const defaultSprache = this.$CONST("CONFIG").DEFAULTLANGUAGE;

      let defaultSprachenObj = this.sprachen.find(
        (sprache) => sprache.id === defaultSprache
      );
      //this.$set(this.lehrgang, "sprache", defaultSprachenObj);
      this.lehrgang.sprache.push(defaultSprachenObj);
    },

    leistungsausweisAusgeben() {
      Api.get("notenberechnung/endnoten/", {
        params: { lehrgangid: this.lehrgangid },
      })
        .then((response) => {
          this.leistungsausweis = response.data;
          if (this.leistungsausweis?.length > 0) {
            let filename = new Intl.DateTimeFormat("ch").format(new Date());
            this.pdfPath =
              server.url +
              `/pdfgenerator/pdf.act?filename=Leistungsausweis${filename}.pdf&report=/pdfgenerator/leistungsausweis.htm&ids=${this.lehrgangid}&date=${this.date}`;
            this.$refs.pdfLayer.show();
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t(
                "global.nodataavailableaddgradetoclassassigningradetoolcalculate"
              ),
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t(
              "global.nodataavailableaddgradetoclassassigningradetoolcalculate"
            ),
          });
        });
    },
    vordiplomausweisAusgeben() {
      Api.get("notenberechnung/endnoten/", {
        params: { lehrgangid: this.lehrgangid },
      })
        .then((response) => {
          this.vordiplomausweis = response.data;
          if (this.vordiplomausweis?.length > 0) {
            let filename = new Intl.DateTimeFormat("ch").format(new Date());
            this.pdfPath =
              server.url +
              `/pdfgenerator/pdf.act?filename=Vordiplomausweis${filename}.pdf&report=/pdfgenerator/vordiplomausweis.htm&ids=${this.lehrgangid}&date=${this.date}`;
            this.$refs.pdfLayer.show();
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t(
                "global.nodataavailableaddgradetoclassassigningradetoolcalculate"
              ),
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t(
              "global.nodataavailableaddgradetoclassassigningradetoolcalculate"
            ),
          });
        });
    },
    notenblattAusgeben() {
      let filename = new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Notenblatt${filename}.pdf&report=/pdfgenerator/notenblatt.htm&ids=${this.lehrgangid}&date=${this.date}`;
      this.$refs.pdfLayer.show();
    },
    /*   schuelerausweisAusgeben() {
		let filename = new Intl.DateTimeFormat("ch").format(new Date());
		this.pdfPath =
		  server.url +
		  `/pdfgenerator/pdf.act?filename=Schuelerausweis${filename}.pdf&report=/pdfgenerator/schuelerausweis.htm&ids=${this.lehrgangid}&date=${this.date}`;
		this.$refs.pdfLayer.show();
	  }, */
    updateDate(selectedDate) {
      this.date = selectedDate;
    },
    abbrechen() {
      this.$router.push({
        name: this.fromRoute || "bildungsgang-liste",
      });
    },

    initializeBildungsgang(lehrgang) {
      if (lehrgang) {
        this.lehrgang = lehrgang;

        if (this.lehrgang.verantwortliche.length === 0) {
          this.lehrgang.verantwortliche.push({
            personName: this.$t("global.notlinked"),
          });
        }
        if (this.lehrgang.verantwortliche2.length === 0) {
          this.lehrgang.verantwortliche2.push({
            personName: this.$t("global.notlinked"),
          });
        }

        if (!this.lehrgang.lehrgangleiter)
          this.lehrgang.lehrgangleiter = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.lehrgang.lehrgangstatus)
          this.lehrgang.lehrgangstatus = {
            personName: this.$t("global.notlinked"),
          };

        if (this.lehrgang.sprache.length === 0) {
          this.setLanguage();
        }

        this.editable =
          this.lehrgang.lehrgangstatus.id ==
          this.$CONST("LEHRGANGSTATI").PLANNED
            ? true
            : true;

        // TODO: diesen Block entfernen, sobald Backend diesen Flag mitsendet
        if (lehrgang.portalanzeige) {
          this.lehrgang.portalanzeige = lehrgang.portalanzeige;
        } else {
          this.lehrgang.portalanzeige = true;
        }
      } else this.editable = true;

      this.editable = this.berechtigungen.b_bildung_bildungsgang.update;
      // Initialize the ChangeWatcher with the names of objects to watch
      this.initializeChangewatcher(["lehrgang"]);
    },
    setPromotionsstruktur(val) {
      this.lehrgang.promotionsstruktur = val;
    },

    setEndnotenstruktur(val) {
      this.lehrgang.endnotenstruktur = val;
      this.initializeChangewatcher(["lehrgang"]);
    },

    ladeKlassen(ladeFehler) {
      if (ladeFehler) {
        this.ladeFehler = true;
      } else {
        this.isBusy = true;
      }
    },

    setKlassen(val) {
      this.klassen = val;
      this.isBusy = false;
    },

    speichern(invalid) {
      if (this.loading) return;
      if (!this.checkAnmeldeformular()) return;

      if (invalid) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.coursedatanotvalid"),
        });
        return;
      }

      this.loading = true;
      if (this.lehrgang.teilnehmer_max === "") {
        this.lehrgang.teilnehmer_max = null;
      }

      var json = Object.assign({}, this.lehrgang);

      // Wenn Postalinfos vorhanden, diese mitspeichern
      if (this.lehrgang.portalanzeige && this.portalinfoValid) {
        this.$bus.$emit("savePortalinfo");
      } else if (this.lehrgang.portalanzeige && !this.portalinfoValid) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.portalinfonotvalid"),
        });
      }

      // Relationen auf ID reduzieren

      json.lehrgangstatus = this.lehrgang.lehrgangstatus
        ? this.lehrgang.lehrgangstatus.id
        : this.$CONST("LEHRGANGSTATI").PLANNED;

      json.lehrgangleiter = this.lehrgang.lehrgangleiter.id;

      // Defaultwerte

      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").STUDIES;

      if (!this.lehrgangid) {
        Api.post("lehrgaenge/", json)
          .then((response) => {
            this.initializeBildungsgang(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.educationcoursesuccessfullysaved"),
            });

            this.$router.replace({
              name: "bildungsgang",
              params: { lehrgangid: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("lehrgaenge/", json, { params: { id: this.lehrgang.id } })
          .then((response) => {
            this.initializeBildungsgang(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.educationcoursesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "bildungsgang-loeschen-modal");
    },

    bildungsgangloeschen() {
      let json = [this.lehrgangid];

      //this.disableChangeWatcher();
      Api.delete("lehrgaenge/", { data: json }).then(() => {
        this.$router.push({ name: "bildungsgang-liste" });
      });
    },

    semesterKopierenModal() {
      this.$bus.$emit("open-modal", "semester-kopieren-modal");
    },

    createKlasseNext() {
      console.log("Methode aufgerufen", this.lehrgangid);
      if (!this.lehrgangid) {
        this.$notify({
          type: "warning",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.educationcoursenotsaved"),
        });
      } else {
        Api.post(
          "template/klassevontemplate/?id=" + this.lehrgangid + "&all=false"
        ).then(() => {
          this.$bus.$emit("update-klassen-liste");
        });
      }
    },
    createKlasseAll() {
      if (!this.lehrgangid) {
        this.$notify({
          type: "warning",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.educationcoursenotyetsaved"),
        });
      } else {
        Api.post(
          "template/klassevontemplate/?id=" + this.lehrgangid + "&all=true"
        ).then(() => {
          this.$bus.$emit("update-klassen-liste");
        });
      }
    },

    bgKopierenModal() {
      this.$bus.$emit("open-modal", "bildungsgang-kopieren-modal");
    },

    createBildungsgang(allclasses) {
      if (!this.lehrgangid) {
        this.$notify({
          type: "warning",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noeducationcourseassigned"),
        });
      } else {
        this.isBusy = true;
        var json = Object.assign({}, this.lehrgang);
        json.klassen = this.klassen;

        Api.post(
          `lehrgaenge/vonLehrgang/?id=${this.lehrgangid}&all=${allclasses}`,
          json
        )
          .then(() => {
            this.$router.push({ name: "Bildungsgangliste" });
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    // endnotenBerechnen() {
    //   if (this.loading) return;

    //   this.loading = true;

    //   Api.post(
    //     "notenberechnung/endnoten/",
    //     {},
    //     {
    //       params: { lehrgangid: this.lehrgangid },
    //     }
    //   )
    //     .then((response) => {
    //       console.log(response);

    //       this.$notify({
    //         type: "success",
    //         title: this.$t("global.actionsuccessful"),
    //         text: this.$t("global.gradecalculationperformed"),
    //       });
    //     })
    //     .catch((e) => {
    //       this.$notify(apiErrorToAlert(e));
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },

    endnotenBerechnen() {
      if (this.loading) return;

      this.loading = true;

      App.post(
        "lehrgang/endnotenberechnung/",
        {},
        {
          params: { id: this.lehrgangid },
        }
      )
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.gradecalculationperformed"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    Endnotenuebersicht() {
      this.$router.push({
        name: "Endnotenuebersicht",
        params: {
          lehrgangid: this.lehrgangid,
        },
      });
    },

    lehrgangsuebergreifendeUebersicht() {
      this.$router.push({
        name: "LehrgangsuebergreifendeNotenuebersicht",
        params: {
          lehrgangid: this.lehrgangid,
        },
      });
    },
    anwesenheitsUebersicht() {
      this.$router.push({
        name: "Anwesenheitsuebersicht",
        params: {
          lehrgangid: this.lehrgangid,
          bildungsgangtyp: this.lehrgang?.bildungsgangtyp?.id,
        },
      });
    },

    kompetenzRaster() {
      this.$bus.$emit(
        "kompetenzraster-modal-show",
        this.lehrgang.kompetenzraster.id
      );
    },
    openSpracheWaehlenModal(id) {
      this.$bus.$emit("open-modal", id);
    },
    openParticipantsListWithPic(json) {
      this.loading = true;
      const reportName = "teilnehmerliste-foto-" + json.language;

      Api.get("veranstaltungen/", {
        params: { lehrgang: this.lehrgangid },
      })
        .then((response) => {
          if (response.data) {
            const veranstaltungsID = response.data[0].id;
            let filename = new Intl.DateTimeFormat("ch").format(new Date());
            this.pdfPath =
              server.url +
              `/pdfgenerator/pdf.act?filename=Teilnehmerliste_mit_Bild_${response.data[0].kuerzel}_${filename}.pdf&report=/pdfgenerator/${reportName}.htm&ids=${veranstaltungsID}&date=${this.date}`;
            this.$refs.pdfLayer.show();
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.noclassesfound"),
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.noclassesfound"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openParticipantsListWithPicWithoutContact(json) {
      this.loading = true;
      const reportName = "teilnehmerliste-foto-ohneKontakt-" + json.language;

      Api.get("veranstaltungen/", {
        params: { lehrgang: this.lehrgangid },
      })
        .then((response) => {
          if (response.data) {
            const veranstaltungsID = response.data[0].id;
            let filename = new Intl.DateTimeFormat("ch").format(new Date());
            this.pdfPath =
              server.url +
              `/pdfgenerator/pdf.act?filename=Teilnehmerliste_mit_Bild_${response.data[0].kuerzel}_${filename}.pdf&report=/pdfgenerator/${reportName}.htm&ids=${veranstaltungsID}&date=${this.date}`;
            this.$refs.pdfLayer.show();
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.noclassesfound"),
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.noclassesfound"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openParticipantsListNoPic(json) {
      this.loading = true;
      const reportName = "teilnehmerliste-" + json.language;

      Api.get("veranstaltungen/", {
        params: { lehrgang: this.lehrgangid },
      })
        .then((response) => {
          if (response.data) {
            const veranstaltungsID = response.data[0].id;
            let filename = new Intl.DateTimeFormat("ch").format(new Date());
            this.pdfPath =
              server.url +
              `/pdfgenerator/pdf.act?filename=Teilnehmerliste_${
                response.data[0].kuerzel
              }_${filename}.pdf&report=/pdfgenerator/${reportName}.htm&ids=${veranstaltungsID}&date=${
                this.date
              }&sgid=${false}`;
            this.$refs.pdfLayer.show();
          } else {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.noclassesfound"),
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.noclassesfound"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleOk() {
      this.lehrgang.bildungsgangtypChanged = true;
      this.bildungsgangtypAlt = this.lehrgang.bildungsgangtyp;
    },
    checkAnmeldeformular() {
      let check = false;
      if (
        this.lehrgang.anmeldeformular &&
        this.lehrgang.anmeldeformular.formularfelder &&
        this.lehrgang.anmeldeformular.formularfelder.length > 0
      ) {
        if (
          this.lehrgang.anmeldeformular.formularfelder.some(
            (feld) => feld.bezeichnung === "" || feld.bezeichnung === null
          )
        ) {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.designationmissinginregistrationform"),
          });
          return (check = false);
        } else check = true;
        if (
          this.lehrgang.anmeldeformular.formularfelder.some(
            (feld) =>
              feld.eingabetyp.appkey === "order" &&
              feld.custom &&
              feld.verrechnungsposition === null
          )
        ) {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.clearingpositionmissinginregistrationform"),
          });
          check = false;
        } else check = true;
      } else check = true; // für bestehende ohne Anmeldeformulare
      return check;
    },
    portalinfoValidChanged(newVal) {
      this.portalinfoValid = newVal;
    },
    openConfigGradesOverview() {
      this.$bus.$emit("openKonfigModal", this.lehrgang);
    },
  },
};
</script>

<style></style>
