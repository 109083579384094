<template>
  <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="form-group">
                <ValidationProvider
                  :rules="
                    korrespondenz.defaultsprache == lang.id ? 'required' : ''
                  "
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    :readonly="!editable"
                    v-if="korrespondenz.translation && lang"
                    v-model.trim="
                      korrespondenz.translation[lang.kuerzel.toLowerCase()]
                        .betreff
                    "
                    class="form-control"
                    v-bind:class="{ 'border-danger': !passed }"
                    placeholder=" "
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">
                    {{ $t("global.subject") }}
                  </label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  :rules="
                    korrespondenz.defaultsprache == lang.id ? 'required' : ''
                  "
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    :readonly="!editable"
                    v-model.trim="korrespondenz.ort"
                    class="form-control"
                    v-bind:class="{ 'border-danger': !passed }"
                    placeholder=" "
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">
                    {{ $t("global.city") }}
                  </label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <v-select
                  v-model="unterschrift1"
                  :options="unterschriften"
                  :disabled="!editable"
                  @input="unterschrift1Updated"
                  :placeholder="$t('global.signature')"
                  :getOptionLabel="
                    (unterschrift) =>
                      typeof unterschrift.person == 'string'
                        ? unterschrift.person
                        : unterschrift.person.personName
                  "
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.signature1") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <v-select
                  v-model="unterschrift2"
                  :options="unterschriften"
                  :disabled="!editable"
                  @input="unterschrift2Updated"
                  :placeholder="$t('global.signature')"
                  :getOptionLabel="
                    (unterschrift) =>
                      typeof unterschrift.person == 'string'
                        ? unterschrift.person
                        : unterschrift.person.personName
                  "
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.signature2") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6"></div>
            <div class="col-xl-5">
              <div class="form-group">
                <textarea
                  :readonly="!editable"
                  v-model="korrespondenz.beilagen"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.Attachments") }}</label>
              </div>
            </div>
            <div class="col-xl-1">
              <b-button
                class="mt-3"
                size="sm"
                variant="outline-primary"
                v-tooltip
                :title="$t('global.highlighttextcodes')"
                @click="highlightTextcodes"
              >
                <font-awesome-icon
                  icon="fa-regular fa-highlighter"
                ></font-awesome-icon>
              </b-button>
            </div>
          </div>

          <!-- ENDE Korrespondenzversand -->

          <div class="row" v-if="korrespondenz.translation && lang">
            <div class="col-xl-12">
              <ValidationProvider
                :rules="
                  korrespondenz.defaultsprache == lang.id ? 'required' : ''
                "
                immediate
                v-slot="{ passed }"
              >
                <div
                  class="form-group"
                  :style="{
                    border: passed ? '' : '1px solid red',
                    'padding-top': passed ? '' : '0',
                    'margin-top': passed ? '' : '16px',
                  }"
                >
                  <ckeditor
                    :disabled="!editable"
                    :editor="editor"
                    v-model="
                      korrespondenz.translation[lang.kuerzel.toLowerCase()].text
                    "
                    ref="ckeditorInstance"
                    @ready="captureEditorInstance"
                  ></ckeditor>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

import page from "@/mixins/Page";
import ClassicEditor from "/custom/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";

export default {
  name: "Korrespondenz",
  components: {},
  metaInfo() {},
  mixins: [page],
  store,
  props: {
    lang: {
      type: Object,
      required: true,
    },
    korrespondenz: {
      type: Object,
      required: true,
    },
    navbarTitel: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unterschrift1: {
        id: null,
        person: { personName: this.$t("global.notlinked") },
      },
      unterschrift2: {
        id: null,
        person: { personName: this.$t("global.notlinked") },
      },
      editor: ClassicEditor,
      editorInstance: null,
      //inhalt: "<p>Content of the editor.</p>",
      inhalt: null,
    };
  },
  computed: {
    unterschriften: {
      get() {
        return this.$store.state.korrespondenz.unterschriften;
      },
    },
    /*  unterschrift1Label() {
      if (this.unterschrift1?.person?.personName)
        return this.unterschrift1.person.personName;
      else return "";
    }, */
  },
  watch: {
    korrespondenz: {
      deep: true,
      handler(val) {
        if (val.unterschrift1 && val.unterschrift1.person) {
          this.unterschrift1 = val.unterschrift1;
        } else {
          this.unterschrift1 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }

        if (val.unterschrift2 && val.unterschrift2.person) {
          this.unterschrift2 = val.unterschrift2;
        } else {
          this.unterschrift2 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }
      },
    },
  },

  created() {
    if (
      this.korrespondenz &&
      this.korrespondenz.unterschrift1 &&
      this.korrespondenz.unterschrift1.person
    ) {
      this.unterschrift1 = this.korrespondenz.unterschrift1;
    } else {
      this.unterschrift1 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }

    if (
      this.korrespondenz &&
      this.korrespondenz.unterschrift2 &&
      this.korrespondenz.unterschrift2.person
    ) {
      this.unterschrift2 = this.korrespondenz.unterschrift2;
    } else {
      this.unterschrift2 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }
  },

  mounted() {
    if (this.unterschriften.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    unterschrift1Updated(val) {
      if (val && val.id) this.$emit("unterschrift1updated", val);
      else this.$emit("unterschrift1updated", null);
    },

    unterschrift2Updated(val) {
      if (val && val.id) this.$emit("unterschrift2updated", val);
      else this.$emit("unterschrift2updated", null);
    },
    captureEditorInstance(editor) {
      this.editorInstance = editor;
    },
    highlightTextcodes() {
      if (this.editorInstance) {
        const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;
        const editorData = this.editorInstance.getData();

        const updatedData = editorData.replace(
          pattern,
          (match, p1, p2, p3, p4) => {
            const highlightedText = p1 || p3;
            return highlightedText
              ? `<span style="color:#5299e0;">${highlightedText}</span>`
              : match;
          }
        );

        this.editorInstance.setData(updatedData);
      } else {
        console.error("Editor instance is not available.");
      }
    },
  },
};
</script>

<style></style>
