<template>
  <div class="col-xl-12 pr-4">
    <!--  <div @click="toggleOpen = !toggleOpen" class="hover-light">
      <container-headline
        :headline="$t('global.content') + ' ' + this.lang"
        :col="3"
        ><div v-if="toggleOpen === true" class="ml-n5">
          <font-awesome-icon icon="fa-solid fa-chevron-up" />
        </div>
        <div v-if="toggleOpen === false" class="ml-n5">
          <font-awesome-icon icon="fa-solid fa-chevron-down" /></div
      ></container-headline>
    </div> -->
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="row">
                <div
                  class="col-xl-12"
                  v-if="!korrespondenz.isMassKorrespondenz"
                >
                  <div class="form-group">
                    <ValidationProvider
                      rules="required"
                      immediate
                      v-slot="{ passed }"
                    >
                      <textarea
                        v-model.trim="korrespondenz.korrespondenzadresse"
                        class="form-control"
                        v-bind:class="{ 'border-danger': !passed }"
                        rows="5"
                        :readonly="!editable"
                      ></textarea>
                      <label v-bind:class="{ 'text-danger': !passed }">
                        {{ $t("global.correspondenceaddress") }}
                      </label>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="form-group">
                    <ValidationProvider
                      rules="required"
                      immediate
                      v-slot="{ passed }"
                    >
                      <input
                        v-if="!korrespondenz.versand.isVorlage"
                        v-model.trim="korrespondenz.versand.betreff"
                        v-bind:class="{ 'border-danger': !passed }"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                      />

                      <input
                        v-if="
                          korrespondenz.versand.isVorlage &&
                          korrespondenz.versand.translation &&
                          lang
                        "
                        v-model.trim="
                          korrespondenz.versand.translation[lang.kuerzel]
                            .betreff
                        "
                        v-bind:class="{ 'border-danger': !passed }"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                      />
                      <label v-bind:class="{ 'text-danger': !passed }">{{
                        $t("global.subject")
                      }}</label>
                    </ValidationProvider>
                  </div>
                </div>
                <div
                  class="col-xl-12"
                  v-if="!korrespondenz.isMassKorrespondenz"
                >
                  <div class="form-group">
                    <input
                      v-model.trim="korrespondenz.anrede"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.salutation") }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <ValidationProvider
                      rules="required"
                      immediate
                      v-slot="{ passed }"
                    >
                      <input
                        v-model.trim="korrespondenz.versand.ort"
                        class="form-control"
                        v-bind:class="{ 'border-danger': !passed }"
                        :readonly="!editable"
                        placeholder=" "
                      />
                      <label v-bind:class="{ 'text-danger': !passed }">
                        {{ $t("global.city") }}
                      </label>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="korrespondenz.versand.datum"
                      placeholder=" "
                      :disabled="!editable"
                      :show-icon="true"
                      @update="(val) => (korrespondenz.versand.datum = val)"
                    />
                    <label
                      v-bind:class="{
                        'text-danger': !korrespondenz.versand.datum,
                      }"
                    >
                      {{ $t("global.date") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="unterschrift1"
                      :options="unterschriften"
                      :disabled="!editable"
                      @input="unterschrift1Updated"
                      :placeholder="$t('global.signature')"
                      :getOptionLabel="
                        (unterschrift) => unterschrift.person.personName
                      "
                    >
                      <template #option="{ person }">
                        {{ person.personName }}
                      </template>
                      <template #selected-option="{ person }">
                        {{
                          person.id ? person.personName : $t("global.notlinked")
                        }}
                      </template>
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.signature1") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="unterschrift2"
                      :options="unterschriften"
                      :disabled="!editable"
                      @input="unterschrift2Updated"
                      :placeholder="$t('global.signature')"
                      :getOptionLabel="
                        (unterschrift) => unterschrift.person.personName
                      "
                    >
                      <template #option="{ person }">
                        {{ person.personName }}
                      </template>
                      <template #selected-option="{ person }">
                        {{
                          person.id ? person.personName : $t("global.notlinked")
                        }}
                      </template>
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.signature2") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-10">
                  <div class="form-group">
                    <textarea
                      v-model.trim="korrespondenz.versand.beilagen"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.Attachments") }}</label>
                  </div>
                </div>
                <div class="col-xl-1">
                  <b-button
                    class="mt-3"
                    size="sm"
                    variant="outline-primary"
                    v-tooltip
                    :title="$t('global.highlighttextcodes')"
                    @click="highlightTextcodes"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-highlighter"
                    ></font-awesome-icon>
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE Korrespondenzversand -->

          <div class="row" v-if="!korrespondenz.versand.isVorlage">
            <div class="col-xl-12">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <div
                  class="form-group"
                  :style="{
                    border: passed ? '' : '1px solid red',
                    'padding-top': passed ? '' : '0',
                    'margin-top': passed ? '' : '16px',
                  }"
                >
                  <ckeditor
                    :editor="editor"
                    v-model="korrespondenz.versand.inhalt"
                    :disabled="!editable"
                    ref="ckeditorInstance"
                    @ready="captureEditorInstance"
                  ></ckeditor>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div
            class="row"
            v-if="
              korrespondenz.versand.isVorlage &&
              korrespondenz.versand.translation &&
              lang
            "
          >
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model.trim="
                    korrespondenz.versand.translation[lang.kuerzel].text
                  "
                  :disabled="!editable"
                  ref="ckeditorInstance"
                  @ready="captureEditorInstance"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ContainerHeadline from "@/components/ContainerHeadline";
import store from "@/store";
import DatePicker from "@/components/Datepicker";

import ClassicEditor from "/custom/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";

export default {
  name: "KorrespondenzInhalt",
  components: {
    //ContainerHeadline,
    DatePicker,
  },
  metaInfo() {},
  mixins: [],
  store,
  props: {
    korrespondenz: {
      type: Object,
      required: true,
    },
    lang: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unterschrift1: { person: { personName: this.$t("global.notlinked") } },
      unterschrift2: { person: { personName: this.$t("global.notlinked") } },
      editor: ClassicEditor,
      editorInstance: null,
    };
  },
  computed: {
    unterschriften: {
      get() {
        return this.$store.state.korrespondenz.unterschriften;
      },
    },
  },
  watch: {
    korrespondenz: {
      deep: true,
      handler(val) {
        if (
          val.versand &&
          val.versand.unterschrift1 &&
          val.versand.unterschrift1.person
        ) {
          this.unterschrift1 = val.versand.unterschrift1;
        } else {
          this.unterschrift1 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }

        if (
          val.versand &&
          val.versand.unterschrift2 &&
          val.versand.unterschrift2.person
        ) {
          this.unterschrift2 = val.versand.unterschrift2;
        } else {
          this.unterschrift2 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }
      },
    },
  },

  created() {
    if (
      this.korrespondenz.versand &&
      this.korrespondenz.versand.unterschrift1 &&
      this.korrespondenz.versand.unterschrift1.person
    ) {
      this.unterschrift1 = this.korrespondenz.versand.unterschrift1;
    } else {
      this.unterschrift1 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }

    if (
      this.korrespondenz.versand &&
      this.korrespondenz.versand.unterschrift2 &&
      this.korrespondenz.versand.unterschrift2.person
    ) {
      this.unterschrift2 = this.korrespondenz.versand.unterschrift2;
    } else {
      this.unterschrift2 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }
  },

  mounted: function () {
    if (this.unterschriften.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    captureEditorInstance(editor) {
      this.editorInstance = editor;
    },
    highlightTextcodes() {
      if (this.editorInstance) {
        const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;
        const editorData = this.editorInstance.getData();

        const updatedData = editorData.replace(
          pattern,
          (match, p1, p2, p3, p4) => {
            const highlightedText = p1 || p3;
            return highlightedText
              ? `<span style="color:#5299e0;">${highlightedText}</span>`
              : match;
          }
        );

        this.editorInstance.setData(updatedData);
      } else {
        console.error("Editor instance is not available.");
      }
    },

    unterschrift1Updated(val) {
      if (val && val.id) this.$emit("unterschrift1updated", val);
      else this.$emit("unterschrift1updated", null);
    },

    unterschrift2Updated(val) {
      if (val && val.id) this.$emit("unterschrift2updated", val);
      else this.$emit("unterschrift2updated", null);
    },
  },
};
</script>

<style></style>
