<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2">
              <div class="ml-4 mr-2">
                <button
                  class="btn btn-success"
                  :disabled="!editable"
                  @click="speichern"
                >
                  {{ $t("global.save") }}
                </button>
              </div>

              <div class="mr-2">
                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />
                  {{ $t("global.tolist") }}
                </button>
              </div>
              <div class="mr-2">
                <button
                  v-if="berechtigungen.m_finanzen.create"
                  class="btn btn-primary"
                  @click="oeffneKorrekturbuchungsModal"
                >
                  {{ $t("global.adjustmentposting") }}
                </button>
              </div>
              <div class="mr-2">
                <button
                  v-if="berechtigungen.m_finanzen.update"
                  class="btn btn-primary"
                  @click="
                    showAlertModal(
                      $t('global.lowerdunninglevel'),
                      $t('global.doyouwanttolowerdunninglevelfromdebtors'),
                      null
                    )
                  "
                >
                  {{ $t("global.lowerdunninglevel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.debitornummer"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.debtornum") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.zahlungsausgleichstatus.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.statusofpaymentclearing") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.ausgleichsdatum"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.clearingdate") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.zahlungstatus.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.statuspayment") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <textarea
                      v-model="debitor.bemerkung"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.comment") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.konto.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.account") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
        <!-- START Adress-Informationen -->
        <container-headline
          :headline="$t('global.addressinformation')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <single-select-firma
                    id="debitorFirma"
                    :firma="this.debitor.firma"
                    :editable="false"
                    :allowOpen="true"
                    :label="$t('global.company')"
                    @confirmed="setFirma"
                  />
                </div>
                <div class="col-xl-6">
                  <single-select-person
                    id="person"
                    :person="this.debitor.person"
                    :label="$t('global.person')"
                    :allowOpen="true"
                    :editable="false"
                    :required="true"
                    @confirmed="setPerson"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <textarea
                      v-model="debitor.rechnung_an"
                      readonly
                      class="form-control"
                      placeholder=" "
                      rows="4"
                    />
                    <label>{{ $t("global.invoiceto") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <textarea
                      v-model="debitor.debitoradresse.adresse_label"
                      class="form-control"
                      placeholder=" "
                      rows="4"
                      readonly
                    />
                    <label>{{ $t("global.debtoraddress") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <textarea
                      v-model="debitor.mahnung_an"
                      readonly
                      class="form-control"
                      placeholder=" "
                      rows="4"
                    />
                    <label>{{ $t("global.reminderto") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Adress-Informationen -->
      </div>
      <!-- ENDE linke Seite -->
      <!-- START rechte Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Auftrag -->
        <container-headline
          :headline="$t('global.order')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.auftrag.auftragsnummer"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <span class="openIcon" v-on:click="goToAuftrag"
                      ><font-awesome-icon
                        icon="fa-duotone fa-arrow-circle-right"
                      />
                    </span>
                    <label>{{ $t("global.order") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.auftrag.buchungsstatus.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.statusbooking") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.auftrag.rechnungsdatum"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.invoicedate") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.auftrag.totalBrutto"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.ordergross") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Auftrag -->
        <!-- START Zahlungsplan -->
        <container-headline
          :headline="$t('global.paymentschedule')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="debitor.zahlungsplan.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.paymentschedule") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.ratenfaelligkeit"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.installmentduedate") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.zahlungsplan.label"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.paymentrate") }}</label>
                  </div>
                </div>
                <!--
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.offenerfaelligersaldo"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>Offener fälliger Saldo</label>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Zahlungsplan -->
        <!-- START Mahnlauf -->
        <container-headline
          :headline="$t('global.dunningrun')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.mahnstufestatus.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.activedunninglevel") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.mahnsperre"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.dunningblock") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.faelligkeitsdatum"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.duedatefrominvoice") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.mahndatum"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.duedatefromdunninglevel") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="debitor.mahnsperrevon"
                      placeholder=" "
                      :disabled="!editable"
                      :show-icon="true"
                      @update="(val) => (debitor.mahnsperrevon = val)"
                    />
                    <label>{{ $t("global.dunningblockfrom") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="debitor.mahnsperrebis"
                      placeholder=" "
                      :disabled="!editable"
                      :show-icon="true"
                      @update="(val) => (debitor.mahnsperrebis = val)"
                    />
                    <label>{{ $t("global.dunningblockuntil") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Mahnlauf -->
        <!-- START Beträge -->
        <container-headline
          :headline="$t('global.Amount')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.waehrung.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.currency") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.bruttobetrag"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.grossamount") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.mahnspesen"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.reminderfees") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.zahlungseingaenge"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.totalincomingpayments") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.minderungen"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.reductions") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="debitor.offener_saldo"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.openbalance") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Beträge -->
      </div>
      <!--ENDE rechte Seite-->
      <div v-if="id" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 0 }"
                  :to="{
                    name: 'BelegMitIdUndAnzeige',
                    params: { id: id, anzeige: 0 },
                  }"
                  >{{ $t("global.accountingentries") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-xl-12 p-0">
            <div class="tab-container p-3">
              <div v-show="anzeige === 0">
                <belege
                  :debitor="id"
                  :shown="anzeige === 0"
                  :editable="editable"
                  :key="beleglisteKey"
                  :triggerRefresh="triggerRefreshBelege"
                  @deleted="refreshPage"
                  @betraegeUpdated="getZahlschuld"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="mr-2 text-warning"
              />
              {{ $t("global.deletepayment") }}
            </h4>
          </div>
          <div class="modal-body">
            {{ $t("global.doyouwantdeletethepayment") }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <single-select-zahlungseingang :id="id" :betrag="betrag" />
    <korrekturbuchungs-modal
      :debitor="debitor"
      @confirmedKorrekturbuchung="korrekturbuchung"
      no-close-on-backdrop="true"
      :clickToClose="false"
      v-bind:click-to-close="false"
    />
    <alert-modal
      id="modal-alert"
      :title="alertModal.title"
      :text="alertModal.text"
      :color="alertModal.color"
      :eventName="'mahnstufeSenkenConfirmed'"
      @mahnstufeSenkenConfirmed="mahnstufeSenken"
    />

    <unsaved-changes-modal
      @discard="handleDiscard"
      @stay="handleStay"
      :saveFunction="!editable ? null : speichern"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import Belege from "@/components/Reiter/Belege";
import SingleSelectZahlungseingang from "@/pages/Finanzen/Modals/SingleSelectZahlungseingang.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import KorrekturbuchungsModal from "@/components/Debitor/KorrekturbuchungsModal.vue";
import AlertModal from "@/components/Modals/AlertModal";
import DatePicker from "@/components/Datepicker";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import AppApi from "@/AppApi";

export default {
  name: "Debitor",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    SingleSelectZahlungseingang,
    DatePicker,
    SingleSelectPerson,
    Belege,
    KorrekturbuchungsModal,
    AlertModal,
    SingleSelectFirma,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: false,
      debitor: {
        zahlungstatus: {},
        auftrag: { id: "", buchungsstatus: {} },
        mahnstufestatus: {},
        konto: {},
        person: {},
        debitoradresse: {},
        waehrung: {},
        zahlungsausgleichstatus: {},
        zahlungsplan: {},
      },
      betrag: 0,
      loading: false,
      //isDisabled: true,
      beleglisteKey: 0,
      alertModal: {
        color: null,
        text: "",
        title: "",
      },
      selectedIds: [this.id],
      triggerRefreshBelege: 0,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.debitor.debitornummer) {
        return `${this.$t("global.debtor")} ${this.debitor.debitornummer}`;
      } else {
        return this.$t("global.debtor");
      }
    },
    /*
    zahlungseingangstati: {
      get() {
        return this.$store.state.zahlungseingang.zahlungseingangstati;
      },
    },
    */
  },
  created() {
    this.getZahlschuld();
  },
  mounted() {},
  methods: {
    getZahlschuld() {
      Api.get("finanzen/zahlschuld/", {
        params: { id: this.id },
      }).then((response) => {
        this.initializeDebitor(response.data);
      });
    },
    refreshPage() {
      console.log("deleted");
      this.$router.go();
    },
    /*compareBetraege(val) {
      if (val.zahlungseingaenge != this.debitor.zahlungseingaenge) {
        this.debitor.zahlungseingaenge = val.zahlungseingaenge;
      }
      if (val.minderungen != this.debitor.minderungen) {
        this.debitor.minderungen = val.minderungen;
      }
    },*/
    mahnstufeSenken() {
      if (this.debitor.mahnstufestatus.level === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.debtoralreadyhaslowestdunninglevel"),
        });
        return;
      }
      AppApi.post("finanzen/mahnungen/senken/", this.selectedIds).then(
        (response) => {
          if (response.data.error.length === 0) {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.debtorsdunninglevelbeenlowered"),
            });
          } else {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.debtorwasnotfound"),
            });
          }
          Api.get("finanzen/zahlschuld/", {
            params: { id: this.id },
          }).then((response) => {
            this.initializeDebitor(response.data);
          });
        }
      );
      this.triggerRefreshBelege++;
    },
    showAlertModal(title, text, color) {
      if (this.selectedIds.length > 0) {
        this.alertModal.title = title;
        this.alertModal.text = text;
        this.alertModal.color = color;
        $("#modal-alert").modal("show");
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.notification_nodebtorselected"),
        });
    },
    setPerson(person) {
      this.debitor.person = person;
    },
    setFirma(firma) {
      this.debitor.firma = firma;
    },
    goToAuftrag() {
      if (this.debitor.auftrag.id) {
        this.$router.push({
          name: "auftrag.id",
          params: { id: this.debitor.auftrag.id, anzeige: 0 },
        });
      }
    },
    oeffneKorrekturbuchungsModal() {
      $("#korrektur-buchung-modal").modal("show");
    },
    korrekturbuchung(json) {
      AppApi.post("finanzen/beleg/", json)
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.receiptsuccessfullysaved"),
          });
          Api.get("finanzen/zahlschuld/", {
            params: { id: this.id },
          }).then((response) => {
            this.initializeDebitor(response.data);
          });
          this.beleglisteKey++;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    oeffneSingleSelectModal() {
      $("#zahlungseingang-zuweisen-modal").modal("show");
      this.$bus.$emit("zahlungseingang-zuweisen-modal", null);
    },
    abbrechen() {
      this.$router.push({ name: "Debitoren" });
    },
    initializeDebitor(debitor) {
      if (debitor) {
        this.debitor = debitor;
        if (!this.debitor.auftrag.buchungsstatus)
          this.debitor.auftrag.buchungsstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };
        if (!this.debitor.zahlungsplan)
          this.debitor.zahlungsplan = {
            bezeichnung: this.$t("global.notlinked"),
            label: this.$t("global.notlinked"),
          };
        if (!this.debitor.debitoradresse)
          this.debitor.debitoradresse = {
            strasse_nr: this.$t("global.notlinked"),
          };
        if (!this.debitor.zahlungsausgleichstatus)
          this.debitor.zahlungsausgleichstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };
        if (!this.debitor.zahlungstatus)
          this.debitor.zahlungstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };
        if (!this.debitor.konto)
          this.debitor.konto = { bezeichnung: this.$t("global.notlinked") };
        if (!this.debitor.mahnstufestatus)
          this.debitor.mahnstufestatus = {
            bezeichnung: this.$t("global.notlinked"),
          };
        if (this.debitor.mahnsperre) {
          this.debitor.mahnsperre = "aktiv";
        } else {
          this.debitor.mahnsperre = "inaktiv";
        }

        this.initializeChangewatcher(["debitor"]);
        this.editable = this.berechtigungen.m_finanzen.update;
      } else {
        console.error("Debitor ist null");
      }
    },
    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.debitor);

      Api.put("finanzen/zahlschuld/", json, { params: { id: this.id } })
        .then((response) => {
          this.initializeDebitor(response.data);
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.debtorsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("finanzen/zahlungseingang/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "Zahlungen" });
      });
    },
  },
};
</script>

<style></style>
