<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2">
                <div class="ml-4" />
                <div class="mr-2" v-if="editable">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid || !portalinfoValid"
                    @click="speichern"
                    @shortkey="speichern"
                    :title="
                      invalid
                        ? $t('global.coursedatanotvalid')
                        : !portalinfoValid
                        ? $t('global.portalinfonotvalid')
                        : ''
                    "
                  >
                    {{ $t("global.save") }}
                  </button>
                </div>
                <div class="mr-2" v-if="editable">
                  <b-dropdown variant="primary">
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-regular fa-cog"
                        class="mr-2"
                      />
                      <em>{{ $t("global.actions") }}</em>
                    </template>
                    <b-dropdown-item @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2 text-danger"
                      />
                      {{ $t("global.delete") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="bildungsgangKopierenModal"
                      :disabled="invalid"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-gem"
                        class="mr-2"
                      />
                      {{ $t("global.createeducationcourse") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="mr-2">
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12" :class="{ 'col-xl-12': !showNavbar }">
          <bildungsgang-stammdaten
            :lehrgang="lehrgang"
            :isTemplate="true"
            :editable="editable"
          />
        </div>

        <div class="col-xl-12 p-0" :class="{ 'col-xl-12': !showNavbar }">
          <vorlage-promotionsstruktur
            :klassen="klassen"
            :lehrgang="lehrgang"
            :notentypen="notentypen"
            @psChanged="setPromotionsstruktur"
            @enChanged="setEndnotenstruktur"
          />
        </div>

        <!-- REITER -->

        <div v-if="lehrgangid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'template-bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 0 },
                    }"
                    >{{ $t("global.assignment") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'template-bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 1 },
                    }"
                    >{{ $t("global.portalinformation") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'template-bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 2 },
                    }"
                    >{{ $t("global.clearingitems") }}</router-link
                  >
                </li>

                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'template-bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 3 },
                    }"
                    >{{ $t("global.registrationform") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <!-- Start Zuordnung -->
                <div v-show="anzeige === 0">
                  <template-klassenliste
                    :lehrgang="lehrgang"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    :isTemplate="true"
                    @klasseLoaded="setKlassen"
                  />
                </div>
                <!-- Ende Zuordnung -->

                <!-- Start Portalinfos -->
                <div v-show="anzeige === 1 && lehrgang.portalanzeige">
                  <portalinformationen
                    :lehrgang="lehrgang"
                    :isKurzveranstaltung="false"
                    :portalinfoId="portalinfoId"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                    :isTemplate="true"
                    @portalinfoChanged="portalinfoChanged"
                    @portalinfoValidChanged="portalinfoValidChanged"
                    @portalinfoInitialized="handlePortalinfoInitialized"
                  />
                </div>
                <!-- Ende Portalinfos -->

                <!-- Start Verrechnungspositionen -->
                <div v-show="anzeige === 2 && lehrgang.portalanzeige">
                  <bildungsgang-verrechnungspositionen
                    :lehrgang="lehrgang"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                    :isTemplate="true"
                  />
                </div>
                <!-- Ende Verrechnungspositionen -->

                <!-- Start Anmeldeformular -->
                <div v-show="anzeige === 3 && lehrgang.portalanzeige">
                  <anmeldeformular
                    :lehrgang="lehrgang"
                    :shown="anzeige == 3 ? true : false"
                    :editable="editable"
                    :isTemplate="true"
                    :mailvorlagen="mailvorlagen"
                  />
                </div>
                <!-- Ende Anmeldeformular -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="bildungsgang-loeschen-modal"
      @confirmed="bildungsgangloeschen"
    />

    <message-box
      id="bildungsgang-kopieren-modal"
      :headerText="$t('global.copyeducationcourse')"
      :text="$t('global.doyouwanttocopyalloronlythefirstsemester')"
      :ok="true"
      :cancel="true"
      :repeat="true"
      :okText="$t('global.semesterone')"
      :repeatText="$t('global.allsemesters')"
      :cancelText="$t('global.cancel')"
      @ok="createBildungsgangOne"
      @repeat="createBildungsgangAll"
    />

    <message-box
      id="bildungsgangtyp-ändern-modal"
      :headerText="$t('global.changecoursetype')"
      :text="$t('global.changecoursetypewarningtext')"
      :ok="true"
      :cancel="true"
      :okText="$t('global.apply')"
      :cancelText="$t('global.cancel')"
      @ok="handleOk"
      @cancel="lehrgang.bildungsgangtyp = bildungsgangtypAlt"
    />

    <loading-overlay v-if="isBusy"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";

import BildungsgangStammdaten from "@/components/Bildung/Bildungsgang/Stammdaten";
import VorlagePromotionsstruktur from "@/components/Bildung/Bildungsgang/VorlagePromotionsstruktur";
import TemplateKlassenliste from "@/components/Bildung/Bildungsgang/TemplateKlassenliste";
import Portalinformationen from "@/components/Reiter/Portalinformationen";
import Anmeldeformular from "@/components/Reiter/Anmeldeformular";
import BildungsgangVerrechnungspositionen from "@/components/Reiter/BildungsgangVerrechnungspositionen";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageBox from "@/components/Modals/Messagebox";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "TemplateBildungsgang",
  components: {
    BildungsgangStammdaten,
    HeadMenu,
    Navbar,
    LoadingOverlay,
    LoeschenModal,
    TemplateKlassenliste,
    MessageBox,
    VorlagePromotionsstruktur,
    Portalinformationen,
    BildungsgangVerrechnungspositionen,
    Anmeldeformular,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  props: {
    lehrgangid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isBusy: false,
      editable: true,
      loading: false,
      klassen: [],
      lehrgang: {
        lehrgangstatus: null,
        lehrgangleiter: { personName: this.$t("global.notlinked") },
        kontakt: { personName: this.$t("global.notlinked") },
        verantwortliche: [{ personName: this.$t("global.notlinked") }],
        verantwortliche2: [{ personName: this.$t("global.notlinked") }],
        portalinfo: { id: null },
        portalanzeige: true,
        anmeldeformularMailvorlage: null,
        //sprache: { id: null, bezeichnung: null, tblName: null },
        sprache: [],
      },
      bildungsgangtypAlt: null,
      portalinfoValid: true,
    };
  },
  computed: {
    navbarTitel() {
      return this.$t("global.educationcoursetemplate");
    },
    notentypen: {
      get() {
        return this.$store.state.veranstaltung.notentypen;
      },
    },
    mailvorlagen: {
      get() {
        return this.$store.state.veranstaltung.mailvorlagen;
      },
    },
    sprachen: {
      get() {
        return this.$store.state.veranstaltung.sprachen;
      },
    },
    portalinfoId() {
      if (this.lehrgang.portalinfo?.id) return this.lehrgang.portalinfo?.id;
      return null;
    },
  },
  watch: {
    ["lehrgang.bildungsgangtyp"]: {
      deep: true,
      handler(neu, alt) {
        if (
          !this.lehrgang.anmeldeformular ||
          !alt ||
          this.bildungsgangtypAlt?.id === neu.id ||
          alt.id === neu.id
        )
          return;

        this.$bus.$emit("open-modal", "bildungsgangtyp-ändern-modal");
        this.bildungsgangtypAlt = alt;
      },
    },
    sprachen: {
      deep: true,
      handler(val) {
        if (val) {
          if (this.lehrgang.sprache.length === 0) this.setLanguage();
        }
      },
    },
  },
  created() {
    if (this.lehrgangid) {
      Api.get("template/lehrgang/", { params: { id: this.lehrgangid } }).then(
        (response) => {
          this.initializeBildungsgang(response.data);
        }
      );
    } else {
      this.editable = true;
      this.setLanguage();
    }

    if (this.notentypen.length == 0) {
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
    }
  },
  mounted: function () {},
  methods: {
    portalinfoChanged() {
      this.$set(this.hasChanges, "portalinformationen", true);
    },

    handlePortalinfoInitialized() {
      this.$set(this.hasChanges, "portalinformationen", false);
    },

    portalinfoValidChanged(newVal) {
      this.portalinfoValid = newVal;
    },

    setPromotionsstruktur(val) {
      this.lehrgang.promotionsstruktur = val;
      this.initializeChangewatcher(["lehrgang"]);
    },

    setEndnotenstruktur(val) {
      this.lehrgang.endnotenstruktur = val;
      this.initializeChangewatcher(["lehrgang"]);
    },

    abbrechen() {
      this.$router.push({ name: "template-bildungsgang-liste" });
    },
    setKlassen(val) {
      this.klassen = val;
    },

    setLanguage() {
      const defaultSprache = this.$CONST("CONFIG").DEFAULTLANGUAGE;

      let defaultSprachenObj = this.sprachen.find(
        (sprache) => sprache.id === defaultSprache
      );
      //this.$set(this.lehrgang, "sprache", defaultSprachenObj);
      this.lehrgang.sprache.push(defaultSprachenObj);
    },

    initializeBildungsgang(lehrgang) {
      if (lehrgang) {
        this.lehrgang = lehrgang;

        if (!this.lehrgang.lehrgangleiter)
          this.lehrgang.lehrgangleiter = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.lehrgang.lehrgangstatus)
          this.lehrgang.lehrgangstatus = {
            id: this.$CONST("LEHRGANGSTATI").PLANNED,
          };

        if (this.lehrgang.verantwortliche.length === 0) {
          this.lehrgang.verantwortliche.push({
            personName: this.$t("global.notlinked"),
          });
        }
        if (this.lehrgang.verantwortliche2.length === 0) {
          this.lehrgang.verantwortliche2.push({
            personName: this.$t("global.notlinked"),
          });
        }

        if (this.lehrgang.sprache.length === 0) {
          this.setLanguage();
        }

        this.editable =
          this.lehrgang.lehrgangstatus.id ==
          this.$CONST("LEHRGANGSTATI").PLANNED
            ? true
            : true;

        // TODO: diesen Block entfernen, sobald Backend diesen Flag mitsendet
        if (lehrgang.portalanzeige) {
          this.lehrgang.portalanzeige = lehrgang.portalanzeige;
        } else {
          this.lehrgang.portalanzeige = true;
        }
      } else this.editable = true;

      this.editable = this.berechtigungen.b_bildung_bildungsgang.update;

      this.initializeChangewatcher(["lehrgang"]);
    },

    speichern() {
      if (this.loading) return;

      if (!this.checkAnmeldeformular()) return;

      this.loading = true;
      if (this.lehrgang.teilnehmer_max === "") {
        this.lehrgang.teilnehmer_max = null;
      }

      var json = Object.assign({}, this.lehrgang);

      // Wenn Postalinfos vorhanden, diese mitspeichern
      if (this.lehrgang.portalanzeige && this.portalinfoValid) {
        this.$bus.$emit("savePortalinfo");
      } else if (this.lehrgang.portalanzeige && !this.portalinfoValid) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.portalinfonotvalid"),
        });
      }

      // Relationen auf ID reduzieren

      json.lehrgangstatus = this.lehrgang.lehrgangstatus
        ? this.lehrgang.lehrgangstatus.id
        : this.$CONST("LEHRGANGSTATI").PLANNED;

      json.lehrgangleiter = this.lehrgang.lehrgangleiter.id;

      // Defaultwerte

      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").STUDIES;

      if (!this.lehrgangid) {
        Api.post("template/lehrgang/", json)
          .then((response) => {
            this.initializeBildungsgang(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.educationcoursesuccessfullysaved"),
            });
            this.$router.replace({
              name: "template-bildungsgang",
              params: { lehrgangid: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("template/lehrgang/", json, {
          params: { id: this.lehrgang.id },
        })
          .then((response) => {
            this.initializeBildungsgang(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.educationcoursesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "bildungsgang-loeschen-modal");
    },

    bildungsgangloeschen() {
      if (!this.lehrgangid) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.educationcoursenotyetsaved"),
        });
      } else {
        Api.delete("template/lehrgang/", {
          params: { ids: this.lehrgangid },
        }).then(() => {
          this.$router.push({ name: "template-bildungsgang-liste" });
        });
      }
    },

    bildungsgangKopierenModal() {
      this.$bus.$emit("open-modal", "bildungsgang-kopieren-modal");
    },

    createBildungsgangOne() {
      if (!this.lehrgangid) {
        this.$notify({
          type: "warning",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noeducationcourseassigned"),
        });
      } else {
        this.isBusy = true;
        var json = Object.assign({}, this.lehrgang);
        json.klassen = this.klassen;
        Api.post(
          "template/vontemplate/?id=" + this.lehrgangid + "&all=false",
          json
        )
          .then(() => {
            this.$router.push({ name: "Bildungsgangliste" });
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    createBildungsgangAll() {
      if (this.lehrgangid === null) {
        this.$notify({
          type: "warning",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noeducationcourseassigned"),
        });
      } else {
        this.isBusy = true;
        var json = Object.assign({}, this.lehrgang);
        json.klassen = this.klassen;
        Api.post(
          "template/vontemplate/?id=" + this.lehrgangid + "&all=true",
          json
        )
          .then(() => {
            this.$router.push({ name: "Bildungsgangliste" });
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    handleOk() {
      this.lehrgang.bildungsgangtypChanged = true;
      this.bildungsgangtypAlt = this.lehrgang.bildungsgangtyp;
    },
    checkAnmeldeformular() {
      let check = false;
      if (
        this.lehrgang.anmeldeformular &&
        this.lehrgang.anmeldeformular.formularfelder &&
        this.lehrgang.anmeldeformular.formularfelder.length > 0
      ) {
        if (
          this.lehrgang.anmeldeformular.formularfelder.some(
            (feld) => feld.bezeichnung === "" || feld.bezeichnung === null
          )
        ) {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.designationmissinginregistrationform"),
          });
          return (check = false);
        } else check = true;
        if (
          this.lehrgang.anmeldeformular.formularfelder.some(
            (feld) =>
              feld.eingabetyp.appkey === "order" &&
              feld.custom &&
              feld.verrechnungsposition === null
          )
        ) {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.clearingpositionmissinginregistrationform"),
          });
          check = false;
        } else check = true;
      } else check = true; // für bestehende ohne Anmeldeformulare
      return check;
    },
  },
};
</script>

<style></style>
