export const DOCS_BASE_URL = "https://www.bx-education.ch/docs/erp/";

export default {
  data() {
    return {
      noHelpURL: DOCS_BASE_URL,
      helpUrlMap: {
        "personen-liste": DOCS_BASE_URL + "crm/#personen",
        Geschaeftspartner: DOCS_BASE_URL + "crm/#personen",
        Firmenliste: DOCS_BASE_URL + "crm/#unternehmen",
        Firma: DOCS_BASE_URL + "crm/#unternehmen",

        Bildungsliste: DOCS_BASE_URL + "ausbildung/#bildungsgang",
        Bildungsgang: DOCS_BASE_URL + "ausbildung/#bildungsgang",
        Klassenliste: DOCS_BASE_URL + "ausbildung/#klasse",
        Klasse: DOCS_BASE_URL + "ausbildung/#klasse",
        "faecher-liste": DOCS_BASE_URL + "ausbildung/#faecher",
        Fach: DOCS_BASE_URL + "ausbildung/#faecher",
        Kurzveranstaltungsliste: DOCS_BASE_URL + "ausbildung",
        Kurzveranstaltung: DOCS_BASE_URL + "ausbildung",
        event: DOCS_BASE_URL + "ausbildung",
        infoanlass: DOCS_BASE_URL + "ausbildung",
        Kursliste: DOCS_BASE_URL + "ausbildung",
        kurs: DOCS_BASE_URL + "ausbildung",

        Rechnungsliste: DOCS_BASE_URL + "finanzen/#auftraege",
        Rechnung: DOCS_BASE_URL + "finanzen/#auftraege",
        Honorarliste: DOCS_BASE_URL + "finanzen/#honorare",
        Honorar: DOCS_BASE_URL + "finanzen/#honorare",
        Debitoren: DOCS_BASE_URL + "finanzen/#debitoren",
        Debitor: DOCS_BASE_URL + "finanzen/#debitoren",
        Mahnungen: DOCS_BASE_URL + "finanzen/#mahnungen",
        Mahnung: DOCS_BASE_URL + "finanzen/#mahnungen",
        Zahlungen: DOCS_BASE_URL + "finanzen/#zahlungseingaenge",
        Zahlungseingang: DOCS_BASE_URL + "finanzen/#zahlungseingaenge",
        Belege: DOCS_BASE_URL + "finanzen/#belege",
        Beleg: DOCS_BASE_URL + "finanzen/#belege",

        Korrespondenzliste: DOCS_BASE_URL + "kommunikation/#korrespondenz",
        Korrespondenz: DOCS_BASE_URL + "kommunikation/#korrespondenz",
        Mailliste: DOCS_BASE_URL + "kommunikation/#e-mails",
        Mail: DOCS_BASE_URL + "kommunikation/#e-mails",
        Dokumentenliste: DOCS_BASE_URL + "kommunikation/#dokumente",
        Dokument: DOCS_BASE_URL + "kommunikation/#dokumente",
        NotizListe: DOCS_BASE_URL + "kommunikation/#notizen",
        Notiz: DOCS_BASE_URL + "kommunikation/#notizen",

        anwesenheitsliste: DOCS_BASE_URL + "dozierende/#anwesenheit",
        Anwesenheit: DOCS_BASE_URL + "dozierende/#anwesenheit",
        Praesenzliste: DOCS_BASE_URL + "dozierende/#praesenzen",
        Dozentenbuchungsliste: DOCS_BASE_URL + "dozierende/#buchungen",
        Dozentenbuchung: DOCS_BASE_URL + "dozierende/#buchungen",
        Dozenteneinsatzliste: DOCS_BASE_URL + "dozierende/#einsatz",
        Dozenteneinsatz: DOCS_BASE_URL + "dozierende/#einsatz",
        Noteneingabeliste: DOCS_BASE_URL + "dozierende/#noteneingabe",
        Noteneingabe: DOCS_BASE_URL + "dozierende/#noteneingabe",
        Journaleintraege: DOCS_BASE_URL + "dozierende",

        AnmeldungslisteSKJV: DOCS_BASE_URL + "anmeldungen/#anmeldungen",
        Anmeldung: DOCS_BASE_URL + "anmeldungen/#anmeldungen",
        Anmeldungsliste: DOCS_BASE_URL + "anmeldungen/#abrechnung-anmeldungen",

        Leads: DOCS_BASE_URL + "leadmanagement/#leads",
        Lead: DOCS_BASE_URL + "leadmanagement/#leads",
        Aktivitaeten: DOCS_BASE_URL + "leadmanagement/#aktivitaeten",
        Kampagnen: DOCS_BASE_URL + "leadmanagement/#kampagnen",
        Kampagne: DOCS_BASE_URL + "leadmanagement/#kampagnen",

        Terminliste: DOCS_BASE_URL + "raeume-und-termine/#termine",
        Termin: DOCS_BASE_URL + "raeume-und-termine/#termine",
        TerminKalender: DOCS_BASE_URL + "raeume-und-termine/#kalender",
        Bildschirmplaner: DOCS_BASE_URL + "raeume-und-termine/#bildschirme",
        Screenevent: DOCS_BASE_URL + "raeume-und-termine/#bildschirme",

        subventionsliste: DOCS_BASE_URL + "subventionen/#subventionen",
        Subvention: DOCS_BASE_URL + "subventionen/#subventionen",
        subventionsbeitragliste: DOCS_BASE_URL + "subventionen/#subventionsbeitraege",
        Subventionsbeitrag: DOCS_BASE_URL + "subventionen/#subventionsbeitraege",
        Subventionsabrechnungliste: DOCS_BASE_URL + "subventionen/#abrechnungen",
        Subventionsabrechnung: DOCS_BASE_URL + "subventionen/#abrechnungen",

        TorListe: DOCS_BASE_URL + "tor/#tor",
        TorAbschlussSimulieren: DOCS_BASE_URL + "tor/#abschluesse-simulieren",

        QuestionaireListe: DOCS_BASE_URL + "umfragen",
        Questionaire: DOCS_BASE_URL + "umfragen",

        StartseiteEinstellungen: DOCS_BASE_URL + "einstellungen",

        "template-bildungsgang-liste": DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        TemplateBildungsgang: DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        TemplateKlassenliste: DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        TemplateKlasse: DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        TemplateFaecherliste: DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        TemplateFach: DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        TemplateKursliste: DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        TemplateKurs: DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        "template-kurzveranstaltungs-liste": DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",
        "template-kurzveranstaltung": DOCS_BASE_URL + "einstellungen/#vorlagen-bildung",

        Raumliste: DOCS_BASE_URL + "einstellungen/#raeume-und-termine",
        Raum: DOCS_BASE_URL + "einstellungen/#raeume-und-termine",
        Gebäudeliste: DOCS_BASE_URL + "einstellungen/#raeume-und-termine",
        Gebaude: DOCS_BASE_URL + "einstellungen/#raeume-und-termine",

        Kompetenzrasterliste: DOCS_BASE_URL + "einstellungen/#kompetenzraster",
        "Einstellungen-Kompetenzraster": DOCS_BASE_URL + "einstellungen/#kompetenzraster",

        Notenliste: DOCS_BASE_URL + "einstellungen/#noten-1",
        Note: DOCS_BASE_URL + "einstellungen/#noten-1",

        Perioden: DOCS_BASE_URL + "einstellungen/#ausbildung",
        Sperrtage: DOCS_BASE_URL + "einstellungen/#sperrtage",

        MailVorlagenListe: DOCS_BASE_URL + "einstellungen/#korrespondenz",
        MailTemplate: DOCS_BASE_URL + "einstellungen/#korrespondenz",
        KorrespondenzVorlagenListe: DOCS_BASE_URL + "einstellungen/#korrespondenzvorlage-erstellen",
        KorrespondenzTemplate: DOCS_BASE_URL + "einstellungen/#korrespondenzvorlage-erstellen",

        Artikelliste: DOCS_BASE_URL + "einstellungen/#finanzen",
        Artikel: DOCS_BASE_URL + "einstellungen/#finanzen",

        TemplateQuestionaireListe: DOCS_BASE_URL + "einstellungen/#vorlagen-umfragen",
        TemplateQuestionaire: DOCS_BASE_URL + "einstellungen/#vorlagen-umfragen",

        EinstellungenAuswahlwerte: DOCS_BASE_URL + "einstellungen/#auswahlwerte",
      },
    };
  },
};
