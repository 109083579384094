<template>
  <div class="col-xl-12 pr-4">
    <!--  <container-headline
      :headline="$t('global.content')"
      :col="3"
    ></container-headline> -->
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row" v-if="mail.gesendet || mail.testmail_gesendet">
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="mail.gesendet"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.sent") }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="mail.testmail_gesendet"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.testmailsent") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <div v-if="!mail.isVorlage">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.empfaengermail"
                      v-bind:class="{ 'border-danger': !passed }"
                      :readonly="!editable"
                      class="form-control"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.receiveremail")
                    }}</label>
                  </ValidationProvider>
                </div>

                <div v-if="mail.isVorlage && mail.absenderadresse && lang">
                  <ValidationProvider
                    :rules="mail.defaultSprache == lang.id ? 'required' : ''"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.empfaengermail"
                      v-bind:class="{ 'border-danger': !passed }"
                      :readonly="!editable"
                      class="form-control"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.receiveremail")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="mail.cc"
                  :readonly="!editable"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.copy") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-if="!mail.isMassMail"
                  v-model.trim="mail.bcc"
                  :readonly="!editable"
                  class="form-control"
                  placeholder=" "
                />
                <textarea
                  v-if="mail.isMassMail"
                  v-model.trim="mail.bcc"
                  :readonly="!editable"
                  class="form-control"
                  placeholder=" "
                  rows="4"
                />
                <label>{{ $t("global.blindcopy") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <div v-if="!mail.isVorlage">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.absendermail"
                      v-bind:class="{ 'border-danger': !passed }"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.senderemail")
                    }}</label>
                  </ValidationProvider>
                </div>

                <div v-if="mail.isVorlage && mail.absenderadresse && lang">
                  <ValidationProvider
                    :rules="mail.defaultSprache == lang.id ? 'required' : ''"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.absenderadresse[lang.kuerzel].text"
                      v-bind:class="{ 'border-danger': !passed }"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.senderemail")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-1">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="mail.zeitpunkt"
                  :disabled="!editable"
                  placeholder=" "
                  :show-icon="true"
                  @update="(val) => (mail.zeitpunkt = val)"
                />
                <label>{{ $t("global.date") }}</label>
              </div>
            </div>
            <div class="col-xl-7">
              <div class="form-group">
                <div v-if="!mail.isVorlage">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.betreff"
                      v-bind:class="{ 'border-danger': !passed }"
                      :readonly="!editable"
                      class="form-control"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.subject")
                    }}</label>
                  </ValidationProvider>
                </div>

                <div v-if="mail.isVorlage && mail.translation && lang">
                  <ValidationProvider
                    :rules="mail.defaultSprache == lang.id ? 'required' : ''"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.translation[lang.kuerzel].betreff"
                      v-bind:class="{ 'border-danger': !passed }"
                      :readonly="!editable"
                      class="form-control"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.subject")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <v-select
                  v-model="mail.mailbanner"
                  label="bezeichnung"
                  :options="mailbanner"
                  :disabled="!editable"
                  @input="mailBannerUpdated"
                  placeholder=" "
                  :clearable="true"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.mailbanner") }}</label>
              </div>
            </div>
            <div class="col-xl-1">
              <b-button
                class="mt-3"
                size="sm"
                variant="outline-primary"
                v-tooltip
                :title="$t('global.highlighttextcodes')"
                @click="highlightTextcodes"
              >
                <font-awesome-icon
                  icon="fa-regular fa-highlighter"
                ></font-awesome-icon>
              </b-button>
            </div>
          </div>

          <div class="row" v-if="!mail.isVorlage">
            <div class="col-xl-12">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <div
                  class="form-group"
                  :style="{
                    border: passed ? '' : '1px solid red',
                    'padding-top': passed ? '' : '0',
                    'margin-top': passed ? '' : '16px',
                  }"
                >
                  <ckeditor
                    :editor="editor"
                    :disabled="!editable"
                    v-model="mail.inhalt"
                    ref="ckeditorInstance"
                    @ready="captureEditorInstance"
                  ></ckeditor>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="row" v-if="mail.isVorlage && mail.translation && lang">
            <div class="col-xl-12">
              <ValidationProvider
                :rules="mail.defaultSprache == lang.id ? 'required' : ''"
                immediate
                v-slot="{ passed }"
              >
                <div
                  class="form-group"
                  :style="{
                    border: passed ? '' : '1px solid red',
                    'padding-top': passed ? '' : '0',
                    'margin-top': passed ? '' : '16px',
                  }"
                >
                  <ckeditor
                    :editor="editor"
                    :disabled="!editable"
                    v-model="mail.translation[lang.kuerzel].text"
                    ref="ckeditorInstance"
                    @ready="captureEditorInstance"
                  ></ckeditor>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";
import DatePicker from "@/components/Datepicker";
import CustomEditor from "/custom/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_MAIL } from "@/store/mail/actions.type";

export default {
  name: "MailInhalt",
  components: {
    //  ContainerHeadline,
    DatePicker,
  },
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    lang: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      editor: CustomEditor,
      editorInstance: null,
      // editorConfig: {
      //   language: "en",
      // },
    };
  },
  computed: {
    mailbanner: {
      get() {
        return this.$store.state.mail.mailbanner;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.mailbanner.length == 0)
      this.$store.dispatch(`mail/${LADE_AUSWAHLWERTE_MAIL}`);
  },

  methods: {
    uploadFiles() {
      this.$refs.dokumenteUpload.processQueue();
    },
    captureEditorInstance(editor) {
      this.editorInstance = editor;
    },
    highlightTextcodes() {
      if (this.editorInstance) {
        const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;
        const editorData = this.editorInstance.getData();

        const updatedData = editorData.replace(
          pattern,
          (match, p1, p2, p3, p4) => {
            const highlightedText = p1 || p3;
            return highlightedText
              ? `<span style="color:#5299e0;">${highlightedText}</span>`
              : match;
          }
        );

        this.editorInstance.setData(updatedData);
      } else {
        console.error("Editor instance is not available.");
      }
    },
    mailBannerUpdated(val) {
      if (val && val.id) this.$emit("mailbannerupdated", val);
      else this.$emit("mailbannerupdated", null);
    },
  },
};
</script>

<style></style>
