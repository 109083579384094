<template>
  <div>
    <container-headline
      :headline="$t('global.masterdata')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="klasse.veranstaltungstyp"
                      label="bezeichnung"
                      :disabled="!editable"
                      :options="veranstaltungstypen"
                      placeholder="Typ"
                      :tabindex="1"
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.type") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="klasse.veranstaltungsstatus"
                      label="bezeichnung"
                      :disabled="!editable"
                      :options="veranstaltungsstati"
                      :placeholder="$t('global.status')"
                      :tabindex="2"
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.status") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <b-form-input
                    v-model.trim="klasse.kuerzel"
                    class="form-control"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                    :tabindex="3"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.shortdesignation")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <b-form-input
                    v-model.trim="klasse.bezeichnung"
                    class="form-control"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                    :tabindex="4"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.designation")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <b-form-input
                    v-model.number="klasse.periodennummer"
                    type="number"
                    v-bind:class="{ 'border-danger': !passed }"
                    placeholder=" "
                    :readonly="!editable"
                    :tabindex="5"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }"
                    >Semester</label
                  >
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <b-form-input
                  v-model.trim="klasse.zeitaufwand"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                  :tabindex="6"
                />
                <label>{{ $t("global.timeexpenditure") }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="!isTemplate">
            <div class="col-xl-6">
              <single-select-person
                id="verantwortlicher"
                :person="this.klasse.verantwortlicher"
                :label="$t('global.Responsible')"
                :allowOpen="true"
                :editable="editable"
                :required="true"
                @confirmed="setVerantwortlicher"
                :tabindex="6"
              />
            </div>
            <div class="col-xl-6">
              <single-select-person
                id="klassenassisztenz"
                :person="this.klasse.klassenassistenz"
                :label="$t('global.classassistant')"
                :allowOpen="true"
                :editable="editable"
                :required="false"
                @confirmed="setKlassenassistenz"
                :tabindex="6"
              />
            </div>
          </div>
          <div class="row" v-if="!isTemplate">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="klasse.klassenchef"
                  label="personName"
                  :options="klassenchefs"
                  :reduce="(kc) => kc.id"
                  :disabled="!editable"
                  :placeholder="$t('global.classpresident')"
                  :tabindex="7"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.classpresident") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="klasse.stvklassenchef"
                  label="personName"
                  :options="stvklassenchefs"
                  :reduce="(skc) => skc.id"
                  :disabled="!editable"
                  :placeholder="$t('global.depclasspresident')"
                  :tabindex="8"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.depclasspresident") }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="!isTemplate">
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="klasse.startdatum"
                  placeholer=" "
                  :show-icon="true"
                  :disabled="!editable"
                  @update="(val) => (klasse.startdatum = val)"
                  :tabindex="9"
                />
                <label>{{ $t("global.start") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="klasse.enddatum"
                  placeholder=" "
                  :show-icon="true"
                  :disabled="!editable"
                  @update="(val) => (klasse.enddatum = val)"
                  :tabindex="10"
                />
                <label>{{ $t("global.end") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="klasse.periode"
                  label="bezeichnung"
                  :options="perioden"
                  :disabled="!editable"
                  :placeholder="$t('global.period')"
                  :tabindex="11"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.period") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="klasse.themen"
                  label="bezeichnung"
                  :options="themen"
                  :disabled="!editable"
                  :placeholder="$t('global.dashboard_topic')"
                  multiple
                  :tabindex="12"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.dashboard_topic") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="klasse.qualifikationen"
                  label="bezeichnung"
                  :options="qualifikationen"
                  :disabled="!editable"
                  :placeholder="$t('global.qualification')"
                  multiple
                  :tabindex="13"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.qualification") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="klasse.pruefungsmodus"
                  label="bezeichnung"
                  :options="pruefungsmodi"
                  :disabled="!editable"
                  :placeholder="$t('global.examinationmode')"
                  :tabindex="13"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.examinationmode") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="klasse.inhalte"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                  :tabindex="14"
                ></textarea>
                <label>{{ $t("global.contents") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="klasse.beschreibung"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                  :tabindex="15"
                ></textarea>
                <label>{{ $t("global.description") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="klasse.zeiten"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                  :tabindex="16"
                ></textarea>
                <label>{{ $t("global.times") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="klasse.institution"
                  :getOptionLabel="(el) => el.bezeichnung"
                  :options="institutionen"
                  :disabled="!editable"
                  :placeholder="$t('global.institution')"
                  :tabindex="17"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.institution") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "KlasseStammdaten",
  components: {
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    klasse: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      klassenchefs: [],
      stvklassenchefs: [],
    };
  },
  watch: {
    veranstaltungsstati: function (val) {
      if (val && !this.klasse.veranstaltungsstatus) {
        let veranstaltungStatusOffen = this.$CONST("VERANSTALTUNGSSTATI").OPEN;

        this.klasse.veranstaltungsstatus = this.veranstaltungsstati.find(
          ({ id }) => id == veranstaltungStatusOffen
        );
      }
    },
    "klasse.id": function () {
      // Holt die Auswahlliste aus den Anmeldungen zu dieser Veranstaltung
      Api.get("geschaeftspartner/veranstaltung/", {
        params: { veranstaltung: this.klasse.id },
      }).then((response) => {
        this.klassenchefs = response.data;
        this.stvklassenchefs = response.data;
      });
    },
    "klasse.veranstaltungsstatus": function (newVal, oldVal) {
      if (!oldVal || !newVal) return;
      if (oldVal.id === newVal.id) return;
      if (newVal && newVal.id == "174D9067E04") {
        // Abgesagt
        this.$emit("eventCancelled", newVal, oldVal);
      }
    },
  },
  computed: {
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    veranstaltungstypen: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungstypen;
      },
    },
    perioden: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    niveaus: {
      get() {
        return this.$store.state.veranstaltung.niveaus;
      },
    },
    themen: {
      get() {
        return this.$store.state.veranstaltung.themen;
      },
    },
    qualifikationen: {
      get() {
        return this.$store.state.veranstaltung.qualifikationen;
      },
    },
    pruefungsmodi: {
      get() {
        return this.$store.state.veranstaltung.pruefungsmodi;
      },
    },
    institutionen: {
      get() {
        return this.$store.state.veranstaltung.institutionen;
      },
    },
  },
  mounted: function () {
    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
  },
  methods: {
    setVerantwortlicher(person) {
      this.klasse.verantwortlicher = person;
    },
    setKlassenassistenz(person) {
      this.klasse.klassenassistenz = person;
    },
  },
};
</script>

<style lang="scss"></style>
