<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable"
                    class="btn btn-success mr-2"
                    :disabled="invalid || !hasRequiredFields"
                    @click="speichern"
                    @shortkey="speichern"
                    v-tooltip.hover
                    :title="
                      !hasRequiredFields || invalid
                        ? $t(
                            'global.pleasefillincontentandsubjectforatleastonelanguage'
                          )
                        : null
                    "
                  >
                    {{ $t("global.savemail") }}
                  </button>

                  <button
                    v-if="
                      id &&
                      !mail.queued &&
                      !mail.gesendet &&
                      !mail.hasQueuedMails
                    "
                    class="btn btn-secondary mr-2"
                    :disabled="invalid || !hasRequiredFields"
                    @click="sendTestMail"
                    v-tooltip.hover
                    :title="
                      !hasRequiredFields || invalid
                        ? $t(
                            'global.pleasefillincontentandsubjectforatleastonelanguage'
                          )
                        : null
                    "
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-envelope"
                      class="mr-2"
                    />
                    {{ $t("global.testmail") }}
                  </button>

                  <button
                    v-if="
                      id &&
                      !mail.queued &&
                      !mail.gesendet &&
                      !mail.hasQueuedMails
                    "
                    class="btn btn-primary mr-2"
                    :disabled="
                      invalid || empfaengerIstNichtAktiv || !hasRequiredFields
                    "
                    v-tooltip.hover
                    :title="
                      empfaengerIstNichtAktiv
                        ? $t('global.recipientnotactive')
                        : !hasRequiredFields || invalid
                        ? $t(
                            'global.pleasefillincontentandsubjectforatleastonelanguage'
                          )
                        : null
                    "
                    @click="mailSendenModal"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-envelope"
                      class="mr-2"
                    />{{
                      mail.versanddatum
                        ? $t("global.putmailinqueue")
                        : $t("global.send")
                    }}
                  </button>

                  <button
                    v-if="mail.queued || mail.gesendet || mail.hasQueuedMails"
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="mailSendenModal"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-envelope"
                      class="mr-2"
                    />{{ $t("global.sendagain") }}
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="vorlageWaehlen"
                    v-if="editable"
                  >
                    {{ $t("global.selecttemplate") }}
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    @click="oeffneKalendereintragModal"
                    v-if="editable && id"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-calendar-day"
                      class="mr-2"
                    />
                    {{ $t("global.createcalendarentry") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />
                    {{ $t("global.tolist") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_korrespondenz.delete"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    <span>{{ $t("global.deletemail") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mail-empfaenger-absender
          v-if="!mail.isMassMail"
          :mail="mail"
          :editable="editable"
          @updated="inhaltKey++"
          :langs="langs"
        />

        <massen-mail-empfaenger-absender
          v-if="mail.isMassMail"
          :mail="mail"
          :editable="editable"
          :key="inhaltKey"
          @updated="inhaltKey++"
          :langs="langs"
        />

        <div v-if="!mail.isVorlage" class="col-xl-12 p-0">
          <div class="col-xl-12 p-0 d-flex">
            <div class="col-2 p-0 mr-1">
              <div class="col p-0">
                <div class="block block-kopf bg-medium-blue">
                  <span class="col primary-headline-text p-0">
                    {{ $t("global.content") }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <mail-inhalt
            v-if="!mail.isMassMail"
            :key="inhaltKey"
            :id="id"
            :mail="mail"
            :editable="editable"
            @mailbannerupdated="mailBannerUpdated"
          />

          <mass-mail-inhalt
            v-if="mail.isMassMail"
            :key="inhaltKey"
            :id="id"
            :mail="mail"
            :editable="editable"
            @mailbannerupdated="mailBannerUpdated"
          />
        </div>

        <div v-if="mail.isVorlage && langs" class="col-xl-12 p-0">
          <div class="col-xl-12 p-0 d-flex">
            <div
              v-for="(lang, index) in langs"
              :key="'headline-' + index"
              class="col-2 p-0 mr-1"
            >
              <div class="col clickable p-0" @click="setActiveLang(index)">
                <div
                  class="block block-kopf bg-medium-blue"
                  :class="activeLang === index ? '' : 'bg-grayscale-5 hover'"
                >
                  <span class="col primary-headline-text p-0">
                    {{ $t("global.content") + " " + lang.kuerzel }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 p-0">
            <div
              v-for="(lang, index) in langs"
              :key="index"
              class="col-xl-12 p-0"
            >
              <mail-inhalt
                v-if="!mail.isMassMail && activeLang === index"
                :key="inhaltKey"
                :id="id"
                :mail="mail"
                :editable="editable"
                :lang="lang"
                @mailbannerupdated="mailBannerUpdated"
              />

              <mass-mail-inhalt
                v-if="mail.isMassMail && activeLang === index"
                :key="inhaltKey"
                :id="id"
                :mail="mail"
                :editable="editable"
                :lang="lang"
                @mailbannerupdated="mailBannerUpdated"
              />
            </div>
          </div>
        </div>

        <div class="col-xl-12 p-0" v-if="id">
          <div class="tab-container p-3">
            <attachment-liste
              :key="attachmentKey"
              :id="id"
              :editable="editable"
              :shown="true"
              @updateComponent="attachmentKey++"
              @dokumenteLoaded="getAttachments"
            />
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid || !hasRequiredFields ? null : speichern"
      />
    </ValidationObserver>

    <mail-vorlage-modal @confirmed="vorlageSetzen" />

    <loeschen-modal id="mail-loeschen-modal" @confirmed="mailLoeschen" />

    <message-box
      id="mail-senden-modal"
      :headerText="$t('global.notification_Sendmails')"
      :text="$t('global.doyouwantsendmails')"
      :ok="true"
      :cancel="true"
      :repeat="false"
      :okText="$t('global.notification_sendmails')"
      :cancelText="$t('global.cancel')"
      @ok="senden"
    />

    <message-box
      id="mail-notify-modal"
      :headerText="$t('global.mailssent')"
      :text="$t('global.testmailsentsuccessfully')"
      :ok="true"
      :cancel="true"
      :repeat="false"
      :cancelText="$t('global.cancel')"
    />

    <kalendereintrag-modal
      id="kalendereintrag-modal"
      :absender="mail.absendermail"
      :inhalt="mail.inhalt"
      :betreff="mail.betreff"
      :empfaenger="mail.isMassMail ? mail.empfaenger : [mail.empfaengermail]"
      :empfaengerFirma="mail.firma"
      :isMassMail="mail.isMassMail"
    />
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "@/store/einstellungendashboard/actions.type";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MailVorlageModal from "@/components/Modals/MailVorlageModal";

import MailInhalt from "@/components/Kommunikation/MailInhalt.vue";
import MassMailInhalt from "@/components/Kommunikation/MassMailInhalt.vue";
import MailEmpfaengerAbsender from "@/components/Kommunikation/MailEmpfaengerAbsender.vue";
import MassenMailEmpfaengerAbsender from "@/components/Kommunikation/MassenMailEmpfaengerAbsender.vue";
import AttachmentListe from "@/components/Reiter/AttachmentListe.vue";
import MessageBox from "@/components/Modals/Messagebox";
import KalendereintragModal from "@/components/Kommunikation/KalendereintragModal";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

export default {
  name: "Mail",
  components: {
    HeadMenu,
    Navbar,
    AttachmentListe,
    LoeschenModal,
    MailVorlageModal,
    MailInhalt,
    MassMailInhalt,
    MailEmpfaengerAbsender,
    MassenMailEmpfaengerAbsender,
    MessageBox,
    KalendereintragModal,
    LoadingOverlay,
    UnsavedChangesModal,
  },

  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mail: {
        isVorlage: false,
        absender: {},
        absenderadresse: {
          de: { betreff: "", text: "" },
          en: { betreff: "", text: "" },
          fr: { betreff: "", text: "" },
          it: { betreff: "", text: "" },
        },
        antwortan: {
          de: { betreff: "", text: "" },
          en: { betreff: "", text: "" },
          fr: { betreff: "", text: "" },
          it: { betreff: "", text: "" },
        },
        empfaengermail: null,
        empfaenger: [
          { personName: this.$t("global.notlinked") },
          { name: this.$t("global.notlinked") },
        ],
        firma: [],
        zeitpunkt: new Intl.DateTimeFormat("de-CH").format(new Date()),
        inhalt: "",
        betreff: "",
        isMassMail: false,
        mailbanner: { bezeichnung: this.$t("global.notlinked") },
        translation: {
          de: { betreff: "", text: "" },
          en: { betreff: "", text: "" },
          fr: { betreff: "", text: "" },
          it: { betreff: "", text: "" },
        },
      },
      activeLang: 0,
      alertModal: {
        color: null,
        text: "",
        title: "",
      },
      attachments: [],
      editable: true,
      loading: false,
      inhaltKey: 0,
      attachmentKey: 0,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.mail.id) {
        return this.$t("global.email");
      } else {
        if (this.kategorieObj)
          return `${this.$t("global.new")} ${this.kategorieObj.bezeichnung}`;
        return this.$t("global.newemail");
      }
    },
    langs: {
      get() {
        return this.$store.state.einstellungendashboard.sprachen;
      },
    },
    inhaltVorhanden() {
      let defaultSprache = this.langs.find((lang) => {
        return lang.id === this.mail.defaultSprache;
      });
      if (
        this.mail.translation[defaultSprache.kuerzel].text &&
        this.mail.translation[defaultSprache.kuerzel].betreff
      ) {
        return true;
      } else return false;
    },
    empfaengerIstNichtAktiv() {
      if (this.mail?.isMassMail === false) {
        if (
          this.mail.empfaenger[0]?.aktivitaetsstatus?.id != "1737FA777AA" &&
          this.mail.firma[0]?.aktivitaetsstatus?.id != "1737FA777AA"
        ) {
          //Aktiv
          return true;
        }
      }
      return false;
    },
    hasRequiredFields() {
      if (this.mail.isVorlage) {
        if (this.langs.length == 0) return;
        const defaultSpracheKuerzel = this.langs.find((sprache) => {
          return sprache.id == this.mail.defaultSprache;
        }).kuerzel;
        if (
          (!this.mail.isMassMail && !this.mail.empfaengermail) ||
          !this.mail.translation[defaultSpracheKuerzel].betreff ||
          !this.mail.translation[defaultSpracheKuerzel].text ||
          !this.mail.absenderadresse[defaultSpracheKuerzel].text
        ) {
          return false;
        } else return true;
      } else return true;
    },
    hasStandardFields() {
      if (
        (!this.mail.isMassMail && !this.mail.empfaengermail) ||
        !this.mail.betreff ||
        !this.mail.inhalt ||
        !this.mail.absendermail
      )
        return false;
      return true;
    },
  },
  watch: {},

  mounted() {
    if (this.id) {
      Api.get("mail/", { params: { id: this.id } }).then((response) => {
        this.initializeMail(response.data);
      });
    } else {
      //this.initializeMail(this.mail);
    }
    if (this.langs.length == 0) {
      this.$store.dispatch(
        `einstellungendashboard/${LADE_AUSWAHLWERTE_EINSTELLUNGEN}`
      );
    }
  },

  //mounted: function () {},

  methods: {
    mailBannerUpdated(u) {
      if (u) {
        if (!u.id) return;

        this.mail.mailbanner = u;
      } else {
        this.mail.mailbanner = {};
      }
    },
    abbrechen() {
      this.$router.push({ name: "mail-liste" });
    },
    getAttachments(attachments) {
      this.mail.attachments = attachments;
      this.attachments = attachments;
    },
    initializeMail(mail) {
      if (mail) {
        mail.empfaenger.sort((a, b) => {
          return a.personName > b.personName;
        });

        this.mail = mail;

        this.mail.attachments = this.attachments;

        if (!this.mail.inhalt) {
          this.mail.inhalt = "";
        } else {
          this.mail.inhalt = this.highlightTextcodes(this.mail.inhalt);
        }

        if (this.mail?.translation) {
          this.langs.forEach((lang) => {
            this.mail.translation[lang.kuerzel].text = this.highlightTextcodes(
              this.mail.translation[lang.kuerzel].text
            );
          });
        }

        if (mail.empfaenger.length == 0) this.mail.empfaenger = [];
        else {
          this.empfaenger = mail.empfaenger;
        }

        if (this.mail.mailbanner) {
          this.mail.mailbannerURL =
            this.$CONST("CONFIG").BASEURL + this.mail.mailbanner.bild;
        }
      } else {
        //console.error("mail ist null");
      }

      if (mail.queued == true || mail.gesendet != null || mail.hasQueuedMails)
        this.editable = false;
      //this.editable = this.mail.queued ? false : true;

      if (!this.mail.zeitpunkt)
        this.mail.zeitpunkt = new Intl.DateTimeFormat("de-CH").format(
          new Date()
        );
      if (
        this.mail.isMassMail &&
        this.mail.absendermail != null &&
        this.mail.absendermail != ""
      ) {
        this.absendermail = this.mail.absendermail;
      } else if (
        this.mail.isMassMail &&
        this.mail.absendermail == null &&
        this.mail.absendermail == ""
      ) {
        this.mail.absendermail = this.$CONST("CONFIG").MANDANTEMAIL;
        this.absendermail = "pfui";
      } else {
        if (this.mail.absendermail != null && this.mail.absendermail != "") {
          this.absendermail = this.mail.absendermail;
        } else {
          if (this.mail.absender == null || this.mail.absender.id == null) {
            if (this.angemeldeterMitarbeiter.person) {
              this.mail.absender = this.angemeldeterMitarbeiter.person;

              if (this.angemeldeterMitarbeiter.person.mailadressen) {
                let mailadressen =
                  this.angemeldeterMitarbeiter.person.mailadressen;

                let pos = mailadressen.findIndex(
                  (mailadr) => mailadr.hauptadresse == true
                );
                if (pos == -1)
                  this.mail.absendermail = mailadressen[0]?.bezeichnung;
                else this.mail.absendermail = mailadressen[pos].bezeichnung;
              } else {
                this.mail.absendermail = "";
              }
            }
          }
        }
        let tempMail;
        if (this.mail.empfaenger[0]) {
          if (this.mail.empfaenger[0]?.kommunikationsmail) {
            tempMail = this.mail.empfaenger[0]?.kommunikationsmail;
          } else {
            tempMail = this.mail.empfaenger[0]?.mailadressen?.find(
              (item) => item.hauptadresse == true
            );
          }
        } else if (this.mail.firma[0]) {
          tempMail = this.mail.firma[0]?.mailadressen?.find(
            (item) => item.hauptadresse == true
          );
        }

        if (tempMail?.bezeichnung) {
          this.mail.empfaengermail = tempMail.bezeichnung;
        }

        if (!this.mail.empfaengermail && this.mail.empfaenger[0]) {
          this.mail.empfaengermail =
            this.mail?.empfaenger[0]?.mailadressen[0]?.bezeichnung;
        } else if (!this.mail.empfaengermail && this.mail.firma[0]) {
          this.mail.empfaengermail =
            this.mail?.firma[0]?.mailadressen[0]?.bezeichnung;
        } else {
          this.mail.empfaengermail = mail.empfaengermail;
        }
      }

      this.editable = !this.berechtigungen.m_korrespondenz.update
        ? false
        : this.editable;

      if (mail) {
        this.initializeChangewatcher(["mail"]);
      }
    },
    highlightTextcodes(text) {
      if (!text) return "";

      const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;

      return text.replace(pattern, (match, p1, p2, p3, p4) => {
        const highlightedText = p1 || p3;
        return highlightedText
          ? `<span style="color:#5299e0;">${highlightedText}</span>`
          : match;
      });
    },
    unhighlightTextcodes(text) {
      if (!text) return "";

      const pattern =
        /<span style="color:#5299e0;">([^<]+)<\/span>|<span style="color:#8bc34a;">([^<]+)<\/span>/g;

      return text.replace(pattern, (match, p1, p2) => {
        if (p1) return p1;
        if (p2) return p2;
      });
    },
    mailLoeschen() {
      Api.delete("mail/", {
        params: { ids: [this.id].join(",") },
      }).then(() => {
        this.$router.push({ name: "mail-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "mail-loeschen-modal");
    },

    showAlertModal(title, text, color) {
      this.alertModal.title = title;
      this.alertModal.text = text;
      this.alertModal.color = color;
      $("#modal-alert").modal("show");
    },

    speichern() {
      if (this.loading) return;

      if (this.mail.isVorlage && !this.hasRequiredFields) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: this.$t(
            "global.pleasefillincontentandsubjectforatleastonelanguage"
          ),
        });
        return;
      } else {
        if (!this.mail.isVorlage && !this.hasStandardFields) {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t(
              "global.pleasefillincontentandsubjectforatleastonelanguage"
            ),
          });
          return;
        }
      }

      if (
        (this.mail.isVorlage && this.inhaltVorhanden) ||
        !this.mail.isVorlage
      ) {
        this.loading = true;

        if (this.mail.versandzeit && !this.mail.versanddatum) {
          this.mail.versanddatum = new Date().toGermanDateString();
        }
        var json = Object.assign({}, this.mail);

        if (json.inhalt) {
          this.$set(json, "inhalt", this.unhighlightTextcodes(json.inhalt));
        }

        if (json.translation) {
          this.langs.forEach((lang) => {
            json.translation[lang.kuerzel].text = this.unhighlightTextcodes(
              json.translation[lang.kuerzel].text
            );
          });
        }

        if (!this.id) {
          Api.post("mail/", json)
            .then((response) => {
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: this.$t("global.mailsuccessfullysaved"),
              });
              this.initializeMail(response.data);
              this.$router.replace({
                name: "mail",
                params: { id: response.data.id, anzeige: 0 },
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          Api.put("mail/", json, { params: { id: this.id } })
            .then((response) => {
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: this.$t("global.mailsuccessfullysaved"),
              });
              this.initializeMail(response.data);
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: this.$t(
            "global.pleasefillincontentandsubjectforatleastonelanguage"
          ),
        });
      }
    },

    vorlageWaehlen() {
      this.$bus.$emit("open-mail-vorlage-modal");
    },

    oeffneKalendereintragModal() {
      this.$bus.$emit("open-modal", "kalendereintrag-modal");
    },

    vorlageSetzen(vorlage) {
      this.mail.isVorlage = true;
      if (vorlage.absenderadresse != "")
        this.mail.absender = this.angemeldeterMitarbeiter.person;
      if (!vorlage.absenderadresse) {
        this.mail.absender = this.angemeldeterMitarbeiter.person;

        if (this.angemeldeterMitarbeiter.person.mailadressen.length > 0) {
          //initialize default mailadresse
          this.mail.absendermail =
            this.angemeldeterMitarbeiter.person.mailadressen[0].bezeichnung;

          //search for ABB -TS mailadress
          let el = this.angemeldeterMitarbeiter.person.mailadressen;
          for (
            let i = 0;
            i < this.angemeldeterMitarbeiter.person.mailadressen.length;
            i++
          ) {
            let mailtypId = el[i].mailtyp.id;

            switch (mailtypId) {
              //set Bildungsadresse as mailadress and exit for loop
              case "17628F1B6CC":
                this.mail.absendermail = el[i].bezeichnung;
                i = el.length;
                break;
              //set buissnesmail if no other was found
              case "1749BE8915F":
                this.mail.absendermail = el[i].bezeichnung;
                break;
            }
          }
        }
      }

      /*  this.mail.betreff = vorlage.betreff;
      this.mail.inhalt = vorlage.mailtext; */
      this.langs.forEach((lang) => {
        this.mail.translation[lang.kuerzel].betreff =
          vorlage.translation[lang.kuerzel].betreff;

        this.mail.translation[lang.kuerzel].text =
          vorlage.translation[lang.kuerzel].text;

        this.mail.translation[lang.kuerzel].language =
          vorlage.translation[lang.kuerzel].language;

        if (
          vorlage.absenderadresse[lang.kuerzel].text !== "" &&
          vorlage.absenderadresse[lang.kuerzel].text !== null
        ) {
          this.mail.absenderadresse[lang.kuerzel].text =
            vorlage.absenderadresse[lang.kuerzel].text;
        } else {
          if (this.angemeldeterMitarbeiter.person.mailadressen.length > 0) {
            const hauptmail =
              this.angemeldeterMitarbeiter.person.mailadressen.find(
                (mail) => mail.hauptadresse
              ).bezeichnung ||
              this.angemeldeterMitarbeiter.person.mailadressen[0].bezeichnung;
            this.mail.absenderadresse[lang.kuerzel].text = hauptmail;
          }
        }
        this.mail.absenderadresse[lang.kuerzel].language =
          vorlage.absenderadresse[lang.kuerzel].language;

        this.mail.antwortan[lang.kuerzel].text =
          vorlage.antwortan[lang.kuerzel].text;
        this.mail.antwortan[lang.kuerzel].language =
          vorlage.antwortan[lang.kuerzel].language;
      });
      this.mail.defaultSprache = vorlage.defaultsprache;

      this.inhaltKey += 1;
    },

    mailSendenModal() {
      this.$bus.$emit("open-modal", "mail-senden-modal");
    },

    mailNotify() {
      this.$bus.$emit("open-modal", "mail-notify-modal");
    },
    senden() {
      if (this.loading) return;

      this.loading = true;

      let json = Object.assign({}, this.mail);

      if (json.inhalt) {
        this.$set(json, "inhalt", this.unhighlightTextcodes(json.inhalt));
      }
      if (json.translation) {
        this.langs.forEach((lang) => {
          json.translation[lang.kuerzel].text = this.unhighlightTextcodes(
            json.translation[lang.kuerzel].text
          );
        });
      }

      Api.put("mail/send/", json, {
        params: { id: this.id, testmail: false },
      })
        .then((response) => {
          this.initializeMail(response.data);

          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.mailssentsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sendTestMail() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.mail);

      if (this.mail.isMassMail) {
        if (this.mail.empfaenger.length !== 0) {
          let ersterEmpfaenger = this.mail.empfaenger[0];
          if (ersterEmpfaenger.mailadressen.length !== 0) {
            let mailadresse = ersterEmpfaenger.mailadressen.find(
              (email) => email.hauptadresse == true
            );
            if (!mailadresse) {
              json.empfaengermail =
                ersterEmpfaenger.mailadressen[0].bezeichnung;
            } else json.empfaengermail = mailadresse.bezeichnung;
          }
        }
      }

      if (json.inhalt) {
        this.$set(json, "inhalt", this.unhighlightTextcodes(json.inhalt));
      }
      if (json.translation) {
        this.langs.forEach((lang) => {
          json.translation[lang.kuerzel].text = this.unhighlightTextcodes(
            json.translation[lang.kuerzel].text
          );
        });
      }

      Api.put("mail/send/", json, {
        params: { id: this.id, testmail: true },
      })
        .then((response) => {
          this.initializeMail(response.data);

          this.mailNotify();
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setActiveLang(index) {
      this.activeLang = index;
    },
  },
};
</script>

<style></style>
