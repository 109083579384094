import Api from "@/Api";

import LoadingOverlay from "@/components/global/LoadingOverlay";

export default {
  components: {
    LoadingOverlay,
  },
  props: {
    showNavbarProp: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  metaInfo() {
    return {
      title: this.navbarTitel,
      titleTemplate: "BX: %s",
    };
  },
  data() {
    return {
      loading: false,
      editable: false,
      vorschauUrl: null,
      showNavbar:
        this.$route.query.showNavbar === undefined ||
        this.$route.query.showNavbar !== "false",
    };
  },
  computed: {
    angemeldeterMitarbeiter: {
      get() {
        if (this.$store) return this.$store.state.mitarbeiter;
        return null;
      },
      set(value) {
        this.$store.commit("setMitarbeiter", value);
      },
    },
    windowHeight: {
      get() {
        this.$store.getters.windowHeight;
      },
      set(val) {
        this.$store.commit("setWindowHeight", val);
      },
    },
    windowWidth: {
      get() {
        this.$store.getters.windowWidth;
      },
      set(val) {
        this.$store.commit("setWindowWidth", val);
      },
    },
    mitarbeiterName() {
      if (this.$store) return this.$store.getters.name;
      return "";
    },
    docsName() {
      return this.$options.name;
    },
  },
  created() {
    if (!this.angemeldeterMitarbeiter)
      Api.get("aktueller_mitarbeiter/").then((response) => {
        this.angemeldeterMitarbeiter = response.data;
      });
  },
  watch: {
    permissions(val) {
      if (val && !val.read) {
        this.$router.replace({ name: "forbidden", params: { response: {} } });
      }
    },
  },
  mounted() {
    if (!(this.showNavbarProp === null)) {
      this.showNavbar = this.showNavbarProp;
    }

    this.windowWidth = window.innerWidth;
    this.windowHeigth = window.innerHeight;

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
  },
  methods: {
    //Sortiert Listeninhalte nach Datum, wenn dessen key den substring "datum" oder "date" enthält
    //beim "b-table"-Element property ":sort-compare="sortDates"" hinzufügen
    sortDates(datumA, datumB, key) {
      if (
        !key.includes("datum") &&
        !key.includes("date") &&
        !key.includes("meldeschluss") &&
        !key.includes("naechsterkontakt")
      ) {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolve(key, datumA);
          b = this.resolve(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    resolve(path, obj = self, separator = ".") {
      var properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    },
  },
};
